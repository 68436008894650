import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useLocale } from '../../state/Localization';
import { SalesChannelEventsState, VenueEvent } from '../../state/SalesChannelEvents/types';
import { v4 as uuidv4 } from 'uuid';
import style from './style.module.css';

type IEventsList = {
  eventsListState: SalesChannelEventsState;
  salesChannelKey: string;
  tenantSlug: string;
};

const EventsList: React.FC<IEventsList> = ({
  eventsListState,
  salesChannelKey,
  tenantSlug
}) => {
  const { strings } = useLocale();
  return (
    <div className={style.eventsWrapper}>
      {eventsListState.events.map((item: VenueEvent) => {
        return (
          <div className={style.eventsItem} key={item.id + uuidv4()}>
            <h3 className={style.eventsSalesLink}>{item.title?.de}</h3>
            <div className={style.eventsItemContent}>
              <p>{strings.EventListingStart}:&nbsp;
                <span>{item.startDate ? new Date(item.startDate).toLocaleString() : null}</span>
              </p>
              <p>{strings.EventListingSubTitle}: <span>{item.subtitle?.de}</span></p>
              <p>{strings.EventListingDescription}: <span>{item.description?.de}</span></p>
            </div>

            <Button
              component={Link}
              to={`/${tenantSlug}/placeSelection/${salesChannelKey}/${item.id}`}
              className={style.eventsSalesBtn}
              color="primary"
              variant='contained'
            >
              {strings.EventListingContinue}
            </Button>

            {/* className={style.eventsSalesBtn} */}

          </div>
        );
      })}
    </div>
  );
};

export default EventsList;
