import {
  Action,
  ActionType,
  PaymentMethodState
} from './types';

const initialState: PaymentMethodState = {
  fetching: false,
  data: []
};

export const reducer = (
  state = initialState,
  action: Action,
): PaymentMethodState => {
  switch (action.type) {
      case ActionType.ERROR: {
        const { error } = action.payload;
        return {
            ...state,
            error,
            fetching: false,
        };
      }
      case ActionType.FETCH: {
        return {
            ...state,
            fetching: true,
        };
      }
      case ActionType.SUCCESS: {
        const { response } = action.payload;
        return {
          ...state,
          data: response.data,
          fetching: false
        };
      }
      default: {
        return state;
      }
  }
};
