import {
  Action as BlockAction,
  BlockState,
  reducer as blockReducer,
} from './Block';
import {
  Action as BlockGroupAction,
  BlockGroupState,
  reducer as blockGroupReducer,
} from './BlockGroup';
import {
  Action as BuildInfoAction,
  BuildInfoState,
  reducer as buildInfoReducer,
} from './BuildInfo';
import {
  Action as FetchAction,
  FetchState,
  reducer as fetchReducer,
} from './Fetch';
import {
  Action as MessageAction,
  MessageState,
  reducer as messageReducer,
} from './Message';
import {
  Action as PlacesPersonalizationAction,
  PlacesPersonalizationState,
  reducer as placesPersonalizationReducer,
} from './PlacePersonalization';
import {
  Action as PurchasableItemsAction,
  PurchasableItemsState,
  reducer as purchasableItemsReducer,
} from './PurchasableItems';
import {
  Action as SalesChannelEventsAction,
  SalesChannelEventsState,
  reducer as salesChannelEventsReducer,
} from './SalesChannelEvents';
import {
  Action as CheckoutAction,
  CheckoutState,
  reducer as checkoutReducer,
} from './Checkout';
import {
  Action as SeatAction,
  VenuePlanState,
  reducer as venuePlanReducer,
} from './VenuePlan';
import {
  Action as SessionAction,
  SessionState,
  reducer as sessionReducer,
} from './Session';
import {
  Action as TicketSelectionAction,
  TicketSelectionState,
  reducer as ticketSelectionReducer,
} from './TicketSelection';
import {
  Action as PaymentAction,
  PaymentState,
  reducer as paymentReducer,
} from './Payment';
import {
  Action as PaymentMethodAction,
  PaymentMethodState,
  reducer as paymentMethodReducer,
} from './PaymentMethod';
import {
  Action as VenueEventAction,
  VenueEventState,
  reducer as venueEventReducer,
} from './VenueEvent';
import {
  Action as RevokeTicketAction,
  RevokeTicketState,
  reducer as revokeTicketReducer,
} from './RevokeTicket';
import {
  Action as TenantConfigAction,
  TenantConfigState,
  reducer as TenantConfigReducer,
} from './TenantConfig';

import {
  Store,
  applyMiddleware,
  combineReducers,
  compose,
  createStore as createReduxStore,
} from 'redux';
import reduxThunk from 'redux-thunk';

export type Action =
  | BlockAction
  | BlockGroupAction
  | BuildInfoAction
  | CheckoutAction
  | PaymentAction
  | PaymentMethodAction
  | FetchAction
  | MessageAction
  | PlacesPersonalizationAction
  | PurchasableItemsAction
  | SalesChannelEventsAction
  | SeatAction
  | SessionAction
  | TenantConfigAction
  | TicketSelectionAction
  | VenueEventAction
  | RevokeTicketAction;

export interface AppState {
  block: BlockState;
  blockGroup: BlockGroupState;
  buildInfo: BuildInfoState;
  checkout: CheckoutState;
  payment: PaymentState;
  paymentMethod: PaymentMethodState;
  fetch: FetchState;
  message: MessageState;
  placesPersonalization: PlacesPersonalizationState;
  purchasableItems: PurchasableItemsState;
  salesChannelEvents: SalesChannelEventsState;
  venuePlan: VenuePlanState;
  session: SessionState;
  tenantConfig: TenantConfigState;
  ticketSelection: TicketSelectionState;
  venueEvent: VenueEventState;
  revokeTicket: RevokeTicketState;
}

export const reducers = combineReducers<AppState>({
  block: blockReducer,
  blockGroup: blockGroupReducer,
  buildInfo: buildInfoReducer,
  checkout: checkoutReducer,
  payment: paymentReducer,
  paymentMethod: paymentMethodReducer,
  fetch: fetchReducer,
  message: messageReducer,
  placesPersonalization: placesPersonalizationReducer,
  purchasableItems: purchasableItemsReducer,
  revokeTicket: revokeTicketReducer,
  salesChannelEvents: salesChannelEventsReducer,
  session: sessionReducer,
  tenantConfig: TenantConfigReducer,
  ticketSelection: ticketSelectionReducer,
  venueEvent: venueEventReducer,
  venuePlan: venuePlanReducer,
});

export const createStore = (): Store => {
  // eslint-disable-next-line
  const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  return createReduxStore(
    reducers,
    //eslint-disable-next-line
    composeEnhancers(applyMiddleware(reduxThunk))
  );
};
