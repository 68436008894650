import { Action, ActionType, MessageState } from './types';

const initialState: MessageState = {
  messages: [],
};

export const reducer = (state = initialState, action: Action): MessageState => {
  switch (action.type) {
    case ActionType.ADD: {
      const { message } = action.payload;
      const messagetype = message.type;
      if (state.messages.find((message) => message.type === messagetype)) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          messages: [...state.messages, message],
        };
      }
    }
    case ActionType.REMOVE: {
      const { messageId } = action.payload;
      return {
        ...state,
        messages: state.messages.filter((message) => message.id !== messageId),
      };
    }
    default: {
      return state;
    }
  }
};
