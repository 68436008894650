import { BlockReseatingState, VenueBlockData } from './data';
import { BitmapText, Container, Graphics, Point, Polygon, Sprite } from 'pixi.js';
import {
  NUMERIC_LABEL_OVERFLOW_NAME,
  NUMERIC_LABEL_OVERFLOW_THRESHOLD,
  getNumericLabelTexture,
  getSeatTexture,
} from './resources';
import {
  TINT_BLOCK_OUTLINE,
  TINT_BLOCK_RESEATING_LABEL,
  TINT_SEAT_LABEL_AVAILABLE,
  TINT_SEAT_LABEL_SELECTED,
} from './colors';
import { calculateShapeCenter, circleCollidesPolygon, getLuminance } from './utils';
import Color from 'color';
import { PlaceGraphicalState } from './PlaceGraphicalState';
import { LABEL_BITMAP_FONT, sanitizeStringForLabelUse } from './fonts';
import { LABEL_DEFAULT_COLOR, LABEL_DEFAULT_LIGHT_COLOR, STAND_BLOCK_LABEL_SIZE } from './constants';
import { checkIfRectFitsWithinPolygon } from './utils';

const BLOCK_BADGE_SIZE = 1.5;
const BLOCK_BADGE_LABEL_SIZE = BLOCK_BADGE_SIZE * 0.65;

export type BlockType = 'seating' | 'standing';

export type BlockStatus = 'AVAILABLE' | 'UNAVAILABLE';

export type BlockReseatingStatus =
  | 'AVAILABLE'
  | 'UNAVAILABLE'
  | 'SELECTED'
  | 'COMPLETED';

async function createAmountBadge(): Promise<Container> {
  const badge = new Sprite( await getSeatTexture(PlaceGraphicalState.UNKNOWN));
  badge.width = BLOCK_BADGE_SIZE;
  badge.height = BLOCK_BADGE_SIZE;
  badge.anchor.x = 0.5;
  badge.anchor.y = 0.5;
  return badge;
}

async function createAmountLabel(): Promise<Sprite> {
  const label = new Sprite(await getNumericLabelTexture('0'));
  label.width = BLOCK_BADGE_LABEL_SIZE;
  label.height = BLOCK_BADGE_LABEL_SIZE;
  label.anchor.x = 0.5;
  label.anchor.y = 0.5;
  label.tint = 0x000000;
  return label;
}

async function createReseatingBadge(): Promise<Sprite> {
  const badge = new Sprite(await getSeatTexture(PlaceGraphicalState.UNKNOWN));
  badge.width = BLOCK_BADGE_SIZE;
  badge.height = BLOCK_BADGE_SIZE;
  badge.anchor.x = 0.5;
  badge.anchor.y = 0.5;
  badge.tint = TINT_BLOCK_RESEATING_LABEL;
  return badge;
}

async function createReseatingBadgeBackground(): Promise<Sprite> {
  const badge = new Sprite(await getSeatTexture(PlaceGraphicalState.UNKNOWN));
  badge.width = BLOCK_BADGE_SIZE * 0.9;
  badge.height = BLOCK_BADGE_SIZE * 0.9;
  badge.anchor.x = 0.5;
  badge.anchor.y = 0.5;
  return badge;
}

async function createReseatingLabel(): Promise<Sprite> {
  const label = new Sprite(await getNumericLabelTexture('0'));
  label.width = BLOCK_BADGE_LABEL_SIZE;
  label.height = BLOCK_BADGE_LABEL_SIZE;
  label.anchor.x = 0.5;
  label.anchor.y = 0.5;
  label.tint = TINT_SEAT_LABEL_AVAILABLE;
  return label;
}

interface BlockLabel {
  readonly label: Container;
  readonly labelText: BitmapText;
  readonly badge: Container;
  readonly amount: Sprite;
  readonly reseatingBadgeContainer: Container;
  readonly reseatingBadge: Sprite;
  readonly reseatingAmount: Sprite;
}

/**
 * Represents a block of seats or standing places.
 */
export class Block {
  constructor(
    readonly id: string,
    readonly type: BlockType,
    readonly name: string,
    readonly color: number,
    readonly outlineGraphics: Graphics,
    private readonly outlines: number[],
    private readonly blockLabel: BlockLabel,
  ) {
    this.selectedAmount = 0;
    this.reseatingStatus = {
      amount: 0,
      status: 'UNAVAILABLE',
    };
  }

  private _status: BlockStatus = 'UNAVAILABLE';

  get status(): BlockStatus {
    return this._status;
  }

  set status(status) {
    this._status = status;

    if (this.type == 'standing') {
      this.outlineGraphics.tint = status === 'AVAILABLE' ? this.color : TINT_BLOCK_OUTLINE;
      this.blockLabel.labelText.tint = getLuminance(this.outlineGraphics.tint.valueOf()) < 128
        ? LABEL_DEFAULT_LIGHT_COLOR
        : LABEL_DEFAULT_COLOR;
      this.outlineGraphics.eventMode = (status === 'AVAILABLE' ? 'static' : 'auto');
    }
  }

  private _selectedAmount = 0;

  get selectedAmount(): number {
    return this._selectedAmount;
  }

  set selectedAmount(selectedAmount: number) {
    this._selectedAmount = selectedAmount;

    const isBadgeVisible = this.type === 'standing' && selectedAmount > 0;
    this.blockLabel.badge.visible = isBadgeVisible;

    const label =
      selectedAmount > NUMERIC_LABEL_OVERFLOW_THRESHOLD
        ? NUMERIC_LABEL_OVERFLOW_NAME
        : selectedAmount.toFixed(0);

    this.updateNumericLabelTexture(label);
  }

  async updateNumericLabelTexture(label: string) {
    const texture = await getNumericLabelTexture(label);
    this.blockLabel.amount.texture = texture;
  }

  async updateSeatTexture(graphicalState: PlaceGraphicalState) {
    const texture = await getSeatTexture(graphicalState);
    this.blockLabel.reseatingBadge.texture = texture;
  }

  set reseatingStatus(reseatingStatus: BlockReseatingState) {
    const { amount, status } = reseatingStatus;

    const isBadgeVisible = this.type === 'standing' && status !== 'UNAVAILABLE';
    this.blockLabel.reseatingBadgeContainer.visible = isBadgeVisible;

    if (!isBadgeVisible) return;

    const label =
      amount > NUMERIC_LABEL_OVERFLOW_THRESHOLD
        ? NUMERIC_LABEL_OVERFLOW_NAME
        : amount.toFixed(0);

        this.updateNumericLabelTexture(label);

    this.blockLabel.reseatingAmount.tint =
      status === 'AVAILABLE'
        ? TINT_SEAT_LABEL_AVAILABLE
        : TINT_SEAT_LABEL_SELECTED;
  }

  get label(): Container {
    return this.blockLabel.label;
  }
  

  /**
   * Check if this block intersects with a given circle.
   *
   * @param c The center of the circle to check against.
   * @param r The radius of the circle to check against.
   */
  intersectsCircle(c: Point, r: number): boolean {
    // Check every polygon that makes up the block against the circle.
    // It would be wrong to shortcut this and check for example only
    // against the convex hull of the block as the circle could fit
    // "between" the polygons without any intersections.
    // TODO: There could be a more efficient algorithm for this.
    for (const outline of this.outlines) {
      if (circleCollidesPolygon(c, r, new Polygon(outline))) {
        return true;
      }
    }

    return false;
  }
}


function createBlockLabelText(blockLabel: string): BitmapText {
  const label = new BitmapText(sanitizeStringForLabelUse(blockLabel), {
    fontSize: STAND_BLOCK_LABEL_SIZE,
    fontName: LABEL_BITMAP_FONT,
    tint: LABEL_DEFAULT_COLOR,
    align: 'center'
  });
  label.eventMode = 'passive';
  return label;
}


function createBlockOutline(block: VenueBlockData): Graphics {
  // create outline
  const blockOutline = new Graphics();
  blockOutline.lineStyle(0.1, 0xffffff);

  // standing blocks are displayed as filled
  if (block.type == 'standing') {
    blockOutline.beginFill(0xffffff);
  }

  blockOutline.drawPolygon(block.outlines);

  blockOutline.endFill();
  blockOutline.tint = TINT_BLOCK_OUTLINE;
  blockOutline.position.copyFrom(block.centerPosition);
  return blockOutline;
}


async function createBlockLabel(block: VenueBlockData): Promise<BlockLabel> {
  const labelContainer = new Container();
  const badgeContainer = new Container();
  const reseatingBadgeContainer = new Container();
  const labelSprite = createBlockLabelText(block.name);
  const badgeSprite = await createAmountBadge();
  const amountSprite = await createAmountLabel();
  const reseatingBadgeSprite = await createReseatingBadge();
  const reseatingBadgeBackground = await createReseatingBadgeBackground();
  const reseatingAmountSprite = await createReseatingLabel();

  labelContainer.addChild(labelSprite, badgeContainer, reseatingBadgeContainer);
  badgeContainer.addChild(badgeSprite, amountSprite);
  reseatingBadgeContainer.addChild(
    reseatingBadgeBackground,
    reseatingBadgeSprite,
    reseatingAmountSprite,
  );

  labelContainer.pivot.set(labelSprite.width / 2, labelSprite.height / 2);
  labelContainer.position.copyFrom(calculateShapeCenter(block.outlines).add(block.centerPosition));

  const labelTextureWidth = labelSprite.width;
  const labelTextureHeight = labelSprite.height;
  // preserve aspect ratio when scaling the block labels.
  const labelScale = STAND_BLOCK_LABEL_SIZE / labelTextureHeight;

  badgeContainer.x = labelTextureWidth / 2;
  badgeContainer.y = -labelTextureHeight / 2;
  reseatingBadgeContainer.x = labelTextureWidth / 2;
  reseatingBadgeContainer.y = labelTextureHeight / 2;

  // Check if the label fits neatly inside the block's outline.
  // Otherwise, we scale the label down even more.
  // This check only makes sense for simple blocks, i.e. those that consist
  // only of a single polygon. For multi-part blocks the label will have
  // to be placed by hand to make sense anyway.
  const isNormalScale = block.outlines.length != 1 ||
    checkIfRectFitsWithinPolygon(
      block.centerPosition.x,
      block.centerPosition.y,
      labelTextureWidth * labelScale * 1.1,
      labelTextureHeight * labelScale * 1.1,
      block.outlines,
    );

  badgeContainer.scale.set(1 / labelScale);
  reseatingBadgeContainer.scale.set(1 / labelScale);
  labelContainer.scale.set(isNormalScale ? labelScale : labelScale * 0.5);

  return {
    label: labelContainer,
    labelText: labelSprite,
    badge: badgeContainer,
    amount: amountSprite,
    reseatingBadgeContainer: reseatingBadgeContainer,
    reseatingBadge: reseatingBadgeSprite,
    reseatingAmount: reseatingAmountSprite,
  };
}

export async function createBlockGraphic(block: VenueBlockData): Promise<Block> {
  const blockLabel = await createBlockLabel(block);
  const blockOutline = createBlockOutline(block);

  return new Block(
    block.id,
    block.type,
    block.name,
    Color(block.color).rgbNumber(),
    blockOutline,
    block.outlines,
    blockLabel,
  );
}

