import React, { useEffect, useRef, useState } from 'react';
import {
  getCheckout,
  getCheckoutFromOrderId,
  postCheckoutFromOrderIdSkipPayment,
  useCheckoutState
} from '../../state/Checkout';
import {
  chargeMollieOrder,
  chargeMolliePayment,
  chargePayonePayment,
  IMolliePaymentData,
  IPayonePaymentData,
  postCheckoutStartPayment,
  postPaymentFakeCharge,
  usePaymentState,
} from '../../state/Payment';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { getVenueEvent, useVenueEvent } from '../../state/VenueEvent';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import { useLocale } from '../../state/Localization';
import Spinner from '../../common/Spinner';
import { useDispatch } from 'react-redux';
import CountdownDialog from '../../components/CountdownDialog';
import FakePaymentComponent from '../../components/CheckoutPayment/FakePaymentComponent';
import PayonePaymentComponent from '../../components/CheckoutPayment/PayonePaymentComponent';
import { progressBarSteps } from '../../components/ProgressBar/ProgressBar';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Layout from '../../common/Layout';
import MolliePaymentComponent from '../../components/CheckoutPayment/MolliePaymentComponent';
import {
  getPaymentMethods,
  IPaymentMethod,
  usePaymentMethodState,
} from '../../state/PaymentMethod';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import CheckoutSummaryComponent from '../../components/CheckoutSummary';
import Drawer from '../../components/Drawer/Drawer';
import Header from '../../components/Header/Header';
import DrawerHandleSumComponent from '../../components/DrawerHandleSum';
import useInitParams from '../../util/useInitParams';
import './styles.scss';
import {useTenantConfig} from '../../state/TenantConfig';
import {hydrateString} from '../../util/localization';
import {setPageTitle} from '../../state/Session';

const CheckoutPayment: React.FC = () => {
  const { strings, titles } = useLocale();
  const { id, uid, tenantSlug, salesChannel } = useRouteMatch<{
    id: string;
    uid: string;
    tenantSlug: string;
    salesChannel: string;
  }>().params;
  const history = useHistory();
  const dispatch = useDispatch();
  const { initParams } = useInitParams();
  const { data: paymentMethods, fetching: paymentMethodsFetching } =
    usePaymentMethodState();
  const { status, expiresAt, fetching, legalContact } = useCheckoutState();
  const { venueEvent } = useVenueEvent();
  const {
    data: paymentState,
    error: paymentError,
    fetching: paymentStateFetching,
  } = usePaymentState();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [activePaymentMethod, setActivePaymentMethod] = useState<IPaymentMethod | null>(null);
  const [submitRequested, setSubmitRequested] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isOrderMethod, setIsOrderMethod] = useState(true);

  const { tenantConfig } = useTenantConfig();
  useEffect(() => {
    if (!tenantConfig.tenantName) {
      return;
    }

    dispatch(setPageTitle(hydrateString(titles.Payment, {'tenantName': tenantConfig.tenantName})));
  }, [tenantConfig.tenantName]);

  const checkInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!initParams?.venueEventId || !initParams.salesChannel) return;
    dispatch(getVenueEvent(initParams.venueEventId, initParams.salesChannel, ''));
  }, [dispatch, initParams]);

  useEffect(() => {
    if (!legalContact) {
      dispatch(getCheckout(salesChannel));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activePaymentMethod) {
      return;
    }

    if (paymentMethods.length) {
      const selectedMethod = paymentMethods.find(
        (item) => item.id === window.localStorage.getItem('activePaymentMethod'),
      );
      setActivePaymentMethod(isEmpty(selectedMethod) ? paymentMethods[0] : selectedMethod);
    }
  }, [paymentMethods, activePaymentMethod]);

  useEffect(() => {
    if (id && uid) {
      dispatch(getCheckoutFromOrderId(id, uid, salesChannel));
      dispatch(getPaymentMethods(salesChannel));
    }
  }, [id, uid]);

  useEffect(() => {

    switch (status) {
      case 'payment_pending':
        setLoading(false);
        if (activePaymentMethod) {
          dispatch(
            postCheckoutStartPayment(id, uid, salesChannel, {
              paymentMethodId: activePaymentMethod.id,
              createNew: true,
            }),
          );
        }
        break;
      case 'payment_confirmation_pending':
        if (id && activePaymentMethod) {
          dispatch(
            postCheckoutStartPayment(id, uid, salesChannel, {
              paymentMethodId: activePaymentMethod.id,
              createNew: false,
            }),
          );
        }
        break;
      case 'payment_processing':
      case 'complete_processing':
        setLoading(true);
        if (status === 'complete_processing') {
          setLoadingMessage('Die Zahlung war erfolgreich, warten Sie auf die Bearbeitung der Bestellung...');
        } else {
          setLoadingMessage('Verarbeitung...');
        }
        checkInterval.current = setInterval(() => {
          dispatch(getCheckoutFromOrderId(id, uid, salesChannel));
        }, 5000);
        break;
      case 'completed':
        setLoading(false);
        history.push({ pathname: `/${tenantSlug}/checkout/${salesChannel}/${id}/${uid}` });
        break;
      case 'canceled':
        setLoading(false);
        setLoadingMessage('Payment canceled');
        break;
      case 'expired':
        setLoading(false);
        setLoadingMessage('Payment expired');
        break;
      default:
        break;
    }

    return () => {
      if (checkInterval.current) clearInterval(checkInterval.current);
    };
  }, [status, activePaymentMethod]);

  const submitChildForm = () => {
    setSubmitRequested(true);
  };

  const cancelSubmitRequested = () => {
    setSubmitRequested(false);
  };

  const submitSkipPayment = () => {
    dispatch(postCheckoutFromOrderIdSkipPayment(id, uid, salesChannel));
  };

  const handlePaymentMethodChange = (id: string) => {
    if (!paymentMethods || paymentStateFetching) return;

    const selectedMethod = paymentMethods.find(
      (item) => item.id === id,
    );

    if (!selectedMethod) return;

    setActivePaymentMethod(selectedMethod);
    window.localStorage.setItem('activePaymentMethod', selectedMethod.id);

    setIsOrderMethod(selectedMethod?.resource === 'orders');
  };

  const handleFakePayment = () => {
    if (paymentState) {
      setSubmitRequested(false);
      dispatch(postPaymentFakeCharge(paymentState.id));
      history.push({
        pathname: `/${tenantSlug}/checkout/${salesChannel}/${id}/${uid}`,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const handlePayoneRequestPayment = async (encryptedCustomerInput: string | null) => {
    if (paymentState && id && uid && activePaymentMethod) {
      setLoading(true);
      setLoadingMessage('Verarbeitung...');
      setSubmitRequested(false);
      dispatch(
        chargePayonePayment(
          paymentState.id,
          encryptedCustomerInput,
          activePaymentMethod.id,
        ),
      );
      if (status === 'payment_pending') {
        checkInterval.current = setInterval(() => {
          dispatch(getCheckoutFromOrderId(id, uid, salesChannel));
        }, 5000);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleMollieRequestPayment = async () => {
    if (
      !paymentState ||
      !id ||
      !uid ||
      !activePaymentMethod ||
      paymentState.provider != 'mollie'
    ) {
      return;
    }

    setLoading(true);
    setLoadingMessage('Verarbeitung...');
    setSubmitRequested(false);

    if (paymentState.resource == 'orders') {
      dispatch(chargeMollieOrder(paymentState.id, activePaymentMethod.id));
    } else if (paymentState.resource == 'payments') {
      dispatch(chargeMolliePayment(paymentState.id, activePaymentMethod.id));
    }
  };

  const handleBackToCheckout = () => {
    // history push not working in the same page
    window.location.href = `/${tenantSlug}/checkout/${salesChannel}/${id}/${uid}/payment`;
  };
  if (paymentError || paymentState?.previousPaymentFailed) {
    return (
      <Layout
        title={loadingMessage}
        header={<Header />}
        main={
          <CountdownDialog
            title={strings.Countdown_Payment_Failed_Title}
            content={strings.Countdown_Payment_Failed_Text}
            btnText={strings.Countdown_Payment_Failed_Button}
            useTimer={false}
            onHandleClick={handleBackToCheckout}
            openDialog
          />
        }
      />
    );
  }

  if (loading) {
    return (
      <Layout
        title={loadingMessage}
        header={<Header />}
        infoText={venueEvent?.salesInformation}
        main={
          <>
            {status === 'canceled' ? (
              <Button
                variant="outlined"
                color="error"
                onClick={handleBackToCheckout}
              >
                {strings.Checkout_Payment_BtnBackToCheckout}
              </Button>
            ) : (
              <Spinner />
            )}
          </>
        }
        progress={status !== 'canceled' ? progressBarSteps.PAYMENT : undefined}
      />
    );
  } else if (fetching || paymentMethodsFetching) {
    return <Spinner />;
  }

  if (paymentMethodsFetching || !paymentMethods.length) {
    return (
      <Layout
        title={strings.Checkout_Summary_Empty}
        header={<Header />}
        progress={progressBarSteps.PAYMENT}
      />
    );
  }

  return (
    <Layout
      title={strings.Checkout_Payment_Title}
      header={<Header expires={expiresAt} />}
      main={
        <>
          {activePaymentMethod?.provider === 'mollie' && paymentMethods.map((item) => {
            const checkedId = activePaymentMethod
              ? activePaymentMethod.id
              : paymentMethods[0].id;
            return (
              <Accordion
                key={item.id}
                expanded={checkedId === item.id && !!paymentState && !paymentStateFetching}
                onChange={() => handlePaymentMethodChange(item.id)}
                className='paymentAccordionWrapper'
              >
                <AccordionSummary>
                  <div className={classNames('paymentAccordeonItem', {
                    'loading': checkedId === item.id && paymentStateFetching,
                    'active': checkedId === item.id && !paymentStateFetching
                  })}>
                    <div className="paymentAccordeonItem--radio"></div>
                    <div className="paymentAccordeonItem--title">{item.label}</div>
                    <img className="paymentAccordeonItem--icon" src={item.logo} alt={item.label} />
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {paymentState && checkedId === item.id && !paymentStateFetching &&
                    <MolliePaymentComponent
                      setIsFormValid={setIsFormValid}
                      handleMollieRequestPayment={handleMollieRequestPayment}
                      cancelSubmitRequested={cancelSubmitRequested}
                      submitRequested={submitRequested}
                      paymentData={paymentState as IMolliePaymentData}
                      active={checkedId === item.id}
                    />
                  }
                </AccordionDetails>
              </Accordion>
            );
          })}

          {activePaymentMethod?.provider !== 'mollie' &&
            <div className="paymentTabs">
              <div className="paymentTabs__panel">
                {paymentMethods.map((item, index) => {
                  const checkedId = activePaymentMethod
                    ? activePaymentMethod.id
                    : paymentMethods[0].id;

                  return (
                    <label key={index}>
                      <input
                        type="radio"
                        name={item.label}
                        value={item.id}
                        checked={checkedId === item.id}
                        disabled={paymentMethodsFetching}
                        onChange={() => handlePaymentMethodChange(item.id)}
                      />
                      <img src={item.logo} alt={item.label} />
                      <span>{item.label}</span>
                    </label>
                  );
                })}
              </div>
              <div className="paymentTabs__content">
                {activePaymentMethod?.provider === 'fake' &&
                  <FakePaymentComponent
                    handlePayment={handleFakePayment}
                    submitRequested={submitRequested}
                  />
                }
                {activePaymentMethod?.provider === 'payone' && paymentState &&
                  <PayonePaymentComponent
                    payoneData={paymentState as IPayonePaymentData}
                    handlePayoneRequestPayment={handlePayoneRequestPayment}
                    submitRequested={submitRequested}
                  />
                }
              </div>
            </div>
          }

          <div className="paymentButtons">
            {initParams && (
              <Button
                onClick={() => {
                  history.push(`/${initParams.tenantSlug}/checkout/${salesChannel}/contact`);
                }}
                color="secondary"
                variant="outlined"
                size="large"
                startIcon={<ChevronLeft />}
              >
                {strings.Shared_Back}
              </Button>
            )}
            <div className='paymentButtons__buttons'>
              <Button
                color="primary"
                variant="contained"
                size="large"
                endIcon={<ChevronRight />}
                onClick={submitChildForm}
                disabled={loading || paymentStateFetching || activePaymentMethod === null ||
                  (isOrderMethod && !isFormValid)}
              >
                {strings.Checkout_Payment_Btn}
              </Button>
              {salesChannel === 'backend' && (
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  endIcon={<ChevronRight />}
                  onClick={submitSkipPayment}
                >
                  {strings.Checkout_Payment_Btn_Skip}
                </Button>
              )}
            </div>
          </div>
          <CountdownDialog
            title={strings.Countdown_Title}
            content={strings.Countdown_Text}
            btnText={strings.Countdown_Button}
            to={expiresAt}
          />
        </>
      }
      progress={progressBarSteps.PAYMENT}
      aside={<CheckoutSummaryComponent readonly={true} />}
      drawer={
        <Drawer
          title="Bestellübersicht"
          handle={<DrawerHandleSumComponent />}
          actions={
            <>
              <Button
                color="primary"
                variant="contained"
                size="large"
                endIcon={<ChevronRight />}
                className="drawer__button"
                onClick={submitChildForm}
                disabled={loading || paymentStateFetching || activePaymentMethod === null}
              >
                {strings.Checkout_Payment_Btn}
              </Button>
              {initParams && (
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  startIcon={<ChevronLeft />}
                  className="drawer__button"
                  onClick={() => {
                    history.push(`/${initParams.tenantSlug}/checkout/${salesChannel}/contact`);
                  }}
                >
                  {strings.Shared_Back}
                </Button>
              )}
            </>
          }
        >
          <CheckoutSummaryComponent />
        </Drawer>
      }
    />
  );
};

export default CheckoutPayment;
