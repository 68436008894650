import { TicketSelection } from './';
import { Bundle } from './Bundle';
import { Place, SeatFlat } from './Place';
import { PricingCategory } from './PricingCategory';
import { PricingClass } from './PricingClass';
import {
  PurchasableItem,
  PurchasableItemSelected,
  PurchasableItemSelectedIdentifier,
} from './PurchasableItem';
import { PurchasableItemsByCategoryMap } from './PurchasableItemsByCategory';
import { SalesRule } from './SalesRule';
import { TicketSelectionStateAPIResponse } from './TicketSelectionStateAPIResponse';

const getSafe = <TKey, TValue>(key: TKey, map: Map<TKey, TValue>): TValue => {
  const value = map.get(key);
  if (value) {
    return value;
  }
  throw new Error(`Key "${key as unknown as string}" not found`);
};

export const mapTicketSelectionStateAPIResponse = (
  response: TicketSelectionStateAPIResponse
): TicketSelection => {
  const pricingCategoryMap = new Map(
    response.pricingCategories.map((pricingCategory) => [
      pricingCategory.id,
      pricingCategory as PricingCategory,
    ]),
  );
  const pricingClassMap = new Map(
    response.pricingClasses.map((pricingClass) => [
      pricingClass.id,
      pricingClass as PricingClass,
    ]),
  );
  const purchasableItems: PurchasableItem[] = response.purchasableItems.map(
    (purchasableItem) => ({
      ...purchasableItem,
      pricingCategory: getSafe(
        purchasableItem.pricingCategoryId,
        pricingCategoryMap,
      ),
      pricingCategoryId: undefined,
      pricingClass: getSafe(purchasableItem.pricingClassId, pricingClassMap),
      pricingClassId: undefined,
    }),
  );
  const purchasableItemMap = new Map(
    purchasableItems.map((purchasableItem) => [
      purchasableItem.id,
      purchasableItem,
    ]),
  );
  const purchasableItemsByCategory: PurchasableItemsByCategoryMap = new Map(
    response.purchasableItemsByCategory.map((purchasableItemsByCategory) => [
      purchasableItemsByCategory.pricingCategoryId,
      {
        pricingCategory: getSafe(
          purchasableItemsByCategory.pricingCategoryId,
          pricingCategoryMap,
        ),
        purchasableItems: purchasableItemsByCategory.purchasableItemIds.map(
          (purchasableItemId) => getSafe(purchasableItemId, purchasableItemMap),
        ),
      },
    ]),
  );
  const places: Place[] = response.places.map((placeFlat) => {
    let bundleMessage;

    for (const bundleFlat of response.bundles) {
      if (!bundleFlat.valid && bundleFlat.places.includes(placeFlat.id)) {
        bundleMessage = bundleFlat.description;
      }
    }

    const expiresAt = new Date(placeFlat.expiresAt);
    const pricingCategory = getSafe(
      placeFlat.pricingCategoryId,
      pricingCategoryMap,
    );
    const selectedPurchasableItem = placeFlat.selectedPurchasableItem
      ? ({
          ...placeFlat.selectedPurchasableItem,
          [PurchasableItemSelectedIdentifier]: true,
          pricingCategory: getSafe(
            placeFlat.selectedPurchasableItem.pricingCategoryId,
            pricingCategoryMap,
          ),
          pricingClass: getSafe(
            placeFlat.selectedPurchasableItem.pricingClassId,
            pricingClassMap,
          ),
        } as PurchasableItemSelected)
      : undefined;
    switch (placeFlat.blockType) {
      case 'seating': {
        const seatFlat = placeFlat as SeatFlat;
        return {
          ...seatFlat,
          blockType: 'seating',
          bundleMessage: bundleMessage,
          expiresAt,
          pricingCategory,
          selectedPurchasableItem,
        };
      }
      case 'standing':
        return {
          ...placeFlat,
          blockType: 'standing',
          bundleMessage: bundleMessage,
          expiresAt,
          pricingCategory,
          selectedPurchasableItem,
        };
      default:
        throw Error();
    }
  });

  const selectedSalesRule = response.selectedSalesRuleId
    ? (response.salesRules.find(
        (salesRule) => salesRule.id === response.selectedSalesRuleId,
      ) as SalesRule)
    : undefined;

  return {
    bundles: response.bundles as Bundle[],
    places,
    pricingCategories: response.pricingCategories as PricingCategory[],
    pricingClasses: response.pricingClasses as PricingClass[],
    purchasableItems,
    purchasableItemsByCategory,
    purchaseForTicketHolder: response.purchaseForTicketHolder,
    salesRules: response.salesRules as SalesRule[],
    selectedSalesRule: selectedSalesRule,
  };
};
