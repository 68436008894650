import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Link } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { deletePlaces, useTicketSelection } from '../../state/TicketSelection';
import Countdown from '../Countdown';
import Ticket from '../Ticket';
import style from './style.module.css';
import { useFetch } from '../../state/Fetch';
import { useLocale } from '../../state/Localization';

interface TicketBasketComponentProps {
  expires?: Date;
}

const TicketBasketComponent: React.FC<TicketBasketComponentProps> = ({expires}) => {
  const dispatch = useDispatch();
  const { fetchComponent } = useFetch();
  const { strings } = useLocale();
  const ticketSelection = useTicketSelection();
  const places = ticketSelection?.places;

  const onRemoveAllClicked = useCallback(() => {
    dispatch(deletePlaces([], fetchComponent));
  }, [dispatch, fetchComponent]);

  return (
    <>
      <div className={style.Header}>
        {expires &&
          <div className={classNames(style.Expires)}>
            <Countdown to={expires} />
          </div>
        }
        <h2>{strings.Tickets_FirstRow}</h2>
      </div>
      {places?.length
        ? <div>
            {places.map((place, key) => (
              <Ticket key={key} index={key} place={place} />
            ))}
            {!!places.length &&
              <div className={style.RemoveAllButton}>
                <Link
                  onClick={onRemoveAllClicked}
                  component={'button'}
                >
                  {strings.Tickets_Item_Remove_All}
                  <DeleteOutline fontSize="small" />
                </Link>
              </div>
            }
          </div>
        : <p className={style.Empty}>{strings.Tickets_Empty}</p>
      }
    </>
  );
};

export default TicketBasketComponent;
