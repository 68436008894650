import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Footer from '../../components/Footer/Footer';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import style from './style.module.css';
import { progressBarSteps } from '../../components/ProgressBar/ProgressBar';
import { LocalizedString } from '../../state/VenueEvent/types/VenueEvent';
import { mapLocalizedString } from '../../util/localization';

export interface ILayoutProps {
  title?: string;
  header?: ReactNode;
  main?: ReactNode;
  aside?: ReactNode;
  drawer?: ReactNode;
  progress?: progressBarSteps;
  dark?: boolean;
  infoText?: LocalizedString;
  showAsideOnMobile?: boolean;
  showTitleOnMobile?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({
  title,
  header,
  main,
  aside,
  drawer,
  progress,
  dark,
  infoText,
  showAsideOnMobile,
  showTitleOnMobile,
}) => {
  return (
    <>
      <div className={classNames(style.Layout, {
        [style.PaddingBottom]: !!drawer
      })}>
        {header}
        <section className={style.Wrapper}>
          <section className={style.Main}>
            {progress &&
              <ProgressBar step={progress} />
            }
            {title &&
              <div className={classNames(style.Title, { [style.ShowOnMobile]: showTitleOnMobile })}>
                <h1>{title}</h1>
              </div>}
            {main}
            {drawer &&
              <aside className={style.Drawer}>
                {drawer}
              </aside>
            }
          </section>
          {aside &&
            <>
              <aside className={classNames(style.Aside, {[style.ShowOnMobile]: showAsideOnMobile})}>
                {aside}
              </aside>
              {mapLocalizedString(infoText) &&
                <div className={style.Info}>{mapLocalizedString(infoText)}</div>
              }
            </>
          }
        </section>
        <Footer infoText={infoText} />
      </div>
    </>
  );
};

export default Layout;
