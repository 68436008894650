import { Strings } from '../../util/localization';

export enum ActionType {
  ERROR = 'PAYMENT::ERROR',
  FETCH = 'PAYMENT::FETCH',
  SUCCESS = 'PAYMENT::SUCCESS',
  SUCCESS_PAYONE = 'PAYMENT::SUCCESS_PAYONE',
  SUCCESS_MOLLIE = 'PAYMENT::SUCCESS_MOLLIE'
}

export interface IFakePaymentData {
  provider: 'fake';
  id: string;
  previousPaymentFailed: boolean;
}

export interface IPayonePaymentProduct {
  id: number;
  paymentMethod: 'card' | 'redirect';
  label: string;
  logo: string;
}

export interface IPayonePaymentData {
  id: string;
  provider: 'payone';
  paymentProduct?: IPayonePaymentProduct;
  assetUrl: string;
  clientApiUrl: string;
  clientSessionId: string;
  currencyCode: string;
  redirectUrl: string;
  countryCode: string;
  customerId: string;
  amount: number;
  previousPaymentFailed: boolean;
  invalidTokens: [];
}

export interface IMolliePaymentData {
  id: string;
  provider: 'mollie';
  resource: 'payments' | 'orders';
  previousPaymentFailed: boolean;
  allowedCountries?: string[];
}

export type PaymentData = IFakePaymentData | IPayonePaymentData | IMolliePaymentData;

export interface FetchResponse {
  data: PaymentData | null;
}

// Response state
export interface PaymentState extends FetchResponse {
  error?: Error;
  fetching: boolean;
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface SuccessAction {
  payload: { response: FetchResponse };
  type: typeof ActionType.SUCCESS;
}
export interface SuccessPayoneAction {
  payload: { response: FetchResponse };
  type: typeof ActionType.SUCCESS_PAYONE;
}
export interface SuccessMollieAction {
  payload: { response: FetchResponse };
  type: typeof ActionType.SUCCESS_MOLLIE;
}

export interface FormCheckoutFields {
  salutation: string;
  firstName: string;
  lastName: string;
  address1?: string | null;
  address2?: string | null;
  companyName?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
  phone?: string | null;
  mobile?: string | null;
  email: string;
}

export interface FormCheckoutContactData {
  legalContact: FormCheckoutFields,
  invoiceContact: FormCheckoutFields,
  gdprAccepted?: false,
  tosAccepted?: false
}

export interface FormCheckoutFieldsComponent {
  contactType: string;
  className?: string;
  i18nFormFields: Strings;
  validatePhone: (value: string) => void;
  validateEmail: (value: string) => void;
}

export interface IStartPaymentRequest {
  paymentMethodId: string;
  createNew: boolean;
}

export type Action = ErrorAction | FetchAction | SuccessAction | SuccessPayoneAction | SuccessMollieAction;
