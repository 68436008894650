import { createFetchActions, FetchComponent } from '../../Fetch';
import { apiPOSTRequest, generateURL } from '../../../util/apiRequest';
import config from '../../../config.json';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import { ActionType as TicketSelectionActionType } from '../index';
import { TicketSelectionStateAPIResponse } from '../types/TicketSelectionStateAPIResponse';
import { ensureBasicState } from '../utils/ensureBasicState';
import { handleError } from '../../../util/handleError';
import { mapTicketSelectionStateAPIResponse } from '../types/mapTicketSelectionStateAPIResponse';
import { addPlacesPersonalization } from '../../PlacePersonalization';

export const addStandingPlace = (
  blockId: string,
  reseatingContractIds?: string[],
  standingPlacesNumber?: number,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      // userId,
      venueEventId,
    } = ensureBasicState(appState);
    const requestURL = generateURL(config.API_ENDPOINTS.ADD_PLACE, {
      params: { venueEventId, salesChannelKey: salesChannel },
      query: {
        purchaseForTicketHolderId,
        salesRuleId,
        subscriptionId,
      },
    });

    let fullName = '';
    const phoneNumber = '';
    const seasonTicketLegalRecipientId = '';

    if (appState.ticketSelection?.places.length === 0) {
      if (appState.ticketSelection.purchaseForTicketHolder) {
        fullName = `${
          appState.ticketSelection.purchaseForTicketHolder?.lastName || ''
        }, 
        ${appState.ticketSelection.purchaseForTicketHolder?.firstName || ''}`;
      } else if (appState.session.user) {
        fullName = `${appState.session.user?.lastName || ''}, ${
          appState.session.user?.firstName || ''
        }`;
      }
    }

    const rightsProviderId =
      appState.session.selectedRightsProvider?.id ?? appState.session.user?.id;
    const body = {
      blockId,
      blockType: 'standing',
      reseatingContractIds,
      rightsProviderId,
      standingPlacesNumber,
    };

    const stateResponse = (await apiPOSTRequest(
      requestURL,
      body,
    )) as TicketSelectionStateAPIResponse;
    const state = mapTicketSelectionStateAPIResponse(stateResponse);

    dispatch({ payload: { state }, type: TicketSelectionActionType.SET_STATE });

    state.places.forEach((place) =>
      dispatch(
        addPlacesPersonalization(
          place.id,
          fullName,
          phoneNumber,
          seasonTicketLegalRecipientId,
        ),
      ),
    );
  } catch (error) {
    handleError(dispatch, error as Error);
  } finally {
    dispatch(fetchStop);
  }
};
