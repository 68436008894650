import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { getNamedLogger } from '../util/logging';
import { parse } from 'query-string';
import config from '../config.json';
import { apiPOSTRequest, generateURL } from '../util/apiRequest';
import jwtDecode from 'jwt-decode';

/**
 * Signals that the current site access is the result of a silent renew callback.
 */
export interface SilentRenewState {
  type: 'SILENT_RENEW';
}

/**
 * Signals no valid authentication token is available.
 */
export interface AnonymousState {
  type: 'ANONYMOUS';
  accessToken: string | null;
  readonly context?: {
    readonly pathname: string;
    readonly search: string;
    readonly hash: string;
  };
}


interface IApiPostResponse {
  accessToken: string;
}

export interface IJwtPayload {
  exp: number;
  iss: string;
  sub: string;
  tenant_id: string;
  tenant_slug: string
}

/**
 * Signals that there is valid authentication token available.
 */

interface AuthContext {
  hash: string;
  pathname: string;
  search: string;
}

export interface AuthenticatedState {
  type: 'AUTHENTICATED';
  readonly accessToken: string;
  readonly context?: AuthContext;
  readonly mode: 'SSO' | 'TOKEN_ONLY';
}

/**
 * Authentication state of the application.
 */
export type AuthenticationState =
  | AnonymousState
  | AuthenticatedState
  | SilentRenewState;

const log = getNamedLogger('authentication');

// the platform SSO provider is very fussy about the redirect URLs: even a trailing slash or "foo//bar"
// mishap when building the below URLs will cause the authentication process to fail.
const APP_BASE_URL = `${window.location.protocol}//${window.location.host}`;
const RENEW_PATH = `${process.env.PUBLIC_URL}/silent-renew`;
const LOGIN_REDIRECT_URL = `${APP_BASE_URL}${process.env.PUBLIC_URL}`.replace(/\/+$/, '');
const RENEW_REDIRECT_URL = `${APP_BASE_URL}${RENEW_PATH}`;

// The number of seconds before the expiration of the access token to try to silently renew it.
const TOKEN_RENEW_THRESHOLD = parseInt(
  window.config?.REACT_APP_IAM_TOKEN_RENEW_THRESHOLD ?? '10',
);

const ACCESS_TOKEN_CHANGE_LISTENERS: AccessTokenChangeListener[] = [];

let AUTHENTICATION_STATE: AuthenticationState = { type: 'ANONYMOUS', accessToken: '' };

function notifyAccessTokenChangeListeners(accessToken: string) {
  ACCESS_TOKEN_CHANGE_LISTENERS.forEach((l) => l(accessToken));
}

function setAuthenticationState(
  state: AuthenticationState,
): AuthenticationState {
  AUTHENTICATION_STATE = state;
  return AUTHENTICATION_STATE;
}

export function getAuthenticationState(): AuthenticationState {
  return AUTHENTICATION_STATE;
}

const USER_MANAGER_CONFIG: UserManagerSettings = {
  accessTokenExpiringNotificationTime: TOKEN_RENEW_THRESHOLD,
  authority: window.config?.REACT_APP_IAM_AUTHORITY,
  automaticSilentRenew: false, // we trigger the renew by ourselves
  client_id: window.config?.REACT_APP_IAM_CLIENT_ID,
  loadUserInfo: false,
  //(boolean, default: true): Flag to control if additional identity data
  // is loaded from the user info endpoint in order to populate the user's profile.
  redirect_uri: LOGIN_REDIRECT_URL,
  post_logout_redirect_uri: LOGIN_REDIRECT_URL,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  //The URI of your client application to receive a response from the OIDC provider.
  response_type: window.config?.REACT_APP_IAM_RESPONSE_TYPE,
  scope: window.config?.REACT_APP_IAM_SCOPE,
  silent_redirect_uri: RENEW_REDIRECT_URL,
};

const USER_MANAGER: UserManager = ((): UserManager => {
  const userManager = new UserManager(USER_MANAGER_CONFIG);

  userManager.events.addAccessTokenExpiring(() => {
    const state = getAuthenticationState();

    if (state.type === 'AUTHENTICATED' && state.mode === 'SSO') {
      log.info(
        `AUTH: access token will expire in less than ${TOKEN_RENEW_THRESHOLD}s; attempting silent renew`,
      );
      userManager.signinSilent();
    }
  });

  userManager.events.addAccessTokenExpired(() => {
    log.warn('AUTH: access token has expired');
  });

  userManager.events.addUserLoaded((user) => {
    log.info(`AUTH: new access token received, valid for ${user.expires_in}s`);

    setAuthenticationState({
      accessToken: user.access_token,
      mode: 'SSO',
      type: 'AUTHENTICATED',
    });

    notifyAccessTokenChangeListeners(user.access_token);
  });

  userManager.events.addSilentRenewError((e) => {
    log.error('AUTH: access token could not be renewed: ', e.message);
  });

  return userManager;
})();

/**
 * Listener for when the access token changes
 */
export interface AccessTokenChangeListener {
  (accessToken: string): void;
}

export const isTokenExpired = (expiryTimestamp: number): boolean => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return expiryTimestamp <= currentTimestamp;
};

export const refreshAnonToken = async (tenantSlug: string, userId?: string ): Promise<string | null> => {
  const requestURL = generateURL(config.API_ENDPOINTS.POST_TOKEN_ANONYMOUS, {
    params: { tenantSlug },
  });

  if (tenantSlug) {    
    const response = await apiPOSTRequest(
      requestURL,
      userId ? { id: userId } : {},
      userId ? true : false
    ) as IApiPostResponse;

    if (response) {
      localStorage.setItem(`anonToken${tenantSlug}`, response.accessToken);
      return response.accessToken;
    } else {
      return null;
    }
  } else {
    return window.location.href = `${process.env.PUBLIC_URL}/not-found`;
  }
};

export const validateAndUpdateAnonToken = async (tenantSlug: string): Promise<boolean> => {
  if (document.hidden) return false;
  const anonToken = localStorage.getItem(`anonToken${tenantSlug}`);

  if (anonToken) {
    const jwtDecoded = jwtDecode<IJwtPayload>(anonToken);

    if (isTokenExpired(jwtDecoded.exp)) {
      await refreshAnonToken(tenantSlug, jwtDecoded.sub);
      return true;
    }
  }

  return false;
};

/**
 * Establishes the current authentication state.
 *
 * Determines if there is a valid authentication token available. If necessary
 * any OIDC callback parameters present in the URL are processed to receive
 * a current authentication token.
 */

// Call the function to set anonymous token when authenticated
const setAnonTokenWhenAuthenticated = async (state: AuthContext): Promise<void> => {
  const match = state.pathname.match(/^\/([^/]+)\/.*/);
  const tenantSlug = match ? match[1] : null;
  const getAnnonymousToken = localStorage.getItem(`anonToken${tenantSlug}`);
  if (tenantSlug && getAnnonymousToken) {
    const requestURL = generateURL(config.API_ENDPOINTS.POST_TOKEN_LOGIN, {
      params: { tenantSlug },
    });
    await apiPOSTRequest(requestURL, { beforeLoginUserToken: getAnnonymousToken });
  }
};


const setUserTokenWhenLogout = async (access_token: string, tenantSlug: string): Promise<void> => {
  if (tenantSlug) {
    const requestURL = generateURL(config.API_ENDPOINTS.POST_TOKEN_LOGOUT, {
      params: { tenantSlug },
    });

    const getAnnonymousToken = localStorage.getItem(`anonToken${tenantSlug}`);

    await fetch(requestURL, {
      body: JSON.stringify({ beforeLogoutUserToken: access_token }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAnnonymousToken}`
      },
      method: 'POST',
    });
  }
};

export async function checkAuthenticationState(tenantSlug: string): Promise<AuthenticationState> {
  const { access_token: accessToken } = parse(window.location.search);
  const isSilentRenewCallback = window.location.pathname.startsWith(RENEW_PATH);

  if (typeof accessToken === 'string') {
    // If we receive the access token directly via the URL trash any lingering
    // SSO state to prevent any conflicts of authentication data and unwanted
    // silent renews.
    await USER_MANAGER.removeUser();
    await USER_MANAGER.clearStaleState();

    log.info(
      'AUTH: access token detected in URL, skipping authentication via SSO',
    );

    return setAuthenticationState({
      accessToken,
      mode: 'TOKEN_ONLY',
      type: 'AUTHENTICATED',
    });
  }

  if (isSilentRenewCallback) {
    await USER_MANAGER.signinSilentCallback();

    log.info('AUTH: silent renew callback successful');

    return setAuthenticationState({ type: 'SILENT_RENEW' });
  }

  try {
    // The site might have been accessed through a login-redirect, so we first
    // try to see if we have a response from login which would contain the user.
    log.info('AUTH: checking for sign-in callback state');
    const user = await USER_MANAGER.signinRedirectCallback();

    log.info('AUTH: sign-in callback successful');

    await setAnonTokenWhenAuthenticated(user.state as AuthContext);

    return setAuthenticationState({
      accessToken: user.access_token,
      //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      context: user.state,
      mode: 'SSO',
      type: 'AUTHENTICATED',
    });
    
  } catch (error) {
    // If there is no login response we just try to load the authenticated user from storage
    log.info(
      'AUTH: no sign-in callback state available; loading authentication token from storage',
    );
    const user = (await USER_MANAGER.getUser()) ?? undefined;

    if (!user || user.expired) {
      log.info('AUTH: no valid authentication token available in storage');

      const anonToken = localStorage.getItem(`anonToken${tenantSlug}`);

      if (anonToken) {
        const jwtDecoded = jwtDecode<IJwtPayload>(anonToken);
        if (isTokenExpired(jwtDecoded.exp)) {
          const newToken = await refreshAnonToken(tenantSlug, jwtDecoded.sub);
          return setAuthenticationState({ type: 'ANONYMOUS', accessToken: newToken });
        } else {
          return setAuthenticationState({ type: 'ANONYMOUS', accessToken: anonToken });
        }
      } else {
        const newToken = await refreshAnonToken(tenantSlug);
        if (newToken) {
          return setAuthenticationState({ type: 'ANONYMOUS', accessToken: newToken });
        } else {
          return setAuthenticationState({ type: 'ANONYMOUS', accessToken: null });
        }
      }
    } else {
      log.info(
        `AUTH: authentication token loaded from storage, valid for ${user.expires_in}s`,
      );

      return setAuthenticationState({
        accessToken: user.access_token,
        mode: 'SSO',
        type: 'AUTHENTICATED',
      });
    }
  }
}

/**
 * Redirect to the external SSO provider
 */
export async function redirectToLogin(): Promise<void> {
  log.info(`AUTH: redirecting to SSO login from ${window.location.href}`);

  const state = {
    hash: window.location.hash,
    pathname: window.location.pathname,
    search: window.location.search
  };

  // The EF platform SSO provider does not support wildcard redirect URIs
  // therefore we need to send the current path as contextual state information
  // and restore this after returning to the fixed redirect_uri.
  return USER_MANAGER.signinRedirect({ state });
}

export function addAccessTokenChangeListener(
  listener: AccessTokenChangeListener,
) {
  ACCESS_TOKEN_CHANGE_LISTENERS.push(listener);
}

export async function signInSilent() {
  await USER_MANAGER.signinSilent();
}

/**
 * Initiates the sign out process by redirecting to the OIDC provider.
 */
export async function signOut(access_token: string, tenantSlug: string, logoutRedirectUrl: string): Promise<void> {
  log.info('AUTH: Initiating sign out process');
  await setUserTokenWhenLogout(access_token, tenantSlug);
  await USER_MANAGER.signoutRedirect();
  await checkPostLogoutState(logoutRedirectUrl);
}

export async function checkPostLogoutState(logoutRedirectUrl: string): Promise<void> {
  try {
    await USER_MANAGER.signoutRedirectCallback();
    window.location.href = logoutRedirectUrl;
    log.info('AUTH: Successfully processed sign-out callback');
  } catch (error) {
    log.error('AUTH: Error processing sign-out callback', error);
  }
}