import { Action, ActionType, BuildInfoState } from './types';

const initialState: BuildInfoState = {
  fetching: false,
};

export const reducer = (
  state = initialState,
  action: Action,
): BuildInfoState => {
  switch (action.type) {
    case ActionType.GET_BUILD_INFO: {
      const { buildInfo } = action.payload;
      return {
        ...state,
        buildInfo,
      };
    }
    case ActionType.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        fetching: false,
      };
    }
    case ActionType.FETCH: {
      return {
        ...state,
        fetching: true,
      };
    }
    case ActionType.SUCCESS: {
      const { buildInfo } = action.payload;
      return {
        ...state,
        buildInfo: buildInfo,
        error: undefined,
        fetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
