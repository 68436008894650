import { Dispatch } from 'redux';
import config from '../../config.json';
import { apiGETRequest, generateURL } from '../../util/apiRequest';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { handleError } from '../../util/handleError';
import { createFetchActions, FetchComponent } from '../Fetch';
import { ensureBasicState } from '../TicketSelection/utils/ensureBasicState';
import {
  Action,
  ActionType as PurchasableItemsActionType,
  PurchasableItems,
} from './types';
import { mapPurchasableItemsResponse } from './types/mapPurchasableItemsResponse';
import { PurchasableItemsResponse } from './types/PurchasableItemsResponse';
import { AppState } from '../../state';
export const clearPurchasableItems = (): Action => {
  const state = {
    placeId: '',
    pricingCategories: [],
    pricingClasses: [],
    purchasableItems: [],
  } as PurchasableItems;
  return {
    payload: { state },
    type: PurchasableItemsActionType.SET_PURCHASABLE_ITEMS,
  };
};

export const loadPurchasableItemsForPlace = (
  placeId: string,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
  dispatch(clearPurchasableItems());
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      venueEventId,
    } = ensureBasicState(appState);
    const requestURL = generateURL(config.API_ENDPOINTS.GET_PURCHASABLE_ITEMS, {
      params: { placeId, venueEventId, salesChannelKey: salesChannel },
      query: {
        purchaseForTicketHolderId,
        salesRuleId,
        subscriptionId,
      },
    });

    const stateResponse = (await apiGETRequest(
      requestURL,
    )) as PurchasableItemsResponse;
    const state = mapPurchasableItemsResponse(stateResponse, placeId);
    dispatch({
      payload: { state },
      type: PurchasableItemsActionType.SET_PURCHASABLE_ITEMS,
    });
  } catch (error) {
    if (error instanceof Error) {
      handleError(dispatch, error);
    }
  } finally {
    dispatch(fetchStop);
  }
};
