import { apiGETRequest, generateURL } from '../../util/apiRequest';
import config from '../../config.json';
import { createFetchActions, FetchComponent } from '../Fetch';
import { ActionType, VenuePlan } from './index';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { ensureBasicState } from '../TicketSelection/utils/ensureBasicState';
import { handleError } from '../../util/handleError';
import { Dispatch } from 'redux';
import { AppState } from '../../state';

export const getVenuePlan = (
  venueEventId: string,
  blockGroupId: string,
  salesChannelKey: string,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    dispatch({ type: ActionType.FETCH });

    const appState = getState();
    const { session } = appState;
    const {
      purchaseForTicketHolderId,
      salesRuleId,
      subscriptionId,
      userId,
    } = ensureBasicState(appState);
    const rightsProviderId = session.selectedRightsProvider?.id ?? userId;

    const requestURL = generateURL(config.API_ENDPOINTS.GET_AVAILABLE_SEATS, {
      params: {
        venueEventId,
        salesChannelKey,
      },
      query: {
        blockGroupId,
        purchaseForTicketHolderId,
        subscriptionId,
        rightsProviderId,
        salesRuleId,
      },
    });

    const venuePlan: VenuePlan = await apiGETRequest(requestURL) as VenuePlan;
    dispatch({ payload: { venuePlan }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  } finally {
    dispatch(fetchStop);
  }
};
