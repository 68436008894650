import { ActionType, FetchResponse } from './index';
import config from '../../config.json';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { apiGETRequest, generateURL } from '../../util/apiRequest';
import { handleError } from '../../util/handleError';
import { Dispatch } from 'redux';


export const getEventsList = (
  salesChannelKey: string
): AsyncReduxThunkAction => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: ActionType.FETCH });

      const requestURL = generateURL(config.API_ENDPOINTS.GET_EVENTS, {
        params: { salesChannelKey },
      });
      const events = (await apiGETRequest(requestURL)) as FetchResponse;
      dispatch({ payload: { response: { events } }, type: ActionType.GET_LIST_SUCCESS}); 
    } catch (error) {
      if (error instanceof Error) {
        dispatch({ payload: { error }, type: ActionType.ERROR });
        handleError(dispatch, error);
      }
    }
  };
};
