import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Default from './screens/Default/Default';
import MessageStack from './components/MessageStack';
import React, { useEffect } from 'react';
import SelectTicket from './screens/SelectTicket';
import { useDispatch } from 'react-redux';
 import VenueEventList from './screens/SalesChannelEventList';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Rmv from './screens/Rmv';
import NotFoundPage from './screens/NotFoundPage/NotFoundPage';
import RevokeTicket from './screens/RevokeTicket';
import CheckoutSummary from './screens/CheckoutSummary';
import CheckoutContactData from './screens/CheckoutContactData';
import CheckoutThanks from './screens/CheckoutThanks';
import CheckoutPayment from './screens/CheckoutPayment';
import { getTenantConfig, useTenantConfig } from './state/TenantConfig';
import { ThemeProvider } from '@mui/material';
import { createTenantTheme } from './util/muiTheme';
import {useLocale} from './state/Localization';

const publicURL = process.env.PUBLIC_URL;

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { tenantConfig } = useTenantConfig();
  const {titles} = useLocale();

  useEffect(() => {
    dispatch(getTenantConfig());
  }, [dispatch]);

  useEffect(() => {
    if (tenantConfig) {
      const { faviconUrl, primaryColor, secondaryColor } = tenantConfig;

      const faviconLinks: Array<{rel: string; sizes?: string; type?: string; href: string; color?: string }> = [
        { rel: 'apple-touch-icon', sizes: '180x180', href: faviconUrl },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: faviconUrl },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: faviconUrl },
        { rel: 'mask-icon', href: faviconUrl, color: primaryColor },
      ];

      faviconLinks.forEach((linkInfo) => {
        let link = document.querySelector(`link[rel~='${linkInfo.rel}']`);
        if (link) {
          link.parentNode?.removeChild(link);
        }
        link = document.createElement('link');
        for (const [key, value] of Object.entries(linkInfo)) {
          link.setAttribute(key, value);
        }
        document.head.appendChild(link);
      });

      const msTileColorMeta = document.querySelector('meta[name=\'msapplication-TileColor\']');
      if (msTileColorMeta) {
        msTileColorMeta.setAttribute('content', 'primaryColor');
      }

      const themeColorMeta = document.querySelector('meta[name=\'theme-color\']');
      if (themeColorMeta) {
        themeColorMeta.setAttribute('content', primaryColor);
      }

      document.documentElement.style.setProperty('--tenant-color', primaryColor);
      document.documentElement.style.setProperty('--tenant-color-light', secondaryColor);
    }
  }, [tenantConfig]);

  return (
    <ThemeProvider theme={createTenantTheme(tenantConfig)}>
      <BrowserRouter basename={publicURL}>
        <Switch>
          <Route path={'/:tenantSlug/placeSelection/:salesChannel/:venueEventId'}>
            <SelectTicket />
          </Route>
          <Route path="/:tenantSlug/revokeMarketplaceTicket/:salesChannel/:ticketId">
            <RevokeTicket />
          </Route>
          <Route path="/:tenantSlug/events/:salesChannel">
            <VenueEventList />
          </Route>
          <Route path="/:tenantSlug/checkout/:salesChannel/summary">
            <CheckoutSummary />
          </Route>
          <Route path="/:tenantSlug/checkout/:salesChannel/contact">
            <CheckoutContactData />
          </Route>
          <Route path="/:tenantSlug/checkout/:salesChannel/:id/:uid/payment">
            <CheckoutPayment />
          </Route>
          <Route path="/:tenantSlug/checkout/:salesChannel/:id/:uid">
            <CheckoutThanks />
          </Route>
          <Route path="/rmv">
            <Rmv />
          </Route>
          <Route path="/not-found">
            <NotFoundPage />
          </Route>
          <Route>
            <Default />
          </Route>
        </Switch>
        <MessageStack />
        <ToastContainer />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
