import { apiGETRequest, generateURL } from '../../util/apiRequest';
import { Dispatch } from 'redux';
import { ActionType, TenantConfig, IQueryParams } from './index';
import { handleError } from '../../util/handleError';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import config from '../../config.json';


const getQueryParams = (): IQueryParams => {
  const params = new URLSearchParams(window.location.search);
  const keys = [
    'primaryColor', 'secondaryColor', 'logoUrl', 'faviconUrl',
    'imprintUrl', 'gtcUrl', 'privacyPolicyUrl', 'websiteUrl',
    'hotlineNumber', 'hotlineReachability'
  ] as (keyof IQueryParams)[];

  const queryParams: IQueryParams = {};

  keys.forEach((key) => {
    const value = params.get(key);
    if (value) {
      queryParams[key] = value;
    }
  });

  return queryParams;
};

export const getTenantConfig = (): AsyncReduxThunkAction => async (
  dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.GET_TENANT_CONFIG, {});
    const tenantConfig: TenantConfig = await apiGETRequest(requestURL, true) as TenantConfig;

    const query = getQueryParams();

    dispatch({ payload: { tenantConfig: { ...tenantConfig, ...query } }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};
