import {
  HTTPError,
  HTTPKnownError,
  KnowErrors,
  SeasonTicketLegalRecipientIdNotFoundError,
} from './apiRequest';
import { MessageLevel, MessageType, addMessage } from '../state/Message';
import AsyncReduxThunkAction from './AsyncReduxThunkAction';
import { AVAILABILITY_UPDATE_INTERVAL } from '../components/PlaceSelection';

import { getRootLogger } from './logging';

const log = getRootLogger();

export const handleActionError = (error: Error): AsyncReduxThunkAction => {
  log.info('error: ', error);
  if (error instanceof HTTPKnownError) {
    switch (error.errorType as KnowErrors) {
      case KnowErrors.InvalidPlace:
        return addMessage({
          level: MessageLevel.Error,
          type: MessageType.InvalidPlace,
        });
      case KnowErrors.InvalidPurchasableItem:
        return addMessage({
          level: MessageLevel.Error,
          type: MessageType.InvalidPurchasableItem,
        });
      case KnowErrors.InvalidRightsProvider:
        return addMessage({
          level: MessageLevel.Error,
          type: MessageType.InvalidRightsProvider,
        });
      case KnowErrors.SubscriptionIdNotFound:
        return addMessage({
          level: MessageLevel.Error,
          type: MessageType.SubscriptionIdNotFound,
        });
      case KnowErrors.PersonalizationNotAllPlacesProvided:
      case KnowErrors.PersonalizationRequired:
        return addMessage({
          level: MessageLevel.Error,
          type: MessageType.PersonalizationRequired,
        });
      case KnowErrors.UserIsNotInTargetGroup:
        return addMessage({
          level: MessageLevel.Error,
          type: MessageType.UserIsNotInTargetGroup,
        });
      case KnowErrors.UserHasMaxTickets:
        return addMessage({
          level: MessageLevel.Warning,
          type: MessageType.UserHasMaxTickets,
        });
      case KnowErrors.PlaceIsBocked:
        return addMessage({
          level: MessageLevel.Error,
          type: MessageType.PlaceIsBocked,
        });
      case KnowErrors.FreePlacesAreMissing:
        return addMessage({
          level: MessageLevel.Warning,
          type: MessageType.FreePlacesAreMissing,
        });
      case KnowErrors.SalesChannelNotFound:
    }
  }

  if (error instanceof SeasonTicketLegalRecipientIdNotFoundError) {
    return addMessage({
      level: MessageLevel.Error,
      messageParam: error.id,
      type: MessageType.InvalidSeasonTicketLegalRecipientId,
    });
  }

  if (error instanceof HTTPError) {
    if (error.status >= 400 && error.status < 500) {
      return addMessage({
        level: MessageLevel.Warning,
        timeout: AVAILABILITY_UPDATE_INTERVAL - 20,
        type: MessageType.APIClientError,
      });
    }
    if (error.status >= 500 && error.status < 600) {
      return addMessage({
        level: MessageLevel.Error,
        timeout: AVAILABILITY_UPDATE_INTERVAL - 20,
        type: MessageType.APIServerError,
      });
    }
  }
  return addMessage({
    level: MessageLevel.Error,
    timeout: AVAILABILITY_UPDATE_INTERVAL - 20,
    type: MessageType.Error,
  });
};
