import {
  PricingCategory,
  PricingClass,
  PurchasableItem,
} from '../../TicketSelection';
import { PurchasableItems } from './index';
import { PurchasableItemsResponse } from './PurchasableItemsResponse';

const getSafe = <TKey, TValue>(key: TKey, map: Map<TKey, TValue>): TValue => {
  const value = map.get(key);
  if (value) {
    return value;
  }
  throw new Error(`Key "${key as unknown as string}" not found`);
};

export const mapPurchasableItemsResponse = (
  response: PurchasableItemsResponse,
  placeId: string,
): PurchasableItems => {
  const pricingCategoryMap = new Map(
    response.pricingCategories.map((pricingCategory) => [
      pricingCategory.id,
      pricingCategory as PricingCategory,
    ]),
  );
  const pricingClassMap = new Map(
    response.pricingClasses.map((pricingClass) => [
      pricingClass.id,
      pricingClass as PricingClass,
    ]),
  );
  const purchasableItems: PurchasableItem[] = response.purchasableItems.map(
    (purchasableItem) => ({
      ...purchasableItem,
      pricingCategory: getSafe(
        purchasableItem.pricingCategoryId,
        pricingCategoryMap,
      ),
      pricingCategoryId: undefined,
      pricingClass: getSafe(purchasableItem.pricingClassId, pricingClassMap),
      pricingClassId: undefined,
    }),
  );

  return {
    placeId: placeId,
    pricingCategories: response.pricingCategories as PricingCategory[],
    pricingClasses: response.pricingClasses as PricingClass[],
    purchasableItems,
  };
};
