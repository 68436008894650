import React from 'react';
import moment from 'moment-timezone';
import style from './style.module.css';
import { useLocale } from '../../state/Localization';
import CheckoutSummaryItem from '../CheckoutSummaryItem';
import { Event } from '../../state/Checkout';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

interface CheckoutSummaryEventProps {
  event: Event;
  readonly?: boolean;
}

const CheckoutSummaryEventComponent: React.FC<CheckoutSummaryEventProps> = (props) => {
  const { strings } = useLocale();
  const { tenantSlug } = useRouteMatch<{
    tenantSlug: string;
  }>().params;

  return (
    <div className={style.EventWrapper}>
      <Link to={`/${tenantSlug}/placeSelection/${props.event.items[0].salesChannel}/${props.event.id}`}>
        {props.event.title?.de}
      </Link>
      <div>
        {moment(props.event.startDate).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm')} {strings.Shared_TimeSuffix}
      </div>
      <div>
        {props.event.venueName}
        {props.event.venueName && props.event.venueCity && ', '}
        {props.event.venueCity}
      </div>

      <div>
        {props.event.items.map((cartItem) => (
          <CheckoutSummaryItem key={cartItem.id} cartItem={cartItem} readonly={props.readonly} />
        ))}
      </div>
    </div>
  );
};

export default CheckoutSummaryEventComponent;
