import { AppState } from '../../';

interface BasicState {
  jwtToken: string;
  purchaseForTicketHolderId: string;
  subscriptionId: string;
  queueittoken: string;
  salesChannel: string;
  salesRuleId: string;
  userId: string;
  venueEventId: string;
}

export const ensureBasicState = (state: AppState): BasicState => {
  const jwtToken = state.session.jwt;
  if (!jwtToken) {
    throw new Error('Ensure Basic State: JWT Token not set');
  }
  const salesChannel = state.session.salesChannel;
  if (!salesChannel) {
    throw new Error('Ensure Basic State: "salesChannel" not set');
  }

  const purchaseForTicketHolderId =
    state.session.purchaseForTicketHolderId ?? '';
  const subscriptionId = state.session.subscriptionId ?? '';
  const queueittoken = state.session.queueittoken ?? '';

  const salesRuleId = state.session.salesRuleId ?? '';

  const userId = state.session.user?.id;
  if (!userId) {
    throw new Error('Ensure Basic State: "userId" not set');
  }
  const venueEventId = state.venueEvent.venueEvent?.id;
  if (!venueEventId) {
    throw new Error('Ensure Basic State: "venueEventId" not set');
  }
  return {
    jwtToken,
    purchaseForTicketHolderId,
    salesChannel,
    salesRuleId,
    subscriptionId,
    userId,
    queueittoken,
    venueEventId,
  };
};
