import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { generateURL } from '../../util/apiRequest';
import config from '../../config.json';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';
import './styles.scss';


interface IEventTicketData {
  event_title: string;
  event_start_date: string;
  is_ticket_valid: boolean;
  error_message: string;
  is_fill_rmv_data: boolean;
  is_exists_rmv_pdf: boolean;
}

interface IErrorCode {
  error_code: string;
}

const Rmv: React.FC = () => {
  const location = useLocation();
  const [ticketId, setTicketId] = useState<string>('');
  const [eventTicketData, setEventTicketData] = useState({
    eventTitle: '',
    eventStartDate: '',
    isTicketValid: false,
    errorMessage: '',
    isFillRmvData: false,
    isExistsRmvPdf: false,
  });

  const [loadPdfUrl, setLoadPdfUrl] = useState<string>('');
  const [isFormBusy, setIsFormBusy] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [ticketIdInputValue, setTicketIdInputValue] = useState<string>('');

  const [isShowLoader, setIsShowLoader] = useState<boolean>(true);
  const [isShowFormEnterTicketId, setIsShowFormEnterTicketId] = useState<
    boolean
  >(false);
  const [
    isShowFormEnterRMVInformation,
    setIsShowFormEnterRMVInformation,
  ] = useState<boolean>(false);
  const [isShowTextWithLoadLink, setIsShowTextWithLoadLink] = useState<boolean>(
    false,
  );

  const [rmvData, setRmvData] = useState({
    birthday: new Date(),
    firstName: '',
    gender: 'male',
    lastName: '',
  });

  const showOnlyFormEnterRMVInformation = () => {
    setIsShowFormEnterTicketId(false);
    setIsShowFormEnterRMVInformation(true);
    setIsShowTextWithLoadLink(false);
  };
  const showOnlyFormEnterTicketId = () => {
    setIsShowFormEnterTicketId(true);
    setIsShowFormEnterRMVInformation(false);
    setIsShowTextWithLoadLink(false);
  };
  const showOnlyTextWithLoadLink = () => {
    setIsShowFormEnterTicketId(false);
    setIsShowFormEnterRMVInformation(false);
    setIsShowTextWithLoadLink(true);
  };

  const loadEventInformation = () => {
    if (!ticketId) {
      return;
    }
    const requestURL = generateURL(config.API_ENDPOINTS.GET_EVENT_DATA_FOR_RMV, {
      params: { ticketID: ticketId },
    });

    fetch(requestURL)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          // ticket not found or something else with server
          setErrorMessage('Ticket nicht gefunden!');
          showOnlyFormEnterTicketId();
          setIsShowLoader(false);
          setIsFormBusy(false);
        }
      })
      .then((res: IEventTicketData) => {
        if (res) {
          setEventTicketData({
            eventTitle: res.event_title,
            eventStartDate: moment(res.event_start_date).tz('Europe/Berlin').format(
              'DD.MM.YYYY HH:mm',
            ),
            isTicketValid: res.is_ticket_valid,
            errorMessage: res.error_message,
            isFillRmvData: res.is_fill_rmv_data,
            isExistsRmvPdf: res.is_exists_rmv_pdf,
          });
        }
        setIsShowLoader(false);
        setIsFormBusy(false);
      })
      .catch(() => {
        // ticket not found or something was wrong
        setErrorMessage('Ticket nicht gefunden!');
        showOnlyFormEnterTicketId();
        setIsShowLoader(false);
        setIsFormBusy(false);
      });
  };

  const loadPdf = () => {
    const requestURL = generateURL(config.API_ENDPOINTS.GET_RMV_PDF, {
      params: { ticketID: ticketId },
    });

    setLoadPdfUrl(requestURL);

    fetch(requestURL)
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          return response.json();
        }
      })
      .then((response: IErrorCode) => {
        if (
          response.error_code &&
          response.error_code === 'rmv_is_not_qualified'
        ) {
          setErrorMessage('Ticket ist nicht gültig!'); // is not valid
          showOnlyFormEnterTicketId();
        }

        if (response.error_code && response.error_code === 'rmv_too_early') {
          setErrorMessage(
            'Das RMV KombiTicket kann erst 7 Tage vor dem Veranstaltungsdatum aktiviert werden',
          );
          showOnlyFormEnterTicketId();
        }

        if (response instanceof Blob) {
          //all ok just show link to upload file
          showOnlyTextWithLoadLink();
          saveAs(response, ticketId + '.pdf');
        }
        setIsShowLoader(false);
        setIsFormBusy(false);
      })
      .catch(() => {
        setErrorMessage('Ticket ist nicht gültig!');

        setIsFormBusy(false);
        setIsShowLoader(false);

        showOnlyFormEnterTicketId();
      });
  };

  //set ticketID from url
  useEffect(() => {
    const searchParams = queryString.parse(location.search);

    if (
      searchParams.ticketID === undefined ||
      searchParams.ticketID === null ||
      searchParams.ticketID == ''
    ) {
      showOnlyFormEnterTicketId();
      setIsShowLoader(false);
    } else {
      setTicketId(
        searchParams.ticketID
          .toString()
          .toLowerCase()
          .replace(/\s/g, '')
          .replace('-', ''),
      );
      setTicketIdInputValue(searchParams.ticketID.toString());
    }
  }, []);

  // get event info after change ticketId
  useEffect(() => {
    if (ticketId) {
      loadEventInformation();
    }
  }, [ticketId]);

  useEffect(() => {
    if (eventTicketData.eventTitle) {
      setErrorMessage('');
      if (eventTicketData.isExistsRmvPdf) {
        loadPdf();
      } else if (!eventTicketData.isTicketValid) {
        // ticket not valid
        setErrorMessage(eventTicketData.errorMessage);
        showOnlyFormEnterTicketId();
      } else if (!eventTicketData.isFillRmvData) {
        showOnlyFormEnterRMVInformation();
      } else {
        loadPdf();
      }
      setIsShowLoader(false);
      setIsFormBusy(false);
    }
  }, [eventTicketData]);

  const handleSubmitRmvDataForm = (
    e: React.FormEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault();

    setIsFormBusy(true);
    setErrorMessage('');

    if (rmvData.firstName == '' || rmvData.lastName == '') {
      setIsFormBusy(false);
      setErrorMessage('Bitte Eingaben ausfüllen.');
    } else {
      const postURL = generateURL(config.API_ENDPOINTS.POST_RMV_DATA, {
        params: { ticketID: ticketId },
      });

      const body = {
        birthday: moment(rmvData.birthday).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm'),
        firstName: rmvData.firstName,
        gender: rmvData.gender,
        lastName: rmvData.lastName,
      };

      fetch(postURL, {
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })
        .then((res) => {
          if (res.status !== 204) {
            return res.json();
          }
        })
        .then((res: IErrorCode) => {
          if (res && res.error_code) {
            setErrorMessage('Ticket ist nicht gültig!');
            setIsFormBusy(false);
          } else if (ticketId) {
            loadPdf();
          }
        })
        .catch(() => {
          setErrorMessage('Ticket nicht gefunden!');
          setIsFormBusy(false);
        });
    }
  };

  const handleGenderChange = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setRmvData({ ...rmvData, gender: e.target.value });
  };

  const handleFirstNameChange = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setRmvData({ ...rmvData, firstName: e.target.value });
  };

  const handleLastNameChange = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setRmvData({ ...rmvData, lastName: e.target.value });
  };

  const handleDateChange = (date: Date) => {
    setRmvData({ ...rmvData, birthday: date });
  };

  const handleChangeTicketIdFromInput = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const regex = RegExp('^[a-zA-Z0-9 -]*$');
    if (regex.exec(e.target.value) !== null && e.target.value.length <= 27) {
      setTicketIdInputValue(e.target.value);
    }
  };

  const handleSubmitTicketIdForm = (
    e: React.FormEvent<HTMLFormElement>,
  ): void => {
    e.preventDefault();
    setErrorMessage('');
    setIsFormBusy(true);

    if (ticketIdInputValue.length < 24 || ticketIdInputValue.length > 27) {
      setErrorMessage(
        'Ticket-ID ist falsch! Die Länge sollte zwischen 24 und 27 Zeichen betragen.',
      );
      setIsFormBusy(false);
    } else {
      const newTicketID = ticketIdInputValue
        .toLowerCase()
        .replace(/\s/g, '')
        .replace('-', '');

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?ticketID=' +
        ticketIdInputValue;
      window.history.pushState({ path: newurl }, '', newurl);

      setTicketId(newTicketID);

      loadEventInformation();
    }
  };

  return (
    <>
      <h1 className='Title'>RMV KombiTicket</h1>

      {isShowLoader ? (
        <div className='LoaderContainer'>
          <div className='Loader'></div>
        </div>
      ) : (
        ''
      )}

      {isShowTextWithLoadLink ? (
        <>
          <div className='DownloadLinkBlock'>
            Dein Kombiticket wurde bereits aktiviert, &nbsp;
            <a href={loadPdfUrl} target="_blank" rel="noreferrer">
              hier kannst du es herunterladen
            </a>
            .
          </div>
        </>
      ) : (
        ''
      )}

      {isShowFormEnterRMVInformation ? (
        <>
          <div className='SubTitle'>
            Bitte fülle die Pflichtfelder aus, um dein RMV KombiTicket für
            folgenden Spieltag abzurufen:
          </div>

          <div className='EventInfo'>
            {eventTicketData.eventTitle} <br />
            {eventTicketData.eventStartDate}
          </div>
          <div className='RmvWarning'>
            RMV KombiTickets können nur ein einziges Mal aktiviert werden.
          </div>

          <div className='EnterRMVInfoBlock'>
            <form onSubmit={(e) => handleSubmitRmvDataForm(e)}>
              <div className='Row'>
                <label htmlFor="gender">Geschlecht:</label>
                <select
                  id="gender"
                  name="gender"
                  className='Gender'
                  onChange={(e) => handleGenderChange(e)}
                >
                  <option value="male">Männlich</option>
                  <option value="female">Weiblich</option>
                </select>
              </div>

              <div className='Row'>
                <label htmlFor="lastName">Nachname:</label>
                <input
                  id="lastName"
                  type="text"
                  name="lastName"
                  onChange={(e) => handleLastNameChange(e)}
                  required
                />
              </div>

              <div className='Row'>
                <label htmlFor="firstName">Vorname:</label>
                <input
                  id="firstName"
                  type="text"
                  name="firstName"
                  onChange={(e) => handleFirstNameChange(e)}
                  required
                />
              </div>

              <div className='Row'>
                <label htmlFor="birthday">Geburtsdatum:</label>

                <div className='Birthday'>
                  <DatePicker
                    id="birthday"
                    selected={rmvData.birthday}
                    onChange={(date: Date) => handleDateChange(date)}
                    dateFormat="dd.MM.Y"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    required
                    maxDate={new Date()}
                  />
                  <label htmlFor="birthday">
                    <span className='Calendar'></span>
                  </label>
                </div>
              </div>

              <div className='ErrorMessage'>{errorMessage}</div>

              <button disabled={isFormBusy}>
                <span className='ButtonText'>KombiTicket abrufen</span>
                {isFormBusy ? (
                  <span className='PositionLoaderInButton'>
                    <div className='Loader'></div>
                  </span>
                ) : (
                  ''
                )}
              </button>
            </form>
          </div>
        </>
      ) : (
        ''
      )}
      {isShowFormEnterTicketId ? (
        <>
          <div className='EnterTicketIdBlock'>
            <h3>
              Bitte trage hier die auf deinem Ticket dargestellte ID ein, um
              dein RMV KombiTicket zu aktivieren. Bitte beachte, dass eine
              Aktivierung erst frühestens 7 Tage vor Veranstaltungsdatum möglich
              ist.
            </h3>
            <div className='PathRmvBlock'>
              <img src="./path_rmv_beispiel.png" alt="path rmv" />
            </div>
            <form
              onSubmit={(e) => {
                handleSubmitTicketIdForm(e);
              }}
            >
              <label>
                Ticket ID:
                <input
                  name="ticketID"
                  type="text"
                  placeholder="XXXXXXXXXXXXXXXXXX-XXXXXX"
                  value={ticketIdInputValue}
                  onChange={(e) => {
                    handleChangeTicketIdFromInput(e);
                  }}
                  required
                />
              </label>
              <div className='ErrorMessage'>{errorMessage}</div>
              <button disabled={isFormBusy}>
                <span className='ButtonText'>KombiTicket abrufen</span>

                {isFormBusy ? (
                  <span className='PositionLoaderInButton'>
                    <div className='Loader'></div>
                  </span>
                ) : (
                  ''
                )}
              </button>
            </form>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default Rmv;
