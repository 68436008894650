import config from '../../../config.json';
import { apiDELETERequest, generateURL } from '../../../util/apiRequest';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import { handleError } from '../../../util/handleError';
import { createFetchActions, FetchComponent } from '../../Fetch';
import { removePlacesPersonalization } from '../../PlacePersonalization';
import { ActionType as TicketSelectionActionType } from '../types';
import { mapTicketSelectionStateAPIResponse } from '../types/mapTicketSelectionStateAPIResponse';
import { TicketSelectionStateAPIResponse } from '../types/TicketSelectionStateAPIResponse';
import { ensureBasicState } from '../utils/ensureBasicState';
import { getState as getTicketSelectionState } from './getState';

/**
 * Delete places from place selection, if placesIds array is empty then all places will be deleted
 *
 * @param placesIds if empty all places will be removed
 * @param fetchComponent
 */
export const deletePlaces = (
  placesIds: string[],
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      subscriptionId,
      salesChannel,
      salesRuleId,
      // userId,
      venueEventId,
    } = ensureBasicState(appState);
    const requestURL = generateURL(config.API_ENDPOINTS.DELETE_PLACES, {
      params: { venueEventId, salesChannelKey: salesChannel },
      query: {
        purchaseForTicketHolderId,
        salesRuleId,
        subscriptionId,
      },
    });

    const stateResponse = (await apiDELETERequest(requestURL, {
      placesIds: placesIds,
    })) as TicketSelectionStateAPIResponse;

    const state = mapTicketSelectionStateAPIResponse(stateResponse);

    dispatch({ payload: { state }, type: TicketSelectionActionType.SET_STATE });

    const selectionPlaces = state.places.map(
      (selectedPlace) => selectedPlace.id,
    );
    placesIds.map((deletedPlace) => {
      if (!selectionPlaces.includes(deletedPlace)) {
        dispatch(removePlacesPersonalization(deletedPlace));
      }
      return null;
    });
  } catch (error) {
    handleError(dispatch, error as Error);
    dispatch(getTicketSelectionState(fetchComponent));
  } finally {
    dispatch(fetchStop);
  }
};
