import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  IconButton,
  TextField
} from '@mui/material';
import classNames from 'classnames';
import MuiInputAdornment from '../../common/MuiInputAdornment/MuiInputAdornment';
import { DeleteOutline, ExpandMore } from '@mui/icons-material';
import { useFetch } from '../../state/Fetch';
import { useLocale } from '../../state/Localization';
import {
  addPlacesPersonalization,
  usePlacesPersonalization,
} from '../../state/PlacePersonalization';
import {
  clearPurchasableItems,
  loadPurchasableItemsForPlace,
  usePurchasableItems,
} from '../../state/PurchasableItems';
import {
  Place,
  deletePlaces,
  setPurchasableItem,
} from '../../state/TicketSelection';
import { useVenueEvent } from '../../state/VenueEvent';
import { get } from 'lodash';
import style from './style.module.css';

const TicketComponent: React.FC<{
  place: Place;
  index: number;
  reseating?: boolean;
}> = ({ place, index, reseating }) => {
  const dispatch = useDispatch();
  const { fetchComponent, fetchIndicator } = useFetch();
  const { language, strings } = useLocale();
  const purchasableItemsState = usePurchasableItems();
  const placesPersonalization = usePlacesPersonalization();
  const venueEvent = useVenueEvent();

  const [expanded, setExpanded] = useState(false);
  const [currentPlaceId, setCurrentPlaceId] = useState<string>();

  const [fullName, setFullName] = useState<string>(() => {
    const initialValue = get(placesPersonalization, `placesPersonalization[${index}].fullName`, '');
    return localStorage.getItem(`fullName_${place.id}`) || initialValue || '';
  });

  useEffect(() => {
    setExpanded(purchasableItemsState?.placeId === place.id);
  }, [purchasableItemsState?.placeId]);


  useEffect(() => {
    const getFullName = localStorage.getItem(`fullName_${place.id}`);
    if (getFullName) {
      dispatch(addPlacesPersonalization(place.id, getFullName, null, null));
    }
  }, []);

  useEffect(() => {
    setCurrentPlaceId(purchasableItemsState?.placeId);
  }, [purchasableItemsState?.placeId]);

  const onPurchasableItemOptionClicked = useCallback(
    (id: string) => {
      if (id !== currentPlaceId) {
        dispatch(setPurchasableItem(place.id, id, fetchComponent));
      }
      dispatch(clearPurchasableItems());
    },
    [dispatch, place.id, place.selectedPurchasableItem, fetchComponent]
  );

  const onChangePurchasableItemClicked = useCallback(() => {
    if (currentPlaceId !== place.id) {
      setCurrentPlaceId(place.id);
      dispatch(loadPurchasableItemsForPlace(place.id, fetchComponent));
    } else {
      setCurrentPlaceId(undefined);
      dispatch(clearPurchasableItems());
    }
  }, [currentPlaceId, dispatch, fetchComponent]);

  const onDeletePlaceClicked = useCallback(() => {
    dispatch(deletePlaces([place.id], fetchComponent));
  }, [dispatch, place.id, fetchComponent]);

  const handleFullNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setFullName(value);
      localStorage.setItem(`fullName_${place.id}`, value);
      dispatch(addPlacesPersonalization(place.id, value, null, null));
    },
    [dispatch, place.id]
  );

  const setPhoneNumber = useCallback(
    (eventArgs: React.ChangeEvent<HTMLInputElement>) => {
      const regex = RegExp('^[+]?[0-9( )\\/-]*$');

      if (regex.exec(eventArgs.target.value) !== null) {
        dispatch(
          addPlacesPersonalization(
            place.id,
            null,
            eventArgs.target.value,
            null,
          ),
        );
      }
    },
    [dispatch, place.id, fetchComponent],
  );

  const setSeasonTicketLegalRecipientId = useCallback(
    (eventArgs: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        addPlacesPersonalization(place.id, null, null, eventArgs.target.value),
      );
    },
    [dispatch, place.id, fetchComponent],
  );

  const getSeasonTicketLegalRecipientId = (): string =>
    placesPersonalization.placesPersonalization[index]
      ?.seasonTicketLegalRecipientId ?? '';

  const placeIsExpired = place.expiresAt < new Date();

  const isValidPersonalData = venueEvent.venueEvent?.personalDataOnTicketIsRequired &&
  fullName.length < 1 &&
  !placeIsExpired;

  return (
    <div className={`${style.Ticket} ${reseating ? style.TicketReseating : ''}`}>
      <div className={style.TicketCategory}>
        <div className={style.SpreadRow}>
          <div className={style.TicketTitle}>
            {place.selectedPurchasableItem
              ? place.selectedPurchasableItem?.publicName +
                ' ' +
                place.pricingCategory.name
              : place.pricingCategory.name}
          </div>
          <IconButton
            size='small'
            color="primary"
            onClick={onDeletePlaceClicked}
            title={strings.Tickets_Item_Remove}
          >
            <DeleteOutline fontSize="small" />
          </IconButton>
        </div>

        <div>
          {venueEvent.venueEvent?.personalDataOnTicketShouldBeCollected && (
            <>
              <div className={style.personalFieldWrapper}>
                <TextField
                  fullWidth
                  size='small'
                  className={classNames(style.formFieldInput, {[style.formFieldInputError] : isValidPersonalData })}
                  label={strings.Tickets_Item_PersonalizationData_Label}
                  variant="filled"
                  data-testid={`name${index}`}
                  onChange={handleFullNameChange}
                  InputProps={{
                    readOnly: placeIsExpired,
                  }}
                  value={fullName}
                />
                {isValidPersonalData && <MuiInputAdornment icon='error' />}
              </div>
                {isValidPersonalData ? (
                  <p className={style.Warning} data-testid={`warning${index}`}>
                    {strings.Tickets_Item_Name_Required}
                  </p>
                ) : (
                  <div
                    className={style.Margin}
                    data-testid={`margin${index}`}
                  ></div>
                )}
            </>
          )}

          {place.blockType === 'seating' ? (
            <div className={style.TicketBlock}>
              {strings.Shared_Block}&nbsp;{place.blockLabel}
              {', '}
              {strings.Shared_Row}&nbsp;{place.rowLabel}
              {', '}
              {strings.Shared_Seat}&nbsp;{place.seatLabel}
            </div>
          ) : (
            <div className={style.TicketBlock}>
              {place.blockLabel}
            </div>
          )}
          {place.rightsProvider ? (
            <div className={style.TicketTitle}>
              {strings.Tickets_Item_Rights_Provider}{' '}
              {place.rightsProvider.emailOrEfId}
            </div>
          ) : null}

          {place.selectedPurchasableItem &&
            (
              <div className={style.SelectedPurchasableItemContainer}>
                <Accordion
                  disabled={placeIsExpired}
                  expanded={expanded && !placeIsExpired}
                  disableGutters
                >
                  <AccordionSummary
                    onClick={onChangePurchasableItemClicked}
                    expandIcon={(
                      fetchIndicator.fetching &&
                      currentPlaceId === place.id)
                      ? <CircularProgress size={'21px'} color={'secondary'} />
                      : <ExpandMore color={'primary'} />
                    }
                  >
                    <div className={style.TicketSelect}>
                      <div>{place.selectedPurchasableItem.pricingClass.publicName}</div>
                      {!placeIsExpired && (
                        <div>
                          {(
                            (place.selectedPurchasableItem?.grossAmount ?? 0) / 100
                          ).toLocaleString(language, {
                            currency: 'EUR',
                            style: 'currency',
                          })}
                        </div>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {purchasableItemsState?.purchasableItems.map(
                      (purchasableItem, key) => (
                        <div
                          className={style.TicketOption}
                          key={key}
                          onClick={() => onPurchasableItemOptionClicked(purchasableItem.id)}
                        >
                          <div>{purchasableItem.pricingClass.publicName}</div>
                          <div>{((purchasableItem.grossAmount ?? 0) / 100).toLocaleString(language, {
                            currency: 'EUR',
                            style: 'currency',
                          })}</div>
                        </div>
                      ),
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            )
          }
        </div>
      </div>
      <div className={style.SpreadRow}>
        {placeIsExpired && (
          <span className={style.ExpiredRow}>
            {strings.Tickets_Item_Expired}
          </span>
        )}
        {/* <Button
          fetchIndicator={fetchIndicator}
          onClick={onDeletePlaceClicked}
          variant="sub"
        >
          {strings.Tickets_Item_Remove}
        </Button> */}
      </div>
      {place.bundleMessage && (
        <div>
          <span className={style.BundleMessageRow}>{place.bundleMessage}</span>
        </div>
      )}
    </div>
  );
};

export default TicketComponent;
