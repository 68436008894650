import {
  Action,
  ActionType,
  PaymentState
} from './types';

const initialState: PaymentState = {
  fetching: false,
  data: null
};

export const reducer = (
  state = initialState,
  action: Action,
): PaymentState => {
  switch (action.type) {
      case ActionType.ERROR: {
        const { error } = action.payload;
        return {
            ...state,
            error,
            fetching: false,
        };
      }
      case ActionType.FETCH: {
        return {
            ...state,
            fetching: true,
        };
      }
      case ActionType.SUCCESS: {
        const { response } = action.payload;
        return {
          ...state,
          data: response.data,
          fetching: false
        };
      }
      case ActionType.SUCCESS_PAYONE: {
        const { response } = action.payload;
        return {
          ...state,
          ...response,
          fetching: false
        };
      }
      case ActionType.SUCCESS_MOLLIE: {
        const { response } = action.payload;
        return {
          ...state,
          ...response,
          fetching: false
        };
      }
      default: {
        return state;
      }
  }
};
