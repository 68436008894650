import React from 'react';
import Section from '../Section';
import style from './style.module.css';
import { useLocale } from '../../state/Localization';
import { useVenueEvent } from '../../state/VenueEvent';

const GameSummaryComponent: React.FC = () => {
  const { venueEvent } = useVenueEvent();
  const { language } = useLocale();

  if (!venueEvent) {
    return null;
  }

  return (
    <Section>
      <div className={style.Container}>
        <div>
          <div className={style.ColumnMainTop}>
            <strong>{venueEvent.title}</strong>
            <span>{venueEvent.subtitle}</span>
          </div>
          <div className={style.ColumnMainBottom}>
            <strong>{venueEvent.eventSeries}</strong>
            {venueEvent.startDate && (
              <span>
                {venueEvent.startDate.toLocaleString(language, {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}{' '}
                {'/ '}
                {venueEvent.startDate.toLocaleString(language, {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                {' '}
                {venueEvent.venueName}
                {venueEvent.venueName && venueEvent.venueCity && ', '}
                {venueEvent.venueCity}
              </span>
            )}
            <span>{venueEvent.preliminaryDateText}</span>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default GameSummaryComponent;
