import * as PIXI from 'pixi.js';
import {range} from 'lodash';

export const LABEL_BITMAP_FONT = 'Label';
export const LABEL_BITMAP_FONT_NAME = 'Arial';

// Menge aller Zeichen aus dem ISO 8859-1 Zeichensatz, siehe https://de.wikipedia.org/wiki/ISO_8859-1
const ISO_8859_1_CHARSET: Set<string> = new Set([
    ...range(0x20, 0x7E + 1),
    ...range(0xA0, 0xFF + 1)
].map((c) => String.fromCharCode(c)));

const LABEL_CHARSET: Set<string> = new Set([...ISO_8859_1_CHARSET, ...'×�']);

const textStyle: PIXI.TextStyle = new PIXI.TextStyle({
    fontFamily: LABEL_BITMAP_FONT_NAME,
    fontSize: 96,
    fill: 0xFFFFFF,
});

PIXI.BitmapFont.from(LABEL_BITMAP_FONT, textStyle, {
    chars: [...LABEL_CHARSET.values()]
});

/**
 * Ersetzt in einem string alle Zeichen, die nicht im Label-Bitmap Font enthalten sind mit einem Platzhalter-Zeichen.
 */
export function sanitizeStringForLabelUse(text: string): string {
    return [...text].map((c) => LABEL_CHARSET.has(c) ? c : '�').join('');
}
