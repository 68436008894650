import React, { useEffect } from 'react';

export interface CheckoutPaymentProps {
  handlePayment: () => void;
  submitRequested: boolean;
}

const CheckoutPaymentComponent: React.FC<CheckoutPaymentProps> = ({
  handlePayment,
  submitRequested
}) => {

  useEffect(() => {
    if (submitRequested) {
      handlePayment();
    }
  }, [submitRequested]);

  return null;
};

export default CheckoutPaymentComponent;
