import React from 'react';
import './style.scss';

export interface ISpinnerProps {
  className?: string;
  fullSize?: boolean;
}

const Layout: React.FC<ISpinnerProps> = ({
  className,
  fullSize = true
}) => {
  return (
    <span className={`${className} ${fullSize ? 'positionSpinnerFullSize' : 'positionSpinnerRelative'}`}>
      <div className='Loader'></div>
    </span>
  );
};

export default Layout;
