import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSwipeable, SwipeEventData } from 'react-swipeable';
import { useResizeDetector } from 'react-resize-detector';
import './Drawer.scss';

interface DrawerProps {
  handle?: React.ReactNode;
  title?: React.ReactNode | string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  ticketCounter?: React.ReactNode;
  hideActions?: boolean;
}

const config = {
  delta: 10,
  preventScrollOnSwipe: true,
  rotationAngle: 0,
  swipeDuration: Infinity,
  touchEventOptions: { passive: true },
  trackMouse: true,
  trackTouch: true
};

const Drawer: React.FC<DrawerProps> = ({
  handle,
  title,
  actions,
  children,
  ticketCounter,
  hideActions = true
}) => {

  const drawerRef = useRef<HTMLDivElement>(null);

  const handlers = useSwipeable({
    onSwipeStart: () => onSwipeStart(),
    onSwiping: (event) => onSwiping(event),
    onSwiped: (event) => onSwiped(event),
    ...config,
  });

  const { height: contentHeight, ref: contentRef } = useResizeDetector();
  const { height: actionsHeight, ref: actionsRef } = useResizeDetector();

  const [startY, setStartY] = useState(actionsHeight ? -actionsHeight : 0);
  const [isOpen, setIsOpen] = useState(false);

  const hide = () => {
    if (!drawerRef.current || !actionsHeight || !contentHeight) return;
    const duration = contentHeight / 2500;
    drawerRef.current.style.transition = `transform ${duration}s ease-out`;
    drawerRef.current.style.transform = `translateY(-${actionsHeight}px)`;
    setStartY(-actionsHeight);
    setIsOpen(false);
  };

  const show = () => {
    if (isOpen || !drawerRef.current || !actionsHeight || !contentHeight) return;
    const duration = contentHeight / 2500;
    const targetY = hideActions ? contentHeight : contentHeight + actionsHeight;
    drawerRef.current.style.transition = `transform ${duration}s ease-out`;
    drawerRef.current.style.transform = `translateY(-${targetY}px)`;
    setStartY(-targetY);
    setIsOpen(true);
  };

  const onSwipeStart = () => {
    if (!drawerRef.current) return;
    drawerRef.current.style.transition = 'none';
  };

  const onSwiped = useCallback((event: SwipeEventData) => {
    if (!drawerRef.current || actionsHeight === undefined || contentHeight === undefined) return;

    const duration = Math.min(.5, (contentHeight - event.absY) / 2500);
    let targetY = startY;

    if (event.absY >= Math.min(50, contentHeight)) {
      const maxTargetY = hideActions ? contentHeight : contentHeight + actionsHeight;
      targetY = event.deltaY < 0 ? (-maxTargetY) : -actionsHeight;
    }

    drawerRef.current.style.transition = `transform ${duration}s ease-out`;
    drawerRef.current.style.transform = `translateY(${targetY}px)`;

    setStartY(targetY);
    setIsOpen(targetY !== -actionsHeight);
  }, [startY, contentHeight]);

  const onSwiping = useCallback((event: SwipeEventData) => {
    if (!drawerRef.current || !actionsHeight || !contentHeight) return;
    const maxTargetY = hideActions ? contentHeight : contentHeight + actionsHeight;
    const targetY = Math.min(-actionsHeight || 0, Math.max(-maxTargetY, startY + event.deltaY));
    drawerRef.current.style.transform = `translateY(${targetY}px)`;
    setIsOpen(actionsHeight + targetY < 0);
  }, [startY, contentHeight]);

  useEffect(() => {
    if (!drawerRef.current || !actionsHeight || !contentHeight || !isOpen) return;
    const targetY = hideActions ? contentHeight : contentHeight + actionsHeight;
    drawerRef.current.style.transition = 'none';
    drawerRef.current.style.transform = `translateY(-${targetY}px)`;
    setStartY(-targetY);
  }, [contentHeight]);

  useEffect(() => {
    if (!drawerRef.current || !actionsHeight || isOpen) return;
    drawerRef.current.style.transform = `translateY(-${actionsHeight}px)`;
    setStartY(-actionsHeight);
  }, [actionsHeight]);

  return (
    <>
      <div className={classNames('drawer', {
        'open': isOpen,
        'hide-actions': hideActions
      })}>
        <div className={'drawer-container'}>
          <div
            className='drawer-backdrop'
            onClick={() => hide()}
          ></div>

          <div className='drawer-wrapper' ref={drawerRef}>

            <div className={classNames('drawer-handle', {
              'drawer-handle-default': !handle,
              'drawer-handle-custom': !!handle
            })} {...handlers}>
              {handle &&
                <div className='custom' onClick={() => show()}>{handle}</div>
              }
              <div className='default'></div>
            </div>

            {children &&
              <div className='drawer-content'
                ref={contentRef}
                style={{ minHeight: `${actionsHeight ? actionsHeight * 2 : 0}px`}}
              >
                {title  && <div className='drawer-title'>{title}</div>}
                <div className='drawer-scroll'>{children}</div>
              </div>
            }

          </div>
        </div>
        {actions &&
          <div className='drawer-actions'>
            <div className='drawer-actions-inner' ref={actionsRef}>
              {ticketCounter &&
                <div className='drawer-action-counter' onClick={() => show() }>
                  {ticketCounter}
                </div>
              }
              <div className='drawer-action-buttons'>{actions}</div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Drawer;
