import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useLocale } from '../../state/Localization';
import { addStandingPlace } from '../../state/TicketSelection';
import { BlockSelectionEvent } from '../VenuePlan/interaction';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import style from './style.module.css';

import { 
  Button,
  Dialog, 
  DialogActions,
  DialogContent,
  DialogTitle, 
  IconButton
} from '@mui/material';
import {
  Add as AddIcon, 
  Close as CloseIcon,
  Remove as RemoveIcon
} from '@mui/icons-material';

if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

type StandingPlaceSelectionProps = {
  isShowModalChooseStandingPlace: boolean;
  setIsShowModalChooseStandingPlace: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  selectedBlockForStandingPlace: BlockSelectionEvent | undefined;
  availablePlacesInSelectedBlockForStandingPlace: number;
  placesInCurrentBlock: number;
};

/**
 * Select a standing place modal
 */
const StandingPlaceSelection: React.FC<StandingPlaceSelectionProps> = (
  props,
) => {
  const NUMBER_REGEX = RegExp('^[0-9]+$');

  const {
    isShowModalChooseStandingPlace,
    setIsShowModalChooseStandingPlace,
    selectedBlockForStandingPlace,
    availablePlacesInSelectedBlockForStandingPlace,
    placesInCurrentBlock,
  } = props;

  const [countInput, setCountInput] = useState(1);
  const dispatch = useDispatch();
  const { strings } = useLocale();

  useEffect(() => {
    setCountInput(availablePlacesInSelectedBlockForStandingPlace ? 1 : 0);
  }, [isShowModalChooseStandingPlace]);

  function closeModal(): void {
    setIsShowModalChooseStandingPlace(false);
    setCountInput(1);
  }

  function setCount(newCount: number): void {
    setCountInput(
      Math.max(
        0,
        Math.min(newCount, availablePlacesInSelectedBlockForStandingPlace),
      ),
    );
  }

  function handleClickMinus(): void {
    if (countInput > 1) {
      setCount(countInput - 1);
    }
  }

  function handleClickPlus(): void {
    setCount(countInput + 1);
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (NUMBER_REGEX.test(e.target.value)) {
      const newCount = parseInt(e.target.value, 10);
      if (newCount > 0) {
        setCount(newCount);
      }
    }
  };

  const handleFormSubmit = () => {
    if (selectedBlockForStandingPlace?.blockId && countInput) {
      dispatch(
        addStandingPlace(
          selectedBlockForStandingPlace.blockId,
          undefined,
          countInput,
        ),
      );
    }
    closeModal();
  };

  return (
    <Dialog 
      className={style.Modal}
      open={isShowModalChooseStandingPlace}
    >
      <IconButton 
        className={style.Close}
        onClick={closeModal}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle>
        {selectedBlockForStandingPlace?.blockName}
      </DialogTitle>
      
      <DialogContent>
        <span className={style.Description}>
          {strings.StandingplaceModal_Description}
        </span>

        <div className={style.InputBlock}>
          <span className={style.Minus} onClick={handleClickMinus}>
            <RemoveIcon />
          </span>
          <input
            className={style.Input}
            value={countInput}
            onChange={(e) => handleInputChange(e)}
          />
          <span className={style.Plus} onClick={handleClickPlus}>
            <AddIcon fontSize="large" />
          </span>
        </div>

      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFormSubmit}
          endIcon={<ArrowForwardIosIcon />}
        >
          {strings.StandingplaceModal_Submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StandingPlaceSelection;
