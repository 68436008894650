import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from '../../state/Localization';
import { useRouteMatch } from 'react-router-dom';
import style from './style.module.css';
import { getCheckoutFromOrderId, useCheckoutState } from '../../state/Checkout';
import Header from '../../components/Header/Header';
import Layout from '../../common/Layout';
import Spinner from '../../common/Spinner';
import CheckoutThanksItem from '../../components/CheckoutThanksItem';
import moment from 'moment-timezone';
import { getApiBaseUrl, getTenantSlugFromURL } from '../../util/apiRequest';
import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { refreshAnonToken } from '../../auth';
import {useTenantConfig} from '../../state/TenantConfig';
import {hydrateString} from '../../util/localization';
import {setPageTitle} from '../../state/Session';

const CheckoutThanksScreen: React.FC = () => {
  const { strings, titles } = useLocale();
  const { id, uid, salesChannel } = useRouteMatch<{
    id: string;
    uid: string;
    salesChannel: string;
  }>().params;
  const dispatch = useDispatch();
  const checkInterval = useRef<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const { events, orderId, createdAt, fetching, status, invoices } = useCheckoutState();
  const { tenantConfig } = useTenantConfig();
  useEffect(() => {
    if (!tenantConfig.tenantName) {
      return;
    }

    dispatch(setPageTitle(hydrateString(titles.Thanks, {'tenantName': tenantConfig.tenantName})));
  }, [tenantConfig.tenantName]);
  useEffect(() => {
    if (id && uid) {
      dispatch(getCheckoutFromOrderId(id, uid, salesChannel));
    }
    if (
      status === 'payment_pending' ||
      status === 'complete_processing' ||
      status === 'payment_confirmation_pending'
    ) {
      setLoading(true);

      if (status === 'complete_processing') {
        setLoadingMessage('Die Zahlung war erfolgreich, warten Sie auf die Bearbeitung der Bestellung...');
      } else {
        setLoadingMessage('Verarbeitung...');
      }

      checkInterval.current = setInterval(() => {
        dispatch(getCheckoutFromOrderId(id, uid, salesChannel));
      }, 5000);
    }

    if (status === 'completed') {
      setLoading(false);
      const tenantSlug = getTenantSlugFromURL();
      localStorage.removeItem(`anonToken${tenantSlug}`);
      refreshAnonToken(tenantSlug);
    }

    return () => {
      if (checkInterval.current) clearInterval(checkInterval.current);
    };
  }, [id, uid, status]);

  if (loading) {
    return (
      <Layout title={loadingMessage} main={<Spinner />} />
    );
  } else if (fetching) {
    return <Spinner />;
  }

  return (
    <Layout title={strings.Checkout_Thanks_Title}
      showAsideOnMobile={true}
      showTitleOnMobile={true}
      header={<Header />}
      main={
        <div className={style.Description}>
          {strings.Checkout_Thanks_Description1}
          <br /><br />
          {strings.Checkout_Thanks_Description2}
          <br /><br />
          {strings.Checkout_Thanks_Description3}
          {!isEmpty(invoices) && (
            <>
              <br /><br />
              <span>{strings.Checkout_Thanks_Invoice_Text}</span>
              <br /><br />
                {invoices.map((invoice) => (
                    <div key={invoice.id} className={style.ThankYouButtons}>
                      <span>
                        {invoice.type === 'main'
                            ? strings.Checkout_Thanks_Invoice_Label_Main
                            : strings.Checkout_Thanks_Invoice_Label_Cancelled
                        } {invoice.formattedInvoiceNumber}
                      </span>
                      <Button
                          key={invoice.id}
                          variant="contained"
                          href={`${getApiBaseUrl()}/invoice/order/${id}/uid/${uid}/invoice/${invoice.id}`}
                          target="_blank"
                          rel="noreferrer"
                      >
                        {invoice.type === 'main'
                            ? strings.Checkout_Thanks_Invoice_Button_Main
                            : strings.Checkout_Thanks_Invoice_Button_Cancelled
                        }
                      </Button>
                    </div>
                ))}
            </>
          )}

        </div>
      }
      aside={
        <>
          <h2>{strings.Checkout_Thanks_Tickets}</h2>

          <div className={style.OrderDetails}>
            {`${strings.Checkout_Thanks_orderId} ${orderId}`}
            <br />
            {`${strings.Checkout_Thanks_orderCreatedAt
              } ${moment(createdAt).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm')}`
            }
          </div>

          <div className={style.Tickets}>
            {events.map((event) =>
              event.items.map((cartItem) => (
                <CheckoutThanksItem
                  key={cartItem.id}
                  cartItem={cartItem}
                  startDate={moment(event.startDate).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm')}
                  titleEvent={event.title?.de}
                  venueName={event.venueName}
                  downloadLink={
                    `${getApiBaseUrl()}/ticketspickup/order/${id}/uid/${uid}/cartItem/${cartItem.id}/ticket.pdf`
                  }
                />
              ))
            )}
          </div>
        </>
      }
    />
  );
};

export default CheckoutThanksScreen;
