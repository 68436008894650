import React, { useCallback, useEffect, useState } from 'react';
import style from './style.module.css';
import { useRouteMatch } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocale } from '../../state/Localization';
import { useFetch } from '../../state/Fetch';
import { useDispatch } from 'react-redux';
import { sendRevokeTicket } from './sendRevokeTicket';
import { useRevokeTicket } from '../../state/RevokeTicket';

const RevokeTicket: React.FC = () => {
  const dispatch = useDispatch();
  const submitFetch = useFetch();
  const { strings } = useLocale();
  const { salesChannel, ticketId } = useRouteMatch<{
    salesChannel: string;
    ticketId: string;
  }>().params;
  const { isRevokeTicketSent, responseRevokeStatus } = useRevokeTicket();

  const successMessage = strings.Revoke_Success_Message;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isShowLoader, setIsShowLoader] = useState<boolean>(true);
  const [isShowSuccessMessage, setIsShowSuccessMessage] = useState<boolean>(
    false,
  );

  const onSubmitClicked = useCallback(() => {
    dispatch(
      sendRevokeTicket(ticketId, salesChannel, submitFetch.fetchComponent),
    );
  }, [dispatch, ticketId]);

  useEffect(() => {
    if (isRevokeTicketSent) {
      switch (responseRevokeStatus) {
        case 204:
          setIsShowSuccessMessage(true);
          break;
        case 404:
          setErrorMessage(strings.Revoke_Error_Ticket_Not_Found);
          break;
        case 400:
          setErrorMessage(strings.Revoke_Error_Cannot_Revoke);
          break;
        case 403:
          setErrorMessage(strings.Revoke_Error_Alien_Ticket);
          break;
        default:
          setErrorMessage(strings.Revoke_Error_Cannot_Revoke);
          break;
      }
      setIsShowLoader(false);
    }
  }, [isRevokeTicketSent]);

  useEffect(() => {
    onSubmitClicked();
  }, []);

  return (
    <>
      <h1 className={style.Title}>{strings.Revoke_Title}</h1>
      {isShowSuccessMessage ? (
        <div className={style.SuccessMessage}>{successMessage}</div>
      ) : (
        ''
      )}
      {isShowLoader ? (
        <div className={style.LoaderContainer}>
          <div className={style.Loader}></div>
        </div>
      ) : (
        ''
      )}
      <div className={style.ErrorMessage}>{errorMessage}</div>
    </>
  );
};

export default RevokeTicket;
