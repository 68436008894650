import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { getCheckout, useCheckoutState } from '../../state/Checkout';
import CheckoutSummaryComponent from '../../components/CheckoutSummary';
import Spinner from '../../common/Spinner';
import {useTenantConfig} from '../../state/TenantConfig';
import {useLocale} from '../../state/Localization';
import {hydrateString} from '../../util/localization';
import {setPageTitle} from '../../state/Session';

const CheckoutSummaryScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { fetching } = useCheckoutState();
  const { salesChannel } = useRouteMatch<{
    salesChannel: string;
  }>().params;
  const {titles} = useLocale();

  const { tenantConfig } = useTenantConfig();
  useEffect(() => {
    if (!tenantConfig.tenantName) {
      return;
    }

    dispatch(setPageTitle(hydrateString(titles.Checkout, {'tenantName': tenantConfig.tenantName})));
  }, [tenantConfig.tenantName]);

  useEffect(() => {
    dispatch(getCheckout(salesChannel));
  }, [dispatch]);

  return (
    fetching ? (
      <Spinner />
    ) : (
      <CheckoutSummaryComponent />
    )
  );
};

export default CheckoutSummaryScreen;
