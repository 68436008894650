import React from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';

export interface IInputAdornment {
    onClick?: () => void;
    icon?: 'cancel' | 'error';
}

const MuiInputAdornment: React.FC<IInputAdornment> = ({
    onClick,
    icon = 'cancel'
}) => {
  return (
    <InputAdornment position="end">
        <IconButton
            onClick={onClick}
            edge="end"
            tabIndex={-1}
        >
            {icon === 'cancel' && <CancelIcon sx={{ color: '#D1D1D1', fontSize: '24px' }} />}
            {icon === 'error' && <ErrorIcon sx={{ color: '#B3261E', fontSize: '24px' }} />}
        </IconButton>
    </InputAdornment>
  );
};

export default MuiInputAdornment;


