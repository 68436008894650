export enum ActionType {
  SET_PERSONALIZATION = 'PLACES_PERSONALIZATION::SET_PERSONALIZATION',
}

export interface PlacesPersonalizationState {
  placesPersonalization: PlacesPersonalization[];
}

export interface PlacesPersonalization {
  placeId: string;
  fullName: string;
  phoneNumber: string;
  seasonTicketLegalRecipientId: string;
  isSeasonTicketLegalRecipientIdWrong?: boolean;
}

interface SetPlacesPersonalization {
  payload: { placesPersonalization: PlacesPersonalization[] };
  type: typeof ActionType.SET_PERSONALIZATION;
}

export type Action = SetPlacesPersonalization;
