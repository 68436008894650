import React from 'react';
import classNames from 'classnames';
import { Event } from '../../state/Checkout';
import { useLocale } from '../../state/Localization';
import { useCheckoutState } from '../../state/Checkout';
import { sumCartItems } from '../../util/sumCartItems';
import style from './style.module.css';
import CheckoutSummaryEvent from '../CheckoutSummaryEvent';
import Countdown from '../Countdown';

interface CheckoutSummaryComponentProps {
  readonly?: boolean;
}

const CheckoutSummaryComponent: React.FC<CheckoutSummaryComponentProps> = ({readonly}) => {
  const { language, strings } = useLocale();
  const { events, expiresAt } = useCheckoutState();

  if (events.length == 0) {
    return (
      <h2 className={style.CheckoutSummaryEmpty}>
        {strings.Checkout_Summary_Empty}
      </h2>
    );
  }

  return (
    <div className={style.Summary}>
      <div className={style.SummaryTitle}>

        <div className={classNames(style.Expires, { [style.Hidden]: !expiresAt })}>
          <Countdown to={new Date(expiresAt || 0)} />
        </div>
        <h2>{strings.Checkout_Summary_Title}</h2>
      </div>
      <div className={style.SummaryContent}>
        {events.map((event) => (
          <CheckoutSummaryEvent key={event.id} event={event} readonly={readonly} />
        ))}
      </div>
      <div className={style.SummaryTotalWrapper}>
        <div className={style.SummaryTotalAmount}>
          <div>{strings.Checkout_Summary_Total}</div>
          {sumCartItems(events).toLocaleString(language, {
            currency: 'EUR',
            style: 'currency',
          })}
        </div>
        <div className={style.SummaryTotalAdditional}>inkl. MwSt.</div>
      </div>
    </div>
  );
};

export interface CheckoutSummaryProps {
  events: Event[];
}

export default CheckoutSummaryComponent;
