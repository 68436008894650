import { FetchIndicator } from '../../state/Fetch';
import React, { ReactNode } from 'react';
import style from './style.module.css';
import images from '../../assets/images';

type StyleVariant = 'primary' | 'secondary' | 'default' | 'sub' | 'error' | undefined;

const selectVariant = (variant: StyleVariant): string => {
  switch (variant) {
    case 'primary':
      return style.VariantPrimary;
    case 'secondary':
      return style.VariantSecondary;
    case 'sub':
      return style.VariantSub;
    case 'error':
      return style.VariantError;
    case 'default':
    default:
      return style.VariantDefault;
  }
};

const ButtonComponent: React.FC<{
  component?: 'button' | 'a';
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  fetchIndicator?: FetchIndicator;
  href?: string;
  onClick?: (
    eventArgs: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  variant?: StyleVariant;
  testid?: string;
}> = ({
  children,
  className,
  component,
  disabled,
  fetchIndicator,
  href,
  onClick,
  variant,
  testid,
}) => {
  if (fetchIndicator?.fetching && fetchIndicator.hasFocus) {
    return (
      <img
        src={images.loadingIndicator as string}
        width="30"
        height="30"
        alt="fetching..."
      />
    );
  }

  const fetchingStyle = fetchIndicator?.fetching ? style.Fetching : '';

  switch (component) {
    case 'a':
      return (
        <a
          className={`${style.ButtonBase} ${selectVariant(variant)} ${
            style.ComponentA
          } ${fetchingStyle}`}
          href={href}
        >
          {children}
        </a>
      );
    case 'button':
    default:
      return (
        <button
          className={`${className} ${style.ButtonBase} ${selectVariant(variant)} ${
            style.ComponentButton
          } ${fetchingStyle}`}
          disabled={fetchIndicator?.fetching ?? disabled}
          onClick={onClick}
          data-testid={testid}
        >
          {children}
        </button>
      );
  }
};

export default ButtonComponent;
