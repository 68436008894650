import { Bundle } from './Bundle';
import { Place } from './Place';
import { PricingCategory } from './PricingCategory';
import { PricingClass } from './PricingClass';
import { PurchasableItem } from './PurchasableItem';
import { PurchasableItemsByCategoryMap } from './PurchasableItemsByCategory';
import { RightsProvider } from './RightsProvider';
import { SalesRule } from './SalesRule';
import { UserData } from '../../../common/types';

export enum ActionType {
  SET_STATE = 'TICKET_SELECTION::SET_STATE',
}

export interface TicketSelection {
  bundles: Bundle[];
  places: Place[];
  pricingCategories: PricingCategory[];
  pricingClasses: PricingClass[];
  purchasableItems: PurchasableItem[];
  purchasableItemsByCategory: PurchasableItemsByCategoryMap;
  purchaseForTicketHolder: UserData;
  salesRules: SalesRule[];
  selectedSalesRule?: SalesRule;
}

export type TicketSelectionState = TicketSelection | null;

interface SetStateAction {
  payload: { state: TicketSelection };
  type: typeof ActionType.SET_STATE;
}

export type Action = SetStateAction;
export type {
  Place,
  PricingCategory,
  PricingClass,
  PurchasableItem,
  RightsProvider,
};
