import {
  PaymentProductFieldJSON,
  PaymentProductJSON
} from 'onlinepayments-sdk-client-js';

import { FormCheckoutFields } from '../state/Checkout';

export const filterNullFields = <T>(values: T): T => {
  const filteredData = {} as T;

  for (const key in values) {
    // eslint-disable-next-line
    if (typeof values[key] === null) {
      filteredData[key] = filterEmptyFields(values[key]);
    } else if (values[key] !== '' && values[key] !== undefined && values[key] !== null) {
      filteredData[key] = values[key];
    }
  }

  return filteredData;
};

export const filterEmptyFields = <T>(values: T): T => {
  const filteredData = {} as { [K in keyof T]: T[K] | null };

  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      if (typeof values[key] === 'object' && values[key] !== null) {
        filteredData[key] = filterEmptyFields(values[key]);
      } else if (values[key] !== '' && values[key] !== undefined) {
        filteredData[key] = values[key];
      } else {
        filteredData[key] = null;
      }
    }
  }

  return filteredData as T;
};

export const checkIsAllEmptyValues = (obj: FormCheckoutFields): boolean => {
  for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key as keyof FormCheckoutFields] !== '') {
          return false;
      }
  }
  return true;
};

export interface JsonKeyPaymentProducts {
  allowsRecurring: boolean;
  allowsTokenization: boolean;
  displayHints: {
    displayOrder: number;
    label: string;
    logo: string;
  };
  displayHintsList: {
    displayOrder: number;
    label: string;
    logo: string;
  };
  fields: PaymentProductFieldJSON[],
  id: number,
  paymentMethod: string,
  paymentProductGroup: string,
  usesRedirectionTo3rdParty: boolean,
  type: string
}

export interface IPaymentProductsData {
  [key: string]: {
    json: JsonKeyPaymentProducts;
  };
}

export interface IPaymentProduct {
  id: number;
  paymentMethod: 'card' | 'redirect';
  json?: PaymentProductJSON;
}

export type IKeyPaymentProducts = number[];

/**
  * Filters func For Payone
  *
  * @param Object format: {1: { data }, 3: { data }, 120: { data }, 7: { data }}
  *
  * @param Array Array of id [1, 3] : number[]
  *
  * @result Array format: [{ data1.json }, { data3.json }]
*/
export const filterPaymentsObjectByKeys = (data: IPaymentProductsData, keys: IKeyPaymentProducts) =>
  keys.map((key) => data[key.toString()]?.json);
