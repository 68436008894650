import React, { useEffect, useRef, useState } from 'react';
import { useLocale } from '../../../state/Localization';
import { IPayonePaymentData } from '../../../state/Payment';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { IPaymentProduct } from '../../../util/formHelpers';
import {
  PaymentContext,
  PaymentRequest,
  Session,
} from 'onlinepayments-sdk-client-js';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import './style.scss';
import { Button } from '@mui/material';

export interface CheckoutPaymentProps {
  payoneData: IPayonePaymentData;
  handlePayoneRequestPayment: (
    encryptedCustomerInput: string | null,
  ) => Promise<void>;
  submitRequested: boolean;
}

export interface IPaymentValues {
  cardNumber: string;
  cardholderName: string;
  expiryDate: string;
  cvv: string;
}

const initialValues: IPaymentValues = {
  cardNumber: '',
  cardholderName: '',
  expiryDate: '',
  cvv: '',
};

const CheckoutPaymentComponent: React.FC<CheckoutPaymentProps> = ({
  payoneData,
  handlePayoneRequestPayment,
  submitRequested
}) => {
  const { strings } = useLocale();
  const submitButton = useRef<HTMLButtonElement>(null);
  const [validationSchema, setValidationSchema] = useState<Yup.AnySchema>();
  const [activePaymentProduct, setActivePaymentProduct] =
    useState<IPaymentProduct | null>(null);

  const { paymentProduct, amount, currencyCode, countryCode, ...sessions } =
    payoneData;

  const session = new Session({
    ...sessions,
  });

  const paymentContext: PaymentContext = {
    locale: 'de-DE',
    countryCode,
    amountOfMoney: { amount, currencyCode },
  };

  useEffect(() => {
    // TODO: This is a workaraound, because we currently
    // do not find an proper solution to trigger form
    // submission from outside.
    if (submitRequested && submitButton.current) {
      submitButton.current.click();
    }
  }, [submitRequested]);

  // set payment product for first render
  useEffect(() => {
    if (!paymentProduct) {
      return;
    }

    if (paymentProduct.paymentMethod === 'redirect') {
      setActivePaymentProduct(paymentProduct);
      return;
    }

    session
      .getPaymentProduct(paymentProduct.id, paymentContext)
      .then((paymentProduct) => {
        setActivePaymentProduct({ ...paymentProduct, paymentMethod: 'card' });
      });
  }, []);

  // add dynamic validation schema and get data validation from active payment product
  useEffect(() => {
    if (
      activePaymentProduct &&
      activePaymentProduct.paymentMethod === 'card' &&
      activePaymentProduct.json
    ) {
      const getFields = activePaymentProduct.json.fields;
      const validations: Record<string, Yup.StringSchema> = {};
      getFields.map((fieldItem) => {
        let validation = Yup.string();
        // check if field is required
        if (fieldItem.dataRestrictions.isRequired) {
          validation = validation.required(
            `${fieldItem.displayHints?.label} is required.`,
          );
        }
        // check if field has validators
        const getValidators = fieldItem.dataRestrictions.validators;
        const getLength = getValidators.length;
        if (getLength) {
          if (getLength.minLength) {
            validation = validation.min(
              getLength.minLength,
              `Must be at least ${getLength.minLength} characters.`,
            );
          }
          if (getLength.maxLength) {
            validation = validation.max(
              getLength.maxLength,
              `Must be at most ${getLength.maxLength} characters.`,
            );
          }
        }
        // check if field has regular expression
        if (getValidators.regularExpression) {
          validation = validation.matches(
            new RegExp(getValidators.regularExpression?.regularExpression),
            `Enter a valid ${fieldItem.displayHints?.label}`,
          );
        }

        // check if field expiry date can't be in the past
        if (fieldItem.id === 'expiryDate') {
          validation = validation.test(
            'is-expiryDate-in-future',
            `${fieldItem.displayHints?.label} can't be in the past`,
            (value?: string) => {
              if (!value || value.length !== 4) return false;

              const month = parseInt(value.slice(0, 2), 10);
              const year = value.slice(2, 4);

              const currentYear = new Date().getFullYear().toString().slice(-2);
              const currentMonth = new Date().getMonth() + 1;

              if (year < currentYear) return false;
              if (year === currentYear && month < currentMonth) return false;

              return true;
            },
          );
        }
        validations[fieldItem.id] = validation.transform(
          (_, originalValue: string) => {
            if (fieldItem.id === 'expiryDate') {
              return originalValue.replace(/\//g, '');
            }
            return originalValue.replace(/\s+/g, '');
          },
        );
      });
      setValidationSchema(Yup.object(validations));
    } else {
      setValidationSchema(Yup.object({}));
    }
  }, [activePaymentProduct]);

  const handleSubmit = (
    values: IPaymentValues,
    { setErrors }: FormikHelpers<IPaymentValues>,
  ) => {
    if (
      activePaymentProduct &&
      activePaymentProduct.paymentMethod === 'redirect'
    ) {
      handlePayoneRequestPayment(null);
      return;
    }

    const paymentRequest = new PaymentRequest();
    paymentRequest.setValue('cardNumber', values.cardNumber);
    paymentRequest.setValue('cvv', values.cvv);
    paymentRequest.setValue('cardholderName', values.cardholderName);
    paymentRequest.setValue('expiryDate', values.expiryDate);
    paymentRequest.setPaymentProduct(activePaymentProduct as any); // eslint-disable-line
    // indicate whether the app should store the customer's credentials for recurring payments
    paymentRequest.setTokenize(false);

    if (paymentRequest.isValid()) {
      const encryptor = session.getEncryptor();
      encryptor.encrypt(paymentRequest).then((payload) => {
        handlePayoneRequestPayment(payload);
      });
    } else {
      const errors = paymentRequest.getErrorMessageIds();
      if (errors.includes('luhn')) {
        setErrors({ cardNumber: 'Kartennummer is not a valid card number' });
      }
      if (errors.includes('expirationDate')) {
        setErrors({ expiryDate: 'Ablaufdatum is not a valid Expiried Date' });
      }
    }
  };

  const ContinuePayment = () => {
    if (!payoneData.redirectUrl) {
      return;
    }

    return (
      <div>
        <h2>{strings.Checkout_Payment_Continue_Description}</h2>
        <Button
          onClick={() => {
            window.location.href = payoneData.redirectUrl;
          }}
          color="primary"
          variant="contained"
        >
          {strings.Checkout_Payment_Continue_Button}
        </Button>
      </div>
    );
  };

  const RenderContentProducts = () => {
    if (!activePaymentProduct) return;
    const getFields = activePaymentProduct.json?.fields ?? [];
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={false}
      >
        <Form className="paymentForm">
          {getFields.map((fieldItem) => {
            return (
              <div
                className={`paymentFormField paymentFormField__${fieldItem.id}`}
                key={fieldItem.displayHints?.displayOrder}
              >
                <label htmlFor={fieldItem.id}>
                  {fieldItem.displayHints?.label}*
                </label>
                <Field name={fieldItem.id}>
                  {({
                    field,
                  }: {
                    field: {
                      name: string;
                      value: string;
                      displayHints: { mask: string };
                    };
                  }) => (
                    <InputMask
                      {...field}
                      className="paymentField"
                      id={fieldItem.id}
                      type={
                        fieldItem.id === 'cvv' ? 'password' : fieldItem.type
                      }
                      placeholder={
                        fieldItem.id === 'expiryDate'
                          ? 'MM/YY'
                          : fieldItem.id === 'cvv'
                          ? '123'
                          : ''
                      }
                      mask={
                        fieldItem.displayHints?.mask
                          ? fieldItem.displayHints?.mask.replace(/[{}]/g, '')
                          : ''
                      }
                      maskChar=""
                    />
                  )}
                </Field>
                <ErrorMessage
                  className="errorMessage"
                  name={fieldItem.id}
                  component="div"
                />
              </div>
            );
          })}
          <Button className="paymentFormSubmit" type="submit" ref={submitButton}></Button>
        </Form>
      </Formik>
    );
  };

  if (!paymentProduct) {
    return <>{strings.Checkout_Payment_NoPaymentProduct}</>;
  }

  return (
    <>
      <ContinuePayment />
      <RenderContentProducts />
    </>
  );
};

export default CheckoutPaymentComponent;
