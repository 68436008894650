import { apiGETRequest, generateURL } from '../../util/apiRequest';
import config from '../../config.json';
import { ActionType, Block } from './index';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { handleError } from '../../util/handleError';
import { Dispatch } from 'redux';

// get blocks by venuePlanId
export const getBlocks = (
  venueEventId: string,
  salesChannelKey: string
): AsyncReduxThunkAction => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.GET_BLOCKS, {
      params: { venueEventId, salesChannelKey },
    });

    const blocks: Block[] = await apiGETRequest(requestURL) as Block[];

    dispatch({ payload: { blocks }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};

// get blocks by blockGroupId
export const getBlocksByBlockGroup = (
  blockGroupId: string,
  salesChannelKey: string,
): AsyncReduxThunkAction => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.GET_BLOCKS_BY_BLOCKGROUP, {
      params: { blockGroupId, salesChannelKey },
    });

    const blocks: Block[] = await apiGETRequest(requestURL) as Block[];

    dispatch({ payload: { blocks }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};
