import React from 'react';
import { useLocale } from '../../state/Localization';
import Layout from '../../common/Layout';

const NotFoundPage: React.FC = () => {
  const { strings } = useLocale();
    return (
        <Layout title={strings.NotFoundSlugTitle} />
    );
};

export default NotFoundPage;