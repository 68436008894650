export enum ActionType {
  ERROR = 'PAYMENT_METHOD::ERROR',
  FETCH = 'PAYMENT_METHOD::FETCH',
  SUCCESS = 'PAYMENT_METHOD::SUCCESS',
}

export interface IPaymentMethod {
  id: string;
  label: string;
  logo: string;
  provider: 'mollie' | 'payone' | 'fake';
  resource: 'payments' | 'orders';
}

export interface FetchResponse {
  data: IPaymentMethod[];
}

// Response state
export interface PaymentMethodState extends FetchResponse {
  error?: Error;
  fetching: boolean;
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface SuccessAction {
  payload: { response: FetchResponse };
  type: typeof ActionType.SUCCESS;
}

export type Action = ErrorAction | FetchAction | SuccessAction;
