export enum ActionType {
  GET_BUILD_INFO = 'BUILD_INFO::GET_BUILD_INFO',
  ERROR = 'BUILD_INFO::ERROR',
  FETCH = 'BUILD_INFO::FETCH',
  SUCCESS = 'BUILD_INFO::SUCCESS',
}

export interface BuildInfo {
  environment?: string;
  gitCommit?: string;
  deploymentTime?: string;
}

export interface BuildInfoState {
  error?: Error;
  fetching: boolean;
  buildInfo?: BuildInfo;
}

export interface SetBuildInfoAction {
  type: typeof ActionType.GET_BUILD_INFO;
  payload: { buildInfo: object };
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface SuccessAction {
  payload: { buildInfo: BuildInfo };
  type: typeof ActionType.SUCCESS;
}

export type Action =
  | SetBuildInfoAction
  | ErrorAction
  | FetchAction
  | SuccessAction;
