import {ActionType, FetchResponse, IStartPaymentRequest} from './index';
import config from '../../config.json';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import {apiPOSTRequest, generateURL} from '../../util/apiRequest';
import { handleError } from '../../util/handleError';
import { Dispatch } from 'redux';

export const postPaymentFakeCharge = (
  paymentId: string,
): AsyncReduxThunkAction => async (
  dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.POST_PAYMENT_FAKE_CHARGE, {
      params: { paymentId },
    });

    const response = (await apiPOSTRequest(requestURL, {})) as FetchResponse;
    dispatch({ payload: { response: { data: response } }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};

export const postCheckoutStartPayment = (
  id: string,
  uid: string,
  salesChannel: string,
  payload: IStartPaymentRequest,
): AsyncReduxThunkAction => async (
  dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.POST_CHECKOUT_PAYMENT, {
      params: { id, uid, salesChannelKey: salesChannel },
    });

    const response = (await apiPOSTRequest(requestURL, payload)) as FetchResponse;
    dispatch({ payload: { response: { data: response } }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};


export const chargePayonePayment = (
  paymentId: string,
  encryptedCustomerInput: string | null,
  paymentMethodId: string
): AsyncReduxThunkAction => async (
  dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.POST_CHECKOUT_PAYONE_CHARGE, {
      params: { paymentId },
    });

    const body = {
      paymentMethodId,
      encryptedCustomerInput
    };

    const response = (await apiPOSTRequest(requestURL, body)) as FetchResponse;
    dispatch({ payload: { response }, type: ActionType.SUCCESS_PAYONE });

    if (response && 'redirectUrl' in response) {
      window.location.href = response.redirectUrl as string;
    }
  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};

export const chargeMolliePayment = (
    paymentId: string,
    paymentMethodId: string
): AsyncReduxThunkAction => async (
    dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.POST_CHECKOUT_MOLLIE_PAYMENT_CHARGE, {
      params: { paymentId },
    });

    const body = {
      paymentMethodId,
    };

    const response = (await apiPOSTRequest(requestURL, body)) as FetchResponse;
    dispatch({ payload: { response }, type: ActionType.SUCCESS_MOLLIE });

    if (response && 'redirectUrl' in response) {
      window.location.href = response.redirectUrl as string;
    }

  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};

export const chargeMollieOrder = (
    orderId: string,
    paymentMethodId: string
): AsyncReduxThunkAction => async (
    dispatch: Dispatch,
): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.POST_CHECKOUT_MOLLIE_ORDER_CHARGE, {
      params: { orderId },
    });

    const body = {
      paymentMethodId,
    };

    const response = (await apiPOSTRequest(requestURL, body)) as FetchResponse;
    dispatch({ payload: { response }, type: ActionType.SUCCESS_MOLLIE });

    if (response && 'redirectUrl' in response) {
      window.location.href = response.redirectUrl as string;
    }

  } catch (error) {
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};
