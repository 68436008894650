import { createFetchActions, FetchComponent } from '../../Fetch';
import { apiPOSTRequest, generateURL } from '../../../util/apiRequest';
import config from '../../../config.json';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import { ActionType as TicketSelectionActionType } from '../types';
import { TicketSelectionStateAPIResponse } from '../types/TicketSelectionStateAPIResponse';
import { ensureBasicState } from '../utils/ensureBasicState';
import { handleError } from '../../../util/handleError';
import { mapTicketSelectionStateAPIResponse } from '../types/mapTicketSelectionStateAPIResponse';
import { Dispatch } from 'redux';
import { AppState } from '../../../state';

export const setPurchasableItem = (
  placeId: string,
  purchasableItemId: string,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      // userId,
      venueEventId,
    } = ensureBasicState(appState);
    const requestURL = generateURL(config.API_ENDPOINTS.SET_PURCHASABLE_ITEM, {
      params: { placeId, venueEventId, salesChannelKey: salesChannel },
      query: {
        purchaseForTicketHolderId,
        salesRuleId,
        subscriptionId,
      },
    });
    const body = {
      purchasableItemId,
    };
    const stateResponse = (await apiPOSTRequest(
      requestURL,
      body,
    )) as TicketSelectionStateAPIResponse;
    const state = mapTicketSelectionStateAPIResponse(stateResponse);
    dispatch({ payload: { state }, type: TicketSelectionActionType.SET_STATE });
  } catch (error) {
    if (error instanceof Error) {
      handleError(dispatch, error);
    }
  } finally {
    dispatch(fetchStop);
  }
};
