import { Point } from 'pixi.js';

/** relative to size */
export const SEAT_TEXTURE_HALF_SIZE = 32;
export const SEAT_TEXTURE_SIZE = SEAT_TEXTURE_HALF_SIZE * 2;
export const SEAT_SIZE = 1;
export const DEFAULT_SEAT_SCALE = SEAT_SIZE / SEAT_TEXTURE_SIZE;
export const AREA_LOD_BIG_BLOCKS_LABELS = 200 * 200;
export const AREA_LOD_SMALL_BLOCKS_LABELS = 100 * 100;
export const AREA_LOD_SEATS = 160 * 160;
export const AREA_LOD_ROW_LABELS = 40 * 40;
export const AREA_LOD_SEAT_LABELS = 25 * 25;
export const AREA_MAX_ZOOM = 4 * 4;
export const LOD_INIT = -1;
export const LOD_BLOCKS = 0;
export const LOD_BIG_BLOCKS_LABELS = 1;
export const LOD_SMALL_BLOCKS_LABELS = 2;
export const LOD_SEATS = 3;
export const LOD_ROW_LABELS = 4;
export const LOD_SEATS_LABELS = 5;
export const MIN_LONG_PRESS_TIME_FOR_OVERLAY_MS = 450;
export const MIN_PANNING_DISTANCE_FOR_OVERLAY_PX = 25;
export const MAX_DOUBLE_TAP_TIME_MS = 250;
export const SINGLE_TAP_THROTTLE_MS = 500;
export const ANIMATION_DURATION_TAP_ZOOM_MS = 350;
export const ANIMATION_DURATION_ENTER_MS = 1000;
/** Base duration, actual value depends on distance */
export const ANIMATION_DURATION_FLIGHT_TO_PLACE_MS = 2000;
/** Part from the lowest of the screen dimensions */
export const LABEL_DEFAULT_COLOR = 0x4D4D4D;
export const LABEL_DEFAULT_LIGHT_COLOR = 0xFFFFFF;
export const ACTIVE_BLOCKS_CIRCLE_SIZE = 0.8;
export const STAND_BLOCK_LABEL_SIZE = 4;
/** Set to true to draw the circle that determines active blocks */
export const DEBUG_ACTIVE_BLOCKS_CIRCLE = false;
export const STAND_BLOCK_TEXT_STYLE = {
  fontName: 'font',
  fontSize: 0.8,
  tint: 0x606060,
};
export const TEXT_ANCHOR = new Point(0.5, 0.5);

export const VIEWPORT_WORLD_PADDING = 10;
// constants for BitmapFont
// for seats
export const BF_SEAT_LABEL_SIZE = 0.0087;
