export enum ActionType {
  GET_TENANT_CONFIG = 'TENANT_CONFIG::GET_TENANT_CONFIG',
  ERROR = 'TENANT_CONFIG::ERROR',
  FETCH = 'TENANT_CONFIG::FETCH',
  SUCCESS = 'TENANT_CONFIG::SUCCESS',
}

export interface TenantConfig {
  gtcUrl: string;
  imprintUrl: string;
  privacyPolicyUrl: string;
  websiteUrl: string;
  primaryColor: string;
  secondaryColor: string;
  logoUrl: string;
  faviconUrl: string;
  hotlineNumber: string;
  hotlineReachability: string;
  tenantName: string;
}

export interface TenantConfigState {
  error?: Error;
  fetching: boolean;
  tenantConfig: TenantConfig;
}

export interface SetTenantConfigAction {
  type: typeof ActionType.GET_TENANT_CONFIG;
  payload: { tenantConfig: TenantConfig };
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface SuccessAction {
  payload: { tenantConfig: TenantConfig };
  type: typeof ActionType.SUCCESS;
}

export type IQueryParams = Partial<TenantConfig>;

export type Action =
  | SetTenantConfigAction
  | ErrorAction
  | FetchAction
  | SuccessAction;
