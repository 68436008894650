import { ActionType as PersonalizationActionType } from './types';
import { SyncThunkAction } from '../thunkAction';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { selectPersonalizationFullName } from './selectors';

// set personalization values in state for all tickets in basket
export const setPlacesPersonalization = (): AsyncReduxThunkAction => async (
  dispatch,
  getState,
): Promise<void> => {
  const appState = getState();

  // set default value for first ticket as name of user or purchaser
  let fullName = '';

  if (
    appState.ticketSelection &&
    appState.ticketSelection.places.length >= 0 &&
    appState.venueEvent.venueEvent?.personalDataOnTicketShouldBeCollected
  ) {
    fullName = selectPersonalizationFullName(appState) ?? '';
  }

  const placesPersonalization = appState.ticketSelection
    ? appState.ticketSelection.places.map((place) => {
        return {
          fullName: '',
          phoneNumber: '',
          placeId: place.id,
          seasonTicketLegalRecipientId: '',
        };
      })
    : [];
  // set default name to first ticket
  if (appState.ticketSelection && placesPersonalization.length > 0) {
    placesPersonalization.splice(0, 1, {
      fullName: fullName,
      phoneNumber: '',
      placeId: appState.ticketSelection?.places[0].id,
      seasonTicketLegalRecipientId: '',
    });
  }

  dispatch({
    payload: { placesPersonalization },
    type: PersonalizationActionType.SET_PERSONALIZATION,
  });
};

export const addPlacesPersonalization = (
  placeId: string,
  fullName: string | null,
  phoneNumber: string | null,
  seasonTicketLegalRecipientId: string | null,
): SyncThunkAction => (dispatch, getState): void => {
  const appState = getState();

  const placesPersonalization =
    appState.placesPersonalization.placesPersonalization;
  const index = placesPersonalization.findIndex((pp) => pp.placeId === placeId);

  let currentItem = placesPersonalization[index];

  if (typeof currentItem === 'undefined') {
    currentItem = {
      placeId: placeId,
      fullName: '',
      phoneNumber: '',
      seasonTicketLegalRecipientId: '',
    };
  }

  if (fullName !== null) {
    currentItem.fullName = fullName;
  }

  if (phoneNumber !== null) {
    currentItem.phoneNumber = phoneNumber;
  }

  if (seasonTicketLegalRecipientId !== null) {
    currentItem.seasonTicketLegalRecipientId = seasonTicketLegalRecipientId;
  }

  if (index > -1) {
    placesPersonalization.splice(index, 1, currentItem);
  } else {
    placesPersonalization.push(currentItem);
  }

  const newPlacesPersonalization = [];
  const places = appState.ticketSelection?.places;
  if (places) {
    for (const place of places) {
      let item = placesPersonalization.find((pp) => pp.placeId === place.id);

      if (typeof item === 'undefined') {
        item = {
          placeId: placeId,
          fullName: '',
          phoneNumber: '',
          seasonTicketLegalRecipientId: '',
        };
      }

      newPlacesPersonalization.push(item);
    }
  }

  const firstItem = placesPersonalization[0];
  const newFirstItem = newPlacesPersonalization[0];
  if (
    typeof firstItem !== 'undefined' &&
    firstItem.phoneNumber &&
    typeof newFirstItem !== 'undefined' &&
    !newFirstItem.phoneNumber
  ) {
    newFirstItem.phoneNumber = firstItem.phoneNumber;
  }

  dispatch({
    payload: { placesPersonalization: newPlacesPersonalization },
    type: PersonalizationActionType.SET_PERSONALIZATION,
  });
};

export const removePlacesPersonalization = (
  placeId: string,
): SyncThunkAction => (dispatch, getState): void => {
  const appState = getState();
  const placesPersonalization =
    appState.placesPersonalization.placesPersonalization;
  const index = placesPersonalization.findIndex((pp) => pp.placeId === placeId);
  placesPersonalization.splice(index, 1);

  dispatch({
    payload: { placesPersonalization },
    type: PersonalizationActionType.SET_PERSONALIZATION,
  });
};

export const removeAllPlacesPersonalization = (): SyncThunkAction => (
  dispatch,
): void => {
  dispatch({
    payload: { placesPersonalization: [] },
    type: PersonalizationActionType.SET_PERSONALIZATION,
  });
};
