import {
  ActionType,
  SetArePlacesSubmitted,
  SetJWTAction,
  SetPageTitle,
  SetPurchaseForTicketHolderIdAction,
  SetQueueittokenAction,
  SetSalesChannelAction,
  SetSalesRuleIdAction,
  SetSubscriptionIdAction
} from './index';

export const setJWT = (jwt: string): SetJWTAction => ({
  payload: { jwt },
  type: ActionType.SET_JWT,
});

export const setSalesChannel = (
  salesChannel: string,
): SetSalesChannelAction => ({
  payload: { salesChannel },
  type: ActionType.SET_SALES_CHANNEL,
});

export const setSalesRuleId = (salesRuleId: string): SetSalesRuleIdAction => ({
  payload: { salesRuleId },
  type: ActionType.SET_SALES_RULE_ID,
});

export const setPurchaseForTicketHolderId = (
  purchaseForTicketHolderId: string,
): SetPurchaseForTicketHolderIdAction => ({
  payload: { purchaseForTicketHolderId },
  type: ActionType.SET_PURCHASE_FOR_TICKET_HOLDER_ID,
});

export const setSubscriptionId = (
  subscriptionId: string,
): SetSubscriptionIdAction => ({
  payload: { subscriptionId },
  type: ActionType.SET_SUBSCRIPTION_ID,
});

export const setQueueittoken = (
  queueittoken: string,
): SetQueueittokenAction => ({
  payload: { queueittoken },
  type: ActionType.SET_QUEUEITOKEN,
});

export const setArePlacesSubmitted = (
  arePlacesSubmitted: boolean,
): SetArePlacesSubmitted => ({
  payload: { arePlacesSubmitted },
  type: ActionType.SET_ARE_PLACES_SUBMITTED,
});

export const setPageTitle = (
    pageTitle: string,
): SetPageTitle => ({
  payload: { pageTitle },
  type: ActionType.SET_PAGE_TITLE,
});