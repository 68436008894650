import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useLocale } from '../../state/Localization';
import { getVenueEvent, useVenueEvent } from '../../state/VenueEvent';
import {
  setPageTitle,
  setPurchaseForTicketHolderId,
  setQueueittoken,
  setSalesChannel,
  setSubscriptionId,
  useSession,
} from '../../state/Session';
import PlaceSelection from '../../components/PlaceSelection';
import SalesRuleTableComponent from '../../components/SalesRuleTable';
import SeatSelection from '../../components/SeatSelection';
import SubmitBasketComponent from '../../components/SubmitBasket';
import TicketBasket from '../../components/TicketBasket';
import TicketCounter from '../../components/TicketCounter';
import VenueEventSummaryComponent from '../../components/VenueEventSummary';
import Header from '../../components/Header/Header';
import { getState as getTicketSelectionState, useTicketSelection } from '../../state/TicketSelection';
import useInitParams from '../../util/useInitParams';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { VenueAvailabilityData } from '../../components/VenuePlan';
import PlaceSelectionWithoutGraphicalVenuePlan from '../../components/PlaceSelectionWithoutGraphicalVenuePlan';
import Drawer from '../../components/Drawer/Drawer';
import Layout from '../../common/Layout';
import style from './style.module.css';
import {hydrateString} from '../../util/localization';

const SelectTicketScreen: React.FC = () => {
  const location = useLocation();
  const { strings, titles } = useLocale();
  const places = useTicketSelection()?.places ?? [];

  const { salesChannel, venueEventId, tenantSlug } = useRouteMatch<{
    salesChannel: string;
    venueEventId: string;
    tenantSlug: string;
  }>().params;

  const dispatch = useDispatch();
  const searchParams = queryString.parse(location.search);

  // platform sends this param as it is named differently on their side
  const purchaseForTicketHolderId =
    typeof searchParams.SubjectId === 'string' ? searchParams.SubjectId : '';

  const subscriptionId = searchParams.SubscriptionId;
  const queueittoken =
    typeof searchParams.queueittoken === 'string'
      ? searchParams.queueittoken
      : '';
  const { arePlacesSubmitted } = useSession();
  const { venueEvent } = useVenueEvent();
  const appStateVenueEventId = venueEvent?.id;
  const { salesRuleId } = useSession();
  const history = useHistory();

  const [availability, setAvailability] = useState<VenueAvailabilityData>({
    getRemainingBlockCapacity: () => 0,
    isBlockAvailable: () => false,
    isSeatAvailable: () => false,
    version: 0,
    availableSeatsCount: 0,
  });

  const expires = useMemo(() => {
    if (!places) return undefined;
    const expiryDates = places
      .filter((place) => !!place.expiresAt)
      .map((place) => new Date(place.expiresAt).getTime());

    if (!expiryDates.length) return undefined;
    return new Date(
      expiryDates.reduce((accumulator, slip) => Math.min(accumulator, slip))
    );
  }, [places]);

  const { setInitParams } = useInitParams();

  useEffect(() => {
    setInitParams({
      salesChannel,
      venueEventId,
      tenantSlug
    });
  }, []);

  useEffect(() => {
    if (purchaseForTicketHolderId) {
      dispatch(setPurchaseForTicketHolderId(purchaseForTicketHolderId));
    }
  }, [dispatch, purchaseForTicketHolderId]);

  useEffect(() => {
    if (typeof subscriptionId === 'string') {
      dispatch(setSubscriptionId(subscriptionId));
    }
  }, [dispatch, subscriptionId]);

  useEffect(() => {
    if (typeof queueittoken === 'string') {
      dispatch(setQueueittoken(queueittoken));
    }
  }, [dispatch, queueittoken]);

  useEffect(() => {
    if (typeof salesChannel === 'string') {
      dispatch(setSalesChannel(salesChannel));
      dispatch(getVenueEvent(venueEventId, salesChannel, queueittoken));
    }
  }, [dispatch, venueEventId, salesChannel, queueittoken]);

  useEffect(() => {
    if (typeof salesChannel === 'string' && appStateVenueEventId) {
      dispatch(getTicketSelectionState());
    }
  }, [dispatch, venueEvent, salesChannel, salesRuleId, appStateVenueEventId]);

  useEffect(() => {
    if (!venueEvent?.title) {
      return;
    }

      dispatch(setPageTitle(hydrateString(titles.Tickets, {'eventName': venueEvent.title})));
  }, [venueEvent?.title]);

  if (arePlacesSubmitted) {
    if (purchaseForTicketHolderId) {
      history.push(`/backend/users/${purchaseForTicketHolderId}/cart`);
    }
  }

  const showSelectionFallback = location.search.includes('fallback');

  return (
    <Layout
      title={strings.PlaceSelection_Heading}
      dark={true}
      header={<Header expires={expires} />}
      main={
        <>
          <VenueEventSummaryComponent />
          <SalesRuleTableComponent />
          {showSelectionFallback && <SeatSelection salesChannelKey={salesChannel} />}
          {venueEvent && !venueEvent.disableVisualSelection && salesChannel && (
            <PlaceSelection
              salesChannel={salesChannel}
              purchaseForTicketHolderId={purchaseForTicketHolderId}
              subscriptionId={
                typeof subscriptionId === 'string' ? subscriptionId : ''
              }
              salesRuleId={typeof salesRuleId === 'string' ? salesRuleId : ''}
              eventId={venueEvent.id}
              queueItToken={queueittoken}
              venuePlanVersionId={venueEvent.venuePlanVersionId}
              availability={availability}
              setAvailability={setAvailability}
            />
          )}
          {venueEvent && venueEvent.disableVisualSelection && (
            <PlaceSelectionWithoutGraphicalVenuePlan
              salesChannel={salesChannel}
              purchaseForTicketHolderId={purchaseForTicketHolderId}
              subscriptionId={
                typeof subscriptionId === 'string' ? subscriptionId : ''
              }
              salesRuleId={typeof salesRuleId === 'string' ? salesRuleId : ''}
              eventId={venueEvent.id}
              queueItToken={queueittoken}
              venuePlanVersionId={venueEvent.venuePlanVersionId}
              availability={availability}
              setAvailability={setAvailability}
            />
          )}
        </>
      }
      aside={
        <div className={style.Basket}>
          <TicketBasket expires={expires} />

          <SubmitBasketComponent
            tenantSlug={tenantSlug}
            availability={availability}
            salesChannel={salesChannel}
          />
        </div>
      }
      drawer={
        <Drawer
          hideActions={false}
          actions={
            <SubmitBasketComponent
              tenantSlug={tenantSlug}
              availability={availability}
              salesChannel={salesChannel}
            />
          }
          ticketCounter={
            <TicketCounter places={places} />
          }
        >
          <TicketBasket />
        </Drawer>
      }
    />
  );
};

export default SelectTicketScreen;
