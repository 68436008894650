import React from 'react';
import { ExpandLess } from '@mui/icons-material';
import { useLocale } from '../../state/Localization';
import { useCheckoutState } from '../../state/Checkout';
import { sumCartItems } from '../../util/sumCartItems';

import style from './style.module.css';

const DrawerHandleSumComponent: React.FC = () => {
  const { language, strings } = useLocale();
  const { events } = useCheckoutState();

  return (
    <div className={style.drawerHandleSum}>
      <div className={style.drawerHandleSumLabel}>
        <ExpandLess fontSize="large" />
        <div>{strings.Checkout_Summary_Total}</div>
      </div>
      <div>
        {sumCartItems(events).toLocaleString(language, {
          currency: 'EUR',
          style: 'currency',
        })}
      </div>
    </div>
  );
};

export default DrawerHandleSumComponent;
