import React, { useEffect, useState } from 'react';
import useInitParams from '../../util/useInitParams';
import { 
  Button,
  Dialog, 
  DialogActions,
  DialogContent, 
  DialogTitle
} from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

const getNow = (): number => {
  return getSeconds(new Date());
};
const getSeconds = (date: Date): number => {
  return Math.round(new Date(date).getTime() / 1000);
};

interface ICountdownDialogProps {
  to?: Date;
  title: string;
  content: string;
  btnText: string;
  useTimer?: boolean;
  openDialog?: boolean;
  onHandleClick?: () => void;
}

const CountdownDialogComponent: React.FC<ICountdownDialogProps> = ({
  title,
  content,
  btnText,
  onHandleClick,
  openDialog = false,
  useTimer = true,
  to
}) => {
  const { initParams } = useInitParams();
  
  const [showDialog, setShowDialog] = useState(openDialog || false);
  const [showShouldDialog, setShouldShowDialog] = useState(false);
  const [now, setNow] = useState(getNow());

  const seconds = to ? Math.max(getSeconds(to) - now, 0) : undefined;

  const handleClick = () => {
    setShowDialog(false);
    if (!useTimer) {
      onHandleClick?.();
    } else {
      if (initParams) {
        // history push not working in the same page
        window.location.href = `/${initParams.tenantSlug}/placeSelection/` +
        `${initParams.salesChannel}/${initParams.venueEventId}`;
      }
    }
  };

  useEffect(() => {
    if (useTimer) {
        const timeout = setInterval(() => setNow(getNow()), 1000);
        return (): void => clearInterval(timeout);
    }
  }, []);

  useEffect(() => {
    // In some cases on initialization the timer is 0 for
    // a second. To prevent showing the dialog then, we
    // allow showing only if timer was > 0 before.
    if (seconds && seconds > 0) {
      setShouldShowDialog(true);
    }
    if (showShouldDialog && seconds === 0) {
      setShowDialog(true);
      setShouldShowDialog(false);
    }
  }, [seconds]);
  return (
    <Dialog open={showDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<ChevronLeft />}
          onClick={handleClick}
        >
          {btnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CountdownDialogComponent;
