import React from 'react';
import { useLocale } from '../../state/Localization';
import { useTenantConfig } from '../../state/TenantConfig';
import { LocalizedString } from '../../state/VenueEvent/types/VenueEvent';
import { mapLocalizedString } from '../../util/localization';
import './Footer.scss';

export interface IFooterProps {
  infoText?: LocalizedString;
}

const Footer: React.FC<IFooterProps> = ({ infoText }) => {
  const { strings } = useLocale();
  const { tenantConfig: {
    gtcUrl,
    privacyPolicyUrl,
    imprintUrl,
    hotlineNumber,
    hotlineReachability
  }} = useTenantConfig();

  return (
    <footer className='footer'>
      <div className="footer__wrapper">
        <div className="footer__left">
          {mapLocalizedString(infoText) &&
            <div className="footer__info">{mapLocalizedString(infoText)}</div>
          }
          <div className="footer__hotline">{hotlineNumber}</div>
          <div className="footer__availability">{hotlineReachability}</div>
        </div>
        <div className="footer__right">
          <a href={gtcUrl} target="_blank" rel="noreferrer">
            {strings.Meta_Terms}
          </a>
          <a href={privacyPolicyUrl} target="_blank" rel="noreferrer">
            {strings.Meta_Privacy}
          </a>
          <a href={imprintUrl} target="_blank" rel="noreferrer">
            {strings.Meta_Imprint}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
