import {
    Action,
    ActionType,
    CartItem,
    CheckoutState,
    Event
} from './types';

const initialState: CheckoutState = {
    fetching: false,
    events: [],
    invoices: [],
    legalContact: null,
    invoiceContact: null,
    id: null,
    orderId: null,
    status: 'new'
};

export const reducer = (
    state = initialState,
    action: Action,
): CheckoutState => {
    switch (action.type) {
        case ActionType.ERROR: {
            const {error} = action.payload;
            return {
                ...state,
                error,
                fetching: false,
            };
        }
        case ActionType.FETCH: {
            return {
                ...state,
                fetching: true,
            };
        }
        case ActionType.SUCCESS: {
            const { response } = action.payload;
            const newEvents: Event[] = [];
            for (const event of response.events) {
                const newItems: CartItem[] = [];
                for (const item of event.items) {
                    newItems.push({
                        canDelete: item.canDelete,
                        personalizationFullName: item.personalizationFullName ?? undefined,
                        expiresAt: item.expiresAt ? new Date(item.expiresAt) : undefined,
                        id: item.id,
                        uid: item.uid,
                        salesChannel: item.salesChannel,
                        place: item.place,
                        grossAmount: item.grossAmount,
                        pricingCategory: item.pricingCategory,
                        pricingClass: item.pricingClass,
                        publicName: item.publicName,
                        status: item.status,
                    });
                }

                newEvents.push({
                    ...event,
                    endDate: event.endDate
                        ? new Date(event.endDate)
                        : undefined,
                    startDate: event.startDate
                        ? new Date(event.startDate)
                        : undefined,
                    items: newItems
                });
            }
            return {
                ...state,
                ...response,
                fetching: false,
                events: newEvents,
            };
        }
        case ActionType.POST_SUCCESS: {
            const { response } = action.payload;
            return {
                ...state,
                ...response,
                fetching: false
            };
        }
        default: {
            return state;
        }
    }
};
