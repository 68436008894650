import {
  ActionType,
  Message,
  MessageWithId,
  RemoveMessageAction,
} from './index';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import asyncTimeout from '../../util/asyncTimeout';
import { v4 as uuidv4 } from 'uuid';
import { Dispatch } from 'redux';

export const addMessage = (
  messageWithoutId: Message,
): AsyncReduxThunkAction => async (dispatch: Dispatch): Promise<void> => {
  const messageId = uuidv4();
  const message: MessageWithId = { ...messageWithoutId, id: messageId };
  dispatch({
    payload: { message },
    type: ActionType.ADD,
  });

  if (message.timeout && message.timeout > 0) {
    await asyncTimeout(message.timeout);
    dispatch({ payload: { messageId }, type: ActionType.REMOVE });
  }
};

export const removeMessage = (messageId: string): RemoveMessageAction => ({
  payload: { messageId },
  type: ActionType.REMOVE,
});
