import {
  getAuthenticationState,
  signInSilent,
  isTokenExpired,
  IJwtPayload,
  refreshAnonToken
} from '../auth';
import jwtDecode from 'jwt-decode';
import { redirectToLogin } from '../auth';


export function createAuthenticationHeaders() {
  const authenticationState = getAuthenticationState();
  if (authenticationState.type === 'AUTHENTICATED') {
    return { Authorization: `Bearer ${authenticationState.accessToken}` };
  }

  if (authenticationState.type === 'ANONYMOUS') {
    const tenantSlug = getTenantSlugFromURL();
    const anonToken = localStorage.getItem(`anonToken${tenantSlug}`);
    if (anonToken) {
      return { Authorization: `Bearer ${anonToken}` };
    }
  }
  return undefined;
}

export class HTTPError extends Error {
  public status: number;

  constructor(status: number, message?: string) {
    super(message);
    this.status = status;
  }
}

export class HTTPKnownError extends HTTPError {
  public errorType: string;

  constructor(status: number, errorType: string) {
    super(status, errorType);
    this.errorType = errorType;
  }
}

export class QueueItRedirectError extends Error {
  public url: string;

  constructor(url: string, errorType: string) {
    super(errorType);
    this.url = url;
  }
}

export class SeasonTicketLegalRecipientIdNotFoundError extends Error {
  public id: string;

  constructor(id: string, errorType: string) {
    super(errorType);
    this.id = id;
  }
}

export class SelectionNotFoundError extends Error {}

export enum KnowErrors {
  InvalidPlace = 'place.not_valid',
  InvalidPurchasableItem = 'purchasableItem.not_valid',
  InvalidRightsProvider = 'rightsProvider.not_valid',
  SubscriptionIdNotFound = 'subscriptionId.notFound',
  PersonalizationNotAllPlacesProvided = 'selection.personalization.notAllPlacesProvided',
  PersonalizationRequired = 'selection.personalization.required',
  SalesChannelNotFound = 'salesChannel.notFound',
  UserIsNotInTargetGroup = 'user.is.not.in.targetGroup',
  UserHasMaxTickets = 'user.has.max.tickets',
  PlaceIsBocked = 'selection.place.booked',
  FreePlacesAreMissing = 'free.places.are.missing',
}

let isAlreadyFetchingAccessToken: boolean = false;

interface IResponseBody {
  errorType?: string;
  url?: string;
  message?: string;
  code?: number;
}

interface GenerateURLArgs {
  params?: { [key: string]: string };
  query?: { [key: string]: string };
}

const handleErrorResponse = async (response: Response): Promise<void> => {
  if (response.status >= 400) {
    const responseBody: IResponseBody = await response.json() as IResponseBody;
    if (Object.values(KnowErrors).includes(responseBody?.errorType as KnowErrors)) {
      throw new HTTPKnownError(response.status, responseBody?.errorType as KnowErrors);
    } else if (responseBody?.errorType === 'queue.redirect') {
      throw new QueueItRedirectError(responseBody?.url as string, responseBody.errorType);
    } else if (responseBody?.errorType === 'selection.notFound') {
      throw new SelectionNotFoundError(responseBody.errorType);
    } else if (
      responseBody?.errorType ===
      'selection.seasonTicketLegalRecipientId.notFound'
    ) {
      throw new SeasonTicketLegalRecipientIdNotFoundError(
        responseBody.message as string,
        responseBody.errorType,
      );
    } else {
      if (
        responseBody.code === 401 &&
        responseBody.message === 'Invalid JWT Token'
      ) {
        if (!isAlreadyFetchingAccessToken) {
          await signInSilent().then(
            () => (isAlreadyFetchingAccessToken = true),
          );

          // Reset isAlreadyFetchingAccessToken after 1m
          setTimeout(() => {
            isAlreadyFetchingAccessToken = false;
          }, 60000);
        }
      } else if (responseBody.code === 404 && response.url.endsWith('/anonymous')) {
        return;
      } else if (responseBody.code === 403 && responseBody.message === 'Access Denied.') {
        redirectToLogin();
      }

      throw new HTTPError(response.status, 'Invalid API request');
    }
  }
};

export const apiGETRequest = async (
  url: string,
  withoutToken?: boolean,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  let headers = !withoutToken ? createAuthenticationHeaders() : {};
  const authenticationState = getAuthenticationState();
  if (authenticationState.type === 'ANONYMOUS') {
    const tenantSlug = getTenantSlugFromURL();
    const anonToken = localStorage.getItem(`anonToken${tenantSlug}`);
    if (anonToken) {
      const jwtDecoded = jwtDecode<IJwtPayload>(anonToken);
      if (isTokenExpired(jwtDecoded.exp)) {
        const access_token = await refreshAnonToken(jwtDecoded.tenant_slug, jwtDecoded.sub);
        headers = { Authorization: `Bearer ${access_token}` };
      }
    }
  }

  const response = await fetch(url, {
    headers: headers,
  });


  await handleErrorResponse(response);
  return response.json();
};

export const apiPOSTRequest = async (
  url: string,
  //eslint-disable-next-line
  body: any,
  withoutToken?: boolean,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  let headers = {
    'Content-Type': 'application/json'
  };

  if (!withoutToken) {
    headers = {
      ...headers,
      ...createAuthenticationHeaders()
    };
  }

  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers: headers,
    method: 'POST',
  });

  await handleErrorResponse(response);

  if (response.status === 404 && response.url.endsWith('/anonymous')) {
    return false;
  }

  if (response.status === 204) {
    // No content to parse, return null or an appropriate value
    return null;
  }
  
  return response.json();
};

export const apiDELETERequest = async (
  url: string,
  //eslint-disable-next-line
  body?: any,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const init = {
    method: 'DELETE',
  } as RequestInit;

  if (typeof body !== 'undefined') {
    init.body = JSON.stringify(body);
    init.headers = {
      'Content-Type': 'application/json',
      ...createAuthenticationHeaders(),
    };
  } else {
    init.headers = createAuthenticationHeaders();
  }

  const response = await fetch(url, init);
  await handleErrorResponse(response);
  return response.json();
};

export const getTenantSlugFromURL = () : string => {
  const urlParts = window.location.pathname.split('/');
  const tenantSlug = urlParts[1];
  return tenantSlug;
};
export const generateURL = (
  template: string,
  args: GenerateURLArgs,
): string => {
  let url = getApiBaseUrl();

  const defaultTenantSlug = getTenantSlugFromURL();
  const params: {
    tenantSlug?: string | null;
    [key: string]: string | null | undefined;
  } = {
      tenantSlug: defaultTenantSlug,
      ...args.params
  };

  const templateComponents = template.split(/{{([^}]+)}}/);
  for (let index = 0; index < templateComponents.length; index++) {
    const component = templateComponents[index];
    if (index % 2 === 0) {
      url += component;
    } else {
      const param = params[component];
      if (param) {
        url += param;
      }
      // else {
      //   throw Error(
      //     `Param "${component}" not found. URL cannot be created. Template: "${template}"`,
      //   );
      // }
    }
  }

  const queryEntities = Object.entries(args.query ?? {});
  if (queryEntities.length > 0) {
    const queryComponents = [];
    for (const [key, value] of queryEntities) {
      if (value) {
        queryComponents.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        );
      }
      // else {
      //   throw Error(
      //     `Query "${key}" not found. URL cannot be created. Template: "${template}"`,
      //   );
      // }
    }
    url += '?' + queryComponents.join('&');
  }

  return url;
};

export const getApiBaseUrl = (): string => {
  if (window.config?.REACT_APP_API_DOMAIN) {
    return window.config?.REACT_APP_API_DOMAIN + window.config.REACT_APP_API_BASE_PATH;
  }

  const url = new URL(window.location.href);

  const urlParts = url.hostname.split('.');
  if (urlParts.length > 2 && urlParts[0] === 'ticketing') {
    url.hostname = urlParts.slice(1).join('.');
  }

  return url.protocol + '//api.' + url.hostname + window.config.REACT_APP_API_BASE_PATH;
};
