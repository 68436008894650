import { useEffect, useState } from 'react';

interface InitParamsProps {
  salesChannel: string;
  venueEventId: string;
  tenantSlug: string;
}

export default function useInitParams() {
  const [initParams, setInitParams] = useState<InitParamsProps>();

  useEffect(() => {
    if (
      !initParams?.salesChannel ||
      !initParams?.venueEventId ||
      !initParams?.tenantSlug
    ) return;
    localStorage.setItem('initParams', JSON.stringify(initParams));
  }, [initParams]);

  useEffect(() => {
    const entry = localStorage.getItem('initParams');
    if (!entry) return;
    const params: InitParamsProps = JSON.parse(entry) as InitParamsProps;
    setInitParams(params);
  }, []);

  return {
    initParams,
    setInitParams
  };
}