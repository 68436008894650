import { FetchComponent, createFetchActions } from '../../state/Fetch';
import {
  createAuthenticationHeaders,
  generateURL,
} from '../../util/apiRequest';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import config from '../../config.json';
import { setResponseRevokeTicket } from '../../state/RevokeTicket';
import { Dispatch } from 'redux';

interface ICustomError {
  status: number;
}

export const sendRevokeTicket = (
  ticketId: string,
  salesChannel: string,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch: Dispatch): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const requestURL = generateURL(
      config.API_ENDPOINTS.POST_REVOKE_TICKET_ON_MARKETPLACE,
      {
        params: { ticketID: ticketId, salesChannelKey: salesChannel }
      },
    );
    const stateResponse = await fetch(requestURL, {
      headers: {
        'Content-Type': 'application/json',
        ...createAuthenticationHeaders(),
      },
      method: 'POST',
    });
    dispatch(setResponseRevokeTicket(true, stateResponse.status));
  } catch (error) {
    dispatch(setResponseRevokeTicket(true, (error as ICustomError).status));
  } finally {
    dispatch(fetchStop);
  }
};
