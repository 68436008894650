import { uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Block,
  getBlocks,
  getBlocksByBlockGroup,
  useBlock,
} from '../../state/Block';
import { getBlockGroups, useBlockGroup } from '../../state/BlockGroup';
import { useFetch } from '../../state/Fetch';
import { useLocale } from '../../state/Localization';
import {
  addSeats,
  addStandingPlace,
  useTicketSelection,
} from '../../state/TicketSelection';
import { useVenueEvent } from '../../state/VenueEvent';
import { getVenuePlan, useVenuePlan } from '../../state/VenuePlan';
import { select } from '../../util/localization';
import Button from '../Button';
import Collapsible from '../Collapsible';
import Section from '../Section';
import Select from '../Select';
import style from './style.module.css';

interface ISeatSelection {
  salesChannelKey: string;
}


/**
 * Select a seat or standing place
 */
const SeatSelection: React.FC<ISeatSelection> = ({
  salesChannelKey
}) => {
  const { fetchComponent, fetchIndicator } = useFetch();
  const [blockGroupId, setBlockGroupId] = useState('');
  const [block, setBlock] = useState({ id: '', type: '' });
  const [row, setRow] = useState('');
  const [standingPlacesNumber, setStandingPlacesNumber] = useState('1');
  const [seatId, setSeatId] = useState('');
  const { venueEvent } = useVenueEvent();
  const ticketSelectionState = useTicketSelection();
  const { blocks } = useBlock();
  const { blockGroups } = useBlockGroup();
  const { venuePlan } = useVenuePlan();
  const { language, strings } = useLocale();
  const dispatch = useDispatch();

  useEffect(() => {
    if (venueEvent) {
      // get block groups of venuePlan
      dispatch(getBlockGroups(venueEvent.id, salesChannelKey));
      if (blockGroupId === '') {
        // get blocks of venuePlan
        dispatch(getBlocks(venueEvent.id, salesChannelKey));
      } else {
        // get blocks of block group if a block group is selected
        dispatch(getBlocksByBlockGroup(blockGroupId, salesChannelKey));
      }
    }
  }, [dispatch, venueEvent, blockGroupId, block]);

  useEffect(() => {
    if (venueEvent) {
      dispatch(getVenuePlan(venueEvent.id, blockGroupId, salesChannelKey));
    }
  }, [dispatch, venueEvent, blockGroupId, ticketSelectionState]);

  // set block group id on block group change for block request
  const onBlockGroupChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setBlockGroupId(event.target.value);
  };

  // set block id and type (type for finding out if a standing or
  // seating block is selected) on block change for seat request
  const onBlockChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const block = blocks?.find((block) => block.id === event.target.value);
    blocks &&
      setBlock({
        id: event.target.value,
        type: block ? block.blockType : '',
      });

    setRow('');
    setSeatId('');
  };

  // set row id on row change for getting seats in that row
  const onRowChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setRow(event.target.value);
    setSeatId('');
  };

  const onStandingPlacesNumberChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setStandingPlacesNumber(event.target.value);
  };

  // set seat id on seat change for adding place on submit
  const onSeatChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSeatId(event.target.value);
  };

  // add seat / standing place to ticket basket
  const addPlaceToCart = (): void => {
    if (venueEvent) {
      if (block.type === 'seating') {
        dispatch(addSeats([seatId], fetchComponent));
        setBlock({ id: '', type: '' });
        setRow('');
        setSeatId('');
      } else {
        dispatch(
          addStandingPlace(block.id, undefined, parseInt(standingPlacesNumber)),
        );
        setBlock({ id: '', type: '' });
        setStandingPlacesNumber('1');
      }
    }
  };

  const availableBlocks: Block[] = blocks
    ? blocks.filter((b) => {
        if (b.blockType === 'seating') {
          return venuePlan && venuePlan.seats
            ? venuePlan.seats.find((s) => s.blockId === b.id)
            : false;
        } else if (b.blockType === 'standing') {
          return venuePlan && venuePlan.standingBlocks
            ? venuePlan.standingBlocks.find((s) => s.id === b.id)
            : false;
        }

        return false;
      })
    : [];

  const isButtonDisabled = !(
    (block.id !== '' && block.type === 'standing') ||
    seatId
  );

  let availableCapacity = 0;
  if (block.id !== '') {
    const currentStandingBlock =
      venuePlan && venuePlan.standingBlocks
        ? venuePlan.standingBlocks.find((s) => s.id === block.id)
        : null;

    if (currentStandingBlock && currentStandingBlock.availableCapacity) {
      availableCapacity = currentStandingBlock.availableCapacity;
    }
  }

  return (
    <>
      <Section border={true}>
        <Collapsible header={strings.SeatSelection_Heading} initial="visible">
          <p className={style.label}>{strings.SeatSelection_BlockGroup}</p>
          <Select
            onChange={onBlockGroupChange}
            value={blockGroupId ?? ''}
            testid="selectBlockGroup"
            fetchIndicator={fetchIndicator}
          >
            <option value="">{strings.SeatSelection_AllBlockGroups}</option>
            {blockGroups &&
              blockGroups.map((blockGroup, key) => (
                <option key={key} value={blockGroup.id}>
                  {select(blockGroup.name, language)}
                </option>
              ))}
          </Select>
          <p className={style.label}>{strings.Shared_Block}</p>
          <Select
            value={block.id}
            onChange={onBlockChange}
            testid="selectBlock"
            fetchIndicator={fetchIndicator}
          >
            <option value="">{strings.SeatSelection_NoChoice}</option>
            {availableBlocks.map((block, key) => (
              <option key={key} value={block.id}>
                {select(block.label, language)}{' '}
                {block.blockType === 'standing' ? ' (Stehplatzblock)' : ''}
              </option>
            ))}
          </Select>
          {block.id !== '' && block.type === 'seating' && (
            <>
              <p className={style.label}>{strings.Shared_Row}</p>
              <Select
                onChange={onRowChange}
                value={row}
                testid="selectRow"
                fetchIndicator={fetchIndicator}
              >
                <option value="">{strings.SeatSelection_NoChoice}</option>
                {venuePlan &&
                  venuePlan.seats &&
                  uniqBy(venuePlan.seats, 'rowLabel').map((seat, key) => (
                    <option key={key} value={seat.rowLabel}>
                      {seat.rowLabel}
                    </option>
                  ))}
              </Select>
              {venuePlan && venuePlan.seats && (
                <>
                  <p className={style.label}>{strings.Shared_Seat}</p>
                  <Select
                    onChange={onSeatChange}
                    value={seatId}
                    testid="selectSeat"
                    fetchIndicator={fetchIndicator}
                  >
                    <option value="">{strings.SeatSelection_NoChoice}</option>
                    {venuePlan.seats
                      .filter((seat) => seat.rowLabel === row)
                      .map((seat, key) => (
                        <option key={key} value={seat.id}>
                          {seat.seatLabel}
                        </option>
                      ))}
                  </Select>
                </>
              )}
            </>
          )}

          {block.id !== '' &&
            block.type === 'standing' &&
            availableCapacity > 0 && (
              <>
                <p className={style.label}>{strings.SeatSelection_NbPlaces}</p>
                <Select
                  onChange={onStandingPlacesNumberChange}
                  value={standingPlacesNumber}
                  testid="selectRow"
                  fetchIndicator={fetchIndicator}
                >
                  {[...Array<number>(availableCapacity)]
                    .map((_, i) => 1 + i)
                    .map((number) => (
                      <option key={number} value={number}>
                        {number}
                      </option>
                    ))}
                </Select>
              </>
            )}

          <Button
            onClick={addPlaceToCart}
            variant="secondary"
            testid="choose"
            fetchIndicator={fetchIndicator}
            disabled={isButtonDisabled}
          >
            {strings.SeatSelection_Button_Choose}
          </Button>
        </Collapsible>
      </Section>
    </>
  );
};

export default SeatSelection;
