import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { VenueAvailabilityData } from '../VenuePlan';
import TicketCounterComponent from '../TicketCounter';
import { useLocale } from '../../state/Localization';
import { usePlacesPersonalization } from '../../state/PlacePersonalization';
import { useVenueEvent } from '../../state/VenueEvent';
import { submitTickets, useTicketSelection } from '../../state/TicketSelection';
import { Strings } from '../../util/localization';
import style from './style.module.css';

type BasketSubmitProps = {
  availability: VenueAvailabilityData;
  salesChannel: string;
  tenantSlug: string;
};

function getLabel(
  isDisabled: undefined | boolean,
  seatsAreAvailableForUser: boolean,
  strings: Strings,
  isLoading: boolean,
  salesChannel: string,
) {
  if (!seatsAreAvailableForUser) {
    return strings.SubmitBasket_Submit_Button_inactive_no_seats_available;
  }

  return strings.SubmitBasket_Submit_Button_active;
}

const BasketSubmitComponent: React.FC<BasketSubmitProps> = (props) => {
  const dispatch = useDispatch();
  const { strings } = useLocale();
  const placesPersonalization = usePlacesPersonalization();
  const venueEvent = useVenueEvent();
  const places = useTicketSelection()?.places ?? [];
  const bundles = useTicketSelection()?.bundles ?? [];
  const { availability, salesChannel } = props;
  const history = useHistory();

  const now = new Date();

  const selectionHasExpiredPlaces = !(
    places.find((place) => place.expiresAt < now) === undefined &&
    places?.length > 0
  );

  const selectionHasNotValidBundles = !!bundles.find((place) => !place.valid);

  const onSubmitBasketClicked = useCallback(() => {
    dispatch(submitTickets(history, props.tenantSlug));
  }, [dispatch]);

  const isDisabled =
    selectionHasExpiredPlaces ||
    selectionHasNotValidBundles ||
    !(
      places.find((place) => place.selectedPurchasableItem === undefined) ===
        undefined && places?.length > 0
    ) ||
    // if personalization is required and nothing is put as fullName, submit button should be disabled
    (venueEvent.venueEvent?.personalDataOnTicketIsRequired &&
      placesPersonalization.placesPersonalization.filter(
        (p) => p.fullName.length === 0,
      ).length > 0);

  const seatsAreAvailableForUser = places?.length > 0 || availability.availableSeatsCount > 0;

  return (
    <div className={style.Basket}>

      <div className={style.TicketCounter}>
        <TicketCounterComponent places={places} />
      </div>

      <Button
        className={style.Submit}
        onClick={onSubmitBasketClicked}
        variant="contained"
        size='large'
        disabled={isDisabled}
        endIcon={<ChevronRight />}
      >
        {getLabel(
          isDisabled,
          seatsAreAvailableForUser,
          strings,
          availability.version < 2,
          salesChannel,
        )}
      </Button>
    </div>
  );
};

export default BasketSubmitComponent;
