import { mapLocalizedString } from '../../../util/localization';

export type LocalizedString =
  | string
  | {
      de?: string;
      en?: string;
    };

export interface FetchResponse {
  events: Event[];
}
export interface VenueEventFlat {
  eventSeries?: string;
  id: string;
  personalDataOnTicketIsRequired: boolean;
  personalDataOnTicketShouldBeCollected: boolean;
  preliminaryDateText?: LocalizedString;
  salesInformation: LocalizedString;
  startDate?: string;
  subtitle?: LocalizedString;
  title: LocalizedString;
  venuePlanVersionId: string;
  disableVisualSelection?: boolean;
  venueName?: string;
  venueCity?: string;
}

export interface VenueEvent {
  eventSeries?: string;
  id: string;
  personalDataOnTicketIsRequired: boolean;
  personalDataOnTicketShouldBeCollected: boolean;
  salesInformation: LocalizedString;
  preliminaryDateText?: string;
  startDate?: Date | null;
  subtitle?: string;
  title: string;
  venuePlanVersionId: string;
  disableVisualSelection?: boolean;
  venueName?: string;
  venueCity?: string;
}

export const mapVenueEvent = (flatVenueEvent: VenueEventFlat): VenueEvent => {
  return {
    eventSeries: flatVenueEvent.eventSeries,
    id: flatVenueEvent.id,
    personalDataOnTicketIsRequired:
      flatVenueEvent.personalDataOnTicketIsRequired,
    personalDataOnTicketShouldBeCollected:
      flatVenueEvent.personalDataOnTicketShouldBeCollected,
    preliminaryDateText: mapLocalizedString(flatVenueEvent.preliminaryDateText),
    startDate: flatVenueEvent.startDate
      ? new Date(flatVenueEvent.startDate)
      : null,
    subtitle: mapLocalizedString(flatVenueEvent.subtitle),
    title: mapLocalizedString(flatVenueEvent.title) ?? '',
    venuePlanVersionId: flatVenueEvent.venuePlanVersionId,
    disableVisualSelection: flatVenueEvent.disableVisualSelection,
    salesInformation: flatVenueEvent.salesInformation,
    venueName: flatVenueEvent.venueName,
    venueCity: flatVenueEvent.venueCity,
  };
};
