import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Done } from '@mui/icons-material';
import useInitParams from '../../util/useInitParams';
import './ProgressBar.scss';

export enum progressBarSteps {
  'SEATING',
  'CONTACT',
  'PAYMENT',
  // 'CHECKOUT'
}

interface ProgressBarProps {
  step: progressBarSteps
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const history = useHistory();
  const { initParams } = useInitParams();

  const stepTitles = new Map<number, string>([
    [progressBarSteps.SEATING, 'Platzwahl'],
    [progressBarSteps.CONTACT, 'Adresse'],
    [progressBarSteps.PAYMENT, 'Zahlungsart'],
    // [progressBarSteps.CHECKOUT, 'Kauf']
  ]);

  const handleClick = (step: string) => {
    if (Number(step) >= Number(props.step)) return;
    switch (Number(step)) {
      case 0:
        history.push(
          `/${initParams?.tenantSlug}/placeSelection/` +
          `${initParams?.salesChannel}/${initParams?.venueEventId}`
        );
        break;
      case 1:
        history.push(`/${initParams?.tenantSlug}/checkout/${initParams?.salesChannel}/contact`);
        break;
    }
  };

  return (

    <div className='progress-bar'>
      {Object.keys(progressBarSteps).filter((step) => !isNaN(Number(step))).map((step) => (
        <Fragment key={step}>
          {!!Number(step) &&
            <div className={classNames('progress-bar-spacer', {
              'active': Number(step) <= Number(props.step)
            })}></div>
          }
          <div
            onClick={() => handleClick(step)}
            className={classNames('progress-bar-item', {
              'active': Number(step) <= Number(props.step),
              'complete': Number(step) < Number(props.step)
            })}
          >
            {Number(step) < Number(props.step) && <Done />}
            <div className='progress-bar-title'>
              {stepTitles.get(Number(step))}
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default ProgressBar;
