
import { createTheme } from '@mui/material/styles';
import { TenantConfig } from '../state/TenantConfig/types';

export const createTenantTheme = (tenantConfig: TenantConfig) => {
  const primaryColor = tenantConfig.primaryColor;
  const secondaryColor = tenantConfig.secondaryColor;

  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: '#fff'
      },
      secondary: {
        main: '#1d1d1f',
      },
    },
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            padding: 0,
            margin: 0,
            backgroundColor: 'transparent',
            '&.Mui-disabled': {
              background: '#f6f6f6',
            },
            '&.Mui-expanded': {
              margin: 0,
              '&:before': {
                opacity: 1,
              },
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            minHeight: 0,
            padding: '0 6px',
            boxShadow: 'none',
            fontSize: '1.07rem',
            fontWeight: '700',
            '&.Mui-expanded': {
              minHeight: 0,
            },
          },
          content: {
            display: 'block',
            margin: '6px 0',
            padding: '0 6px 0 0',
            '&.Mui-expanded': {
              margin: '6px 0',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
            fontSize: '1.07rem',
            borderRadius: '4px'
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              color: '#b3261e',
              borderColor: '#b3261e',
            }
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              color: '#b3261e !important',
            },
          },
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            position: 'absolute',
            right: '12px',
            top: '50%',
            transform: 'translateY(-50%)',
          },
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: '700',
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            },
          },
          sizeSmall: {
            fontSize: '.875rem',
          },
          sizeMedium: {
            fontSize: '1.07rem',
          },
          sizeLarge: {
            padding: '.9rem 1.5rem',
            fontSize: '1.07rem',
          },
          outlined: {
            backgroundColor: '#fff',
          }
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            },
          },
          sizeSmall: {
            fontSize: '.875rem',
          },
          sizeMedium: {
            fontSize: '1.07rem',
          },
          sizeLarge: {
            fontSize: '1.07rem',
          },
        },
      },
      
      MuiFormControl: {
        styleOverrides: {
          root: {
            ['&:hover, &:focus']: {
              '& .MuiInputLabel-filled:not(.Mui-error)': {
                color: primaryColor
              },
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit',
            backgroundColor: '#fff !important',
            boxShadow: '0 0 0 1px #1D1D1F',
            borderRadius: '4px',
  
            '&:hover, &:focus': {
              backgroundColor: '#fff',
              boxShadow: `0 0 0 2px ${primaryColor}`,
            },
  
            '&.Mui-focused': {
              boxShadow: `0 0 0 2px ${primaryColor}`,
            },
  
            '&.Mui-error': {
              boxShadow: '0 0 0 2px #b3261e',
            },
  
            ['&:before, &:after']: {
              display: 'none'
            },
          },
        },
      },
  
      MuiInputLabel: {
        styleOverrides: {
          filled: {
            color: '#1D1D1F',
            fontSize: '15px',
            fontWeight: '500',
            fontFamily: 'inherit',
  
            '&.Mui-focused': {
              fontSize: '12px',
              color: primaryColor,
            },
            '&.Mui-error': {
              color: '#b3261e'
            },
          },
          shrink: {
            fontSize: '12px !important',
          }
        }
      },
  
      MuiLink: {
        styleOverrides: {
          underlineAlways: {
            textDecoration: 'none',
            '&:hover, &:focus': {
              textDecoration: 'underline',
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(217,217,217,0.5)',
            backdropFilter: 'blur(2.5px)',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            ['&.paymentAccordionWrapper']: {
              padding: '0 16px'
            },
            '&.MuiDialog-paper': {
              minWidth: '320px'
            },
            '& > .MuiButtonBase-root': {
              borderRadius: '4px'
            }
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontFamily: 'Lato, Helvetica Neue, sans-serif',
            padding: '44px 44px 16px 24px',
            fontSize: '25px',
            fontWeight: '700',
            lineHeight: 1.2,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            fontSize: 15,
            padding: '16px 44px 16px 24px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '20px 24px 24px',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            ['&.MuiSvgIcon-fontSizeMedium']: {
              '&.Mui-icon-warning': {
                color: '#ff5f05'
              },
            }
          }
        }
      }
    },
  });
};