import { I18nText } from '../../common/types';
import { Strings } from '../../util/localization';
import { ICOUNTRIES } from '../../util/countries';
export enum ActionType {
  ERROR = 'CHECKOUT::ERROR',
  FETCH = 'CHECKOUT::FETCH',
  SUCCESS = 'CHECKOUT::SUCCESS',
  POST_SUCCESS = 'CHECKOUT_DATA::POST_SUCCESS'
}

export type PlaceType = Seat | StandingPlace | MetaEventStandingPlace;

export interface CartItem {
  id: string;
  canDelete: boolean;
  uid: string;
  grossAmount: number;
  salesChannel: string;
  personalizationFullName?: string;
  place: PlaceType;
  expiresAt?: Date;
  pricingCategory: CartItemPricingCategory;
  pricingClass: CartItemPricingClass;
  publicName: I18nText;
  status: 'created' | 'processing' | 'completed' | 'cancelled';
}

export interface CartItemPricingCategory {
  id: string;
  name: string;
}

export interface CartItemPricingClass {
  id: string;
  publicName: I18nText;
}

export interface Event {
  id: string;
  title?: I18nText;
  startDate?: Date;
  endDate?: Date;
  venueName?: string;
  venueCity?: string;
  gtcUrl?: string;
  privacyPolicyUrl?: string;
  items: CartItem[];
}

export interface Invoice {
  id: string;
  type: 'main'|'cancelled';
  formattedInvoiceNumber: string;
}

export interface FormCheckoutFields {
  salutation: string;
  firstName: string;
  lastName: string;
  address1?: string | null;
  address2?: string | null;
  companyName?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
  phone?: {
    countryCode: string | null;
    phoneNumber: string | null;
  };
  mobile?: {
    countryCode: string | null;
    phoneNumber: string | null;
  };
  email: string;
}

export interface FormCheckoutContactData {
  legalContact: FormCheckoutFields,
  invoiceContact: FormCheckoutFields,
  gdprAccepted?: false,
  tosAccepted?: false
}

export interface FormCheckoutFieldsComponent {
  contactType: string;
  className?: string;
  countries: ICOUNTRIES[];
  invalidCountryLabel?: string;
  i18nFormFields: Strings;
  setFieldValueWrapper: (field: string, value: string) => void;
}

export interface Venue {
  name: string;
}

interface BasicPlace {
  id: string;
  block: Block;
}

export interface Seat extends BasicPlace {
  type: 'seat';
  seatId: string;
  rowLabel: string;
  seatLabel: string;
}

export interface StandingPlace extends BasicPlace {
  type: 'standingPlace';
}

export interface MetaEventStandingPlace extends BasicPlace {
  type: 'metaEventStandingPlace';
}

export interface Block {
  label: I18nText;
}

// Response
export interface FetchResponse {
  events: Event[];
  expiresAt?: Date;
  legalContact: null | FormCheckoutFields
  invoices: Invoice[];
  invoiceContact: null | FormCheckoutFields
  id?: string | null;
  uid?: string | null;
  createdAt?: string | null;
  orderId?: string | null;
  status: 'new'
    | 'payment_pending'
    | 'payment_confirmation_pending'
    | 'canceled'
    | 'payment_processing'
    | 'complete_processing'
    | 'expired'
    | 'completed';
}

// Response state
export interface CheckoutState extends FetchResponse {
  error?: Error;
  fetching: boolean;
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface SuccessAction {
  payload: { response: FetchResponse };
  type: typeof ActionType.SUCCESS;
}

export interface SuccessPostAction {
  payload: { response: FetchResponse };
  type: typeof ActionType.POST_SUCCESS;
}

export type Action = ErrorAction | FetchAction | SuccessAction | SuccessPostAction;
