import { Event } from '../state/Checkout';

export const sumCartItems = (events: Event[]): number => {
  let sum = 0;
  for (const event of events) {
    for (const item of event.items) {
      sum += item.grossAmount;
    }
  }
  return sum / 100;
};