import './index.scss';
import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from './state';
import { Provider as StateProvider } from 'react-redux';
import {
  AnonymousState,
  AuthenticatedState,
  validateAndUpdateAnonToken,
  addAccessTokenChangeListener,
  checkAuthenticationState
} from './auth';
import { getTenantSlugFromURL } from './util/apiRequest';
import { setJWT } from './state/Session';

function setupApplication(state: AuthenticatedState | AnonymousState): void {
  const store = createStore();

  if (!state.accessToken && location.pathname !== '/not-found') {
    window.location.href = `${process.env.PUBLIC_URL}/not-found`;
  }

  if (
    location.pathname !== '/rmv' &&
    location.pathname !== '/not-found' &&
    state.accessToken
  ) {
    // prepopulate the JWT in the store.
    store.dispatch(setJWT(state.accessToken));

    
    // should the access token changed due to silent renews, etc... we also need
    // to reflect that change in the store.
    addAccessTokenChangeListener((accessToken) => {
      store.dispatch(setJWT(accessToken));
    });
  
    // "redirect" to the deep link from before making the roundtrip through the SSO
    if (state.context) {
      const url = [
        state.context.pathname,
        state.context.search,
        state.context.hash,
      ].join('');
      window.history.replaceState(
        undefined,
        '',
        url,
      );
    }
  }

  const root = document.getElementById('root');
  const rootContainer = createRoot(root!);
  rootContainer.render(
    <StateProvider store={store}>
      <App />
    </StateProvider>
  );
}

// Before even attempting to setup the app we handle all the authentication
// stuff. This way we avoid pulling up the whole app only to notice that we
// need to redirect to the SSO provider anyway or if it was a renew callback.
let intervalId: ReturnType<typeof setInterval>;
const tenantSlug = getTenantSlugFromURL();

checkAuthenticationState(tenantSlug).then((state) => {
  switch (state.type) {
    case 'SILENT_RENEW':
      // nothing else needs to be done; parent frame will be notified
      // of the renew by the OIDC client library
      return;
    case 'ANONYMOUS':
      document.addEventListener('visibilitychange', () => { validateAndUpdateAnonToken(tenantSlug); });
      intervalId = setInterval(() => { validateAndUpdateAnonToken(tenantSlug); }, 10000);
      return setupApplication(state);
    case 'AUTHENTICATED':
      return setupApplication(state);
  }
});
