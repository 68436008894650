export enum ActionType {
  ERROR = 'SALES_CHANNEL_EVENTS::ERROR',
  FETCH = 'SALES_CHANNEL_EVENTS::FETCH',
  GET_LIST_SUCCESS = 'SALES_CHANNEL_EVENTS::GET_LIST_SUCCESS',
}

export interface VenueEvent {
  id: string;
  ident: string;
  title: {
    de: string;
    en: string;
  },
  subtitle: {
    de: string;
    en: string;
  },
  description: {
    de: string;
    en: string;
  },
  startDate: string;
  endDate: string;
  deepLink: string;
}

// Response
export interface FetchResponse {
  events: VenueEvent[];
}

// Response state
export interface SalesChannelEventsState extends FetchResponse {
  fetching: boolean;
  error?: Error;
}

// action if error occurs
export interface ErrorAction {
  payload: { error: Error };
  type: typeof ActionType.ERROR;
}

// action to get state
export interface FetchAction {
  type: typeof ActionType.FETCH;
}

// action on success
export interface GetListSuccessAction {
  payload: { response: FetchResponse };
  type: typeof ActionType.GET_LIST_SUCCESS;
}

export type Action = ErrorAction | FetchAction | GetListSuccessAction;
