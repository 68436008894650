import { createFetchActions, FetchComponent } from '../../Fetch';
import {
  apiPOSTRequest,
  generateURL,
  SelectionNotFoundError,
} from '../../../util/apiRequest';
import { History } from 'history';
import config from '../../../config.json';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import { ensureBasicState } from '../utils/ensureBasicState';
import { handleError } from '../../../util/handleError';
import { setArePlacesSubmitted } from '../../Session';
import { Dispatch } from 'redux';
import { AppState } from '../../../state';

export const submitTickets = (
  history: History,
  tenantSlug: string,
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      venueEventId,
    } = ensureBasicState(appState);
    const requestURL = generateURL(config.API_ENDPOINTS.POST_EVENT_TICKET_ITEM, {
      params: { venueEventId, salesChannelKey: salesChannel },
      query: {
        purchaseForTicketHolderId,
        salesRuleId,
        subscriptionId,
      },
    });

    const phoneNumber =
      appState.placesPersonalization.placesPersonalization[0].phoneNumber ??
      null;

    if (phoneNumber) {
      appState.placesPersonalization.placesPersonalization.map(
        (p) => (p.phoneNumber = phoneNumber.trim()),
      );
    }

    // send only tickets with personalization data filled
    const filledPlacesPersonalization = appState.placesPersonalization.placesPersonalization.filter(
      (p) =>
        p.fullName.length > 0 ||
        p.phoneNumber.length > 0 ||
        p.seasonTicketLegalRecipientId.length > 0,
    );
    const body = { placesPersonalization: filledPlacesPersonalization };
    await apiPOSTRequest(requestURL, body);
    dispatch(setArePlacesSubmitted(true));
    history.push(`/${tenantSlug}/checkout/${salesChannel}/contact`);
  } catch (error) {
    if (error instanceof SelectionNotFoundError) {
      //Seems like user clicked submit button multiply times
      dispatch(setArePlacesSubmitted(true));
      return;
    }
    if (error instanceof Error) {
      handleError(dispatch, error);
    }
  } finally {
    dispatch(fetchStop);
  }
};
