import { UserData } from '../common/types';
import { get } from 'lodash';

/**
 * Creates a "full name" string from a given user's data in the pattern "Lastname, Firstname".
 *
 * @param userData The user's data to get the first and last name from.
 * @returns A formatted string containing the user's full name, or null if no name is available.
 */
export const getFullNameFromUserData = (userData: UserData): string | undefined => {
  const firstName = get(userData, 'firstName');
  const lastName = get(userData, 'lastName');

  if (lastName && firstName) {
    return `${lastName}, ${firstName}`;
  } else if (lastName) {
    return lastName;
  } else {
    return undefined;
  }
};
