import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Button } from '@mui/material';
import { IMolliePaymentData } from '../../../state/Payment';
import { useLocale } from '../../../state/Localization';
import {
  useCheckoutState,
  FormCheckoutContactData,
  postCheckoutContactData
} from '../../../state/Checkout';
import CheckoutContactFields from '../../CheckoutContactFields';
import { filterEmptyFields, filterNullFields } from '../../../util/formHelpers';
import { COUNTRIES, ICOUNTRIES } from '../../../util/countries';
import './style.scss';
import style from '../../../screens/CheckoutContactData/style.module.css';

export interface CheckoutPaymentProps {
  cancelSubmitRequested: () => void;
  setIsFormValid: (valid: boolean) => void;
  handleMollieRequestPayment: () => Promise<void>;
  submitRequested: boolean;
  paymentData: IMolliePaymentData | null;
  active: boolean;
}

export interface FormFields {
  streetAndNumber: string;
  postalCode: string;
  city: string;
  country: string;
}

const initialFormValues: FormCheckoutContactData = {
  legalContact: {
    salutation: 'm',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    companyName: '',
    postalCode: '',
    city: '',
    country: 'DE',
    phone: {
      countryCode: null,
      phoneNumber: null
    },
    mobile: {
        countryCode: null,
        phoneNumber: null
    },
    email: '',
  },
  invoiceContact: {
    salutation: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    companyName: '',
    postalCode: '',
    city: '',
    country: '',
    phone: {
      countryCode: null,
      phoneNumber: null
    },
    mobile: {
        countryCode: null,
        phoneNumber: null
    },
    email: '',
  }
};

const CheckoutPaymentComponent: React.FC<CheckoutPaymentProps> = ({
  cancelSubmitRequested,
  handleMollieRequestPayment,
  submitRequested,
  paymentData,
  setIsFormValid,
  active
}) => {
  const dispatch = useDispatch();
  const checkoutState = useCheckoutState();
  const { formFields, strings } = useLocale();

  const { salesChannel } = useRouteMatch<{
    salesChannel: string;
  }>().params;

  const submitButton = useRef<HTMLButtonElement>(null);

  const [formValues, setFormValues] = useState<FormCheckoutContactData>();
  const [invalidCountryLabel, setInvalidCountryLabel] = useState<string>();

  const nameValidationRegex = /^[^0-9]{2,}$/;

  const checkoutContactValidationSchema = Yup.object().shape({
    legalContact: Yup.object().shape({
      firstName: Yup.string()
        .matches(nameValidationRegex, '')
        .required(formFields.validateMessageField),
      lastName: Yup.string()
        .matches(nameValidationRegex, '')
        .required(formFields.validateMessageField),
      email: Yup.string().email().required(formFields.validateMessageField),
      address1: Yup.string().required(formFields.validateMessageField),
      postalCode: Yup.string().required(formFields.validateMessageField).max(10),
      city: Yup.string().required(formFields.validateMessageField),
      country: Yup.string().required(formFields.validateMessageField)
    }),
    invoiceContact: Yup.lazy(() => Yup.mixed().notRequired())
  });

  const countries = useMemo<ICOUNTRIES[]>(() => {
    if (!paymentData?.allowedCountries) return COUNTRIES;
    return COUNTRIES.filter((country) => paymentData.allowedCountries?.includes(country.id));
  }, [paymentData]);

  useEffect(() => {
    // TODO: This is a workaraound, because we currently
    // do not find an proper solution to trigger form
    // submission from outside.
    if (active && submitRequested && submitButton.current) {
      submitButton.current.click();
    }
  }, [submitRequested]);

  useEffect(() => {
    if (formValues || !checkoutState.legalContact || !paymentData) return;

    let isInvalidCountry = false;
    const selectedCountry: ICOUNTRIES|undefined = COUNTRIES.find(
      (country) => country.id === checkoutState.legalContact?.country
    );

    if (selectedCountry && paymentData.allowedCountries?.length) {
      isInvalidCountry = !paymentData.allowedCountries.find(
        (country) => country === selectedCountry.id
      );

      if (isInvalidCountry) {
        toast.error(strings.Error_contact_data_invalid_country, {
          position: toast.POSITION.BOTTOM_RIGHT,
          toastId: 'isInvalidCountry'
        });
        setInvalidCountryLabel(selectedCountry.name);
      }
    }

    setFormValues({
      ...initialFormValues,
      legalContact: {
        ...initialFormValues.legalContact,
        ...filterNullFields(checkoutState.legalContact),
        ...{ country: isInvalidCountry ? '' : checkoutState.legalContact.country }
      },
      invoiceContact: {
        ...initialFormValues.invoiceContact,
        ...filterNullFields(checkoutState.invoiceContact),
        ...{ country: isInvalidCountry ? '' : checkoutState.legalContact.country }
      }
    });
  }, [checkoutState, paymentData]);

  const handleSubmit = (values: FormCheckoutContactData) => {
    const formDataToSend = {
      // As we will not show invoiceContact form we will always use legalContact
      invoiceContact: values.legalContact,
      legalContact: values.legalContact,
      // Because the user won't reach this page without accepting tos and
      // we can assume that these values are true
      gdprAccepted: true,
      tosAccepted: true
    };
    const filteredValues = filterEmptyFields(formDataToSend);

    onHandleSubmit(filteredValues as FormCheckoutContactData);
  };

  const onHandleSubmit = (values: FormCheckoutContactData) => {
    dispatch(postCheckoutContactData(values, salesChannel, handleMollieRequestPayment));
  };

  return (
    <>
      {formValues && paymentData?.resource === 'orders' &&
        <Formik
          initialValues={formValues}
          validationSchema={checkoutContactValidationSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, submitForm, isValid }) => {
            useEffect(() => {
              setIsFormValid(isValid);
            }, [isValid, setIsFormValid]);
            return (
              <Form>
                <CheckoutContactFields
                  className={style.formWrapper}
                  contactType='legalContact'
                  i18nFormFields={formFields}
                  countries={countries}
                  invalidCountryLabel={invalidCountryLabel}
                  setFieldValueWrapper={(field, value) => {
                    setFieldValue(field, value);
                  }}
                />

                <Button
                  className="paymentFormSubmit"
                  type="button"
                  ref={submitButton}
                  onClick={() => {
                    cancelSubmitRequested();
                    submitForm();
                  }}
                ></Button>

              </Form>
            );
          }}

        </Formik>
      }
      {paymentData?.resource !== 'orders' &&
        <Button
          className="paymentFormSubmit"
          type="button"
          ref={submitButton}
          onClick={() => {
            cancelSubmitRequested();
            handleMollieRequestPayment();
          }}
        ></Button>
      }
    </>
  );
};

export default CheckoutPaymentComponent;
