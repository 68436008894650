export enum ActionType {
  ADD = 'MESSGE::ADD',
  REMOVE = 'MESSGE::REMOVE',
}

export enum MessageLevel {
  Success = 'SUCCESS',
  Warning = 'WARNING',
  Error = 'ERROR',
}

export enum MessageType {
  InvalidPlace = 'Error_place_not_valid',
  InvalidPurchasableItem = 'Error_purchasableItem_not_valid',
  InvalidRightsProvider = 'Error_rightsProvider_not_valid',
  InvalidSeasonTicketLegalRecipientId = 'Error_SeasonTicketLegalRecipientId_not_valid',
  SubscriptionIdNotFound = 'Error_subscription_id_not_found',
  PersonalizationRequired = 'Error_personalization_required',
  SalesChannelNotFound = 'Error_sales_channel_not_found',
  UserIsNotInTargetGroup = 'UserIsNotInTargetGroup',
  UserHasMaxTickets = 'UserHasMaxTickets',
  PlaceIsBocked = 'PlaceIsBocked',
  FreePlacesAreMissing = 'FreePlacesAreMissing',
  APIClientError = 'API_CLIENT_ERROR',
  APIClientErrorUnauthorized = 'API_CLIENT_ERROR_UNAUTHORIZED',
  APIServerError = 'API_SERVER_ERROR',
  Error = 'ERROR',
}

export interface Message {
  callToAction?: {
    linkText: string;
    linkTo: string;
  };
  messageParam?: string;
  level: MessageLevel;
  timeout?: number;
  type: MessageType;
}

export interface MessageWithId extends Message {
  id: string;
}

export interface MessageState {
  messages: MessageWithId[];
}

export interface AddMessageAction {
  type: typeof ActionType.ADD;
  payload: { message: MessageWithId };
}

export interface RemoveMessageAction {
  type: typeof ActionType.REMOVE;
  payload: { messageId: string };
}

export type Action = AddMessageAction | RemoveMessageAction;
