import React, { useEffect } from 'react';
import {
  getEventsList,
  useSalesChannelEventsState
} from '../../state/SalesChannelEvents';
import { useLocale } from '../../state/Localization';
import { useRouteMatch } from 'react-router-dom';
import EventsList from '../../components/EventsList';
import Spinner from '../../common/Spinner';
import { useDispatch } from 'react-redux';
import style from './style.module.css';
import Header from '../../components/Header/Header';
import Layout from '../../common/Layout';
import {useTenantConfig} from '../../state/TenantConfig';
import {hydrateString} from '../../util/localization';
import {setPageTitle} from '../../state/Session';

const SalesChannelEventListScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { strings, titles } = useLocale();
  const eventsListState = useSalesChannelEventsState();
  const { tenantSlug, salesChannel } = useRouteMatch<{
    tenantSlug: string;
    salesChannel: string;
  }>().params;
  const { tenantConfig } = useTenantConfig();
  useEffect(() => {
    if (!tenantConfig.tenantName) {
      return;
    }

    dispatch(setPageTitle(hydrateString(titles.Events, {'tenantName': tenantConfig.tenantName})));
  }, [tenantConfig.tenantName]);
  
  useEffect(() => {
    dispatch(getEventsList(salesChannel));
  }, [dispatch]);
  
  return (
    eventsListState.fetching ? (
      <Spinner />
    ) : (
      <Layout
        title={`${strings.EventListingTitle} (${salesChannel})`}
        dark={true}
        header={<Header />}
        main={
          <div className={style.EventList}>
            <EventsList
              eventsListState={eventsListState}
              salesChannelKey={salesChannel}
              tenantSlug={tenantSlug}
            />
          </div>
        }
      />
    )
  );
};

export default SalesChannelEventListScreen;
