import { createFetchActions, FetchComponent } from '../../Fetch';
import { apiGETRequest, generateURL } from '../../../util/apiRequest';
import config from '../../../config.json';
import AsyncReduxThunkAction from '../../../util/AsyncReduxThunkAction';
import { ActionType as TicketSelectionActionType } from '../index';
import { TicketSelectionStateAPIResponse } from '../types/TicketSelectionStateAPIResponse';
import { ensureBasicState } from '../utils/ensureBasicState';
import { handleError } from '../../../util/handleError';
import { mapTicketSelectionStateAPIResponse } from '../types/mapTicketSelectionStateAPIResponse';
import { setPlacesPersonalization } from '../../PlacePersonalization';

export const getState = (
  fetchComponent?: FetchComponent,
): AsyncReduxThunkAction => async (dispatch, getState): Promise<void> => {
  const { fetchStart, fetchStop } = createFetchActions(fetchComponent);
  dispatch(fetchStart);
  try {
    const appState = getState();
    const {
      purchaseForTicketHolderId,
      salesChannel,
      salesRuleId,
      subscriptionId,
      // userId,
      venueEventId,
    } = ensureBasicState(appState);

    const requestURL = generateURL(config.API_ENDPOINTS.GET_STATE, {
      params: { venueEventId, salesChannelKey: salesChannel },
      query: {
        purchaseForTicketHolderId,
        salesRuleId,
        subscriptionId,
      },
    });
    const stateResponse = (await apiGETRequest(
      requestURL,
    )) as TicketSelectionStateAPIResponse;
    const state = mapTicketSelectionStateAPIResponse(stateResponse);
    dispatch({ payload: { state }, type: TicketSelectionActionType.SET_STATE });
    if (
      state.places.length !==
      appState.placesPersonalization.placesPersonalization.length
    ) {
      dispatch(setPlacesPersonalization());
    }
  } catch (error) {
    if (error instanceof Error) {
      handleError(dispatch, error);
    }
  } finally {
    dispatch(fetchStop);
  }
};
