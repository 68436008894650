import React from 'react';
import { Field, FieldProps } from 'formik';
import { FormCheckoutFieldsComponent } from '../../state/Checkout';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { FormCheckoutFields } from '../../state/Checkout';
import style from './style.module.css';
import classNames from 'classnames';
import MuiInputAdornment from '../../common/MuiInputAdornment/MuiInputAdornment';

interface FormikErrors {
  [key: string]: FormCheckoutFields;
}

const CheckoutContactFields: React.FC<FormCheckoutFieldsComponent> = ({
  contactType,
  className,
  setFieldValueWrapper,
  countries,
  invalidCountryLabel,
  i18nFormFields: {
    salutation,
    salutationM,
    salutationF,
    salutationD,
    firstName,
    lastName,
    address1,
    address2,
    companyName,
    postalCode,
    city,
    country,
    email
  },
}) => {
  const handleClick = (field: string) => {
    setFieldValueWrapper(field, '');
  };

  return (
    <div className={className}>
      <div className={classNames(style.formField, style.companyName)}>
          <Field
              type="text"
              name={`${contactType}.companyName`}
              id={`${contactType}companyName`}
          >
              {({ field }: { field: { name: string, value: string } }) => (
                <>
                  <TextField
                      fullWidth
                      className={style.formFieldInput}
                      label={companyName}
                      variant="filled"
                      {...field}
                  />
                  {field.value && <MuiInputAdornment onClick={() => handleClick(`${contactType}.companyName`)} />}
                </>
              )}
          </Field>
      </div>
      <div className={classNames(style.formField, style.salutation)}>
        <Field name={`${contactType}.salutation`}>
            {({ field }: FieldProps) => (
                <FormControl fullWidth variant="filled" className={style.formFieldInput}>
                    <InputLabel htmlFor={`${contactType}salutation`}>{salutation}</InputLabel>
                    <Select
                        label={salutation}
                        {...field}
                        id={`${contactType}salutation`}
                    >
                        <MenuItem value="m">{salutationM}</MenuItem>
                        <MenuItem value="f">{salutationF}</MenuItem>
                        <MenuItem value="d">{salutationD}</MenuItem>
                    </Select>
                </FormControl>
            )}
        </Field>
      </div>
      <div className={classNames(style.formField, style.firstName)}>
        <Field
          type="text"
          name={`${contactType}.firstName`}
          id={`${contactType}firstName`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <>
              <TextField
                fullWidth
                label={firstName}
                error={!!form.errors[contactType]?.firstName}
                variant="filled"
                className={style.formFieldInput}
                {...field}
              />
              {field.value && <MuiInputAdornment onClick={() => handleClick(`${contactType}.firstName`)} />}
              {form.errors[contactType]?.firstName && <MuiInputAdornment icon='error' />}
            </>
          )}
        </Field>
      </div>
      <div className={classNames(style.formField, style.lastName)}>
        <Field
          type="text"
          name={`${contactType}.lastName`}
          id={`${contactType}lastName`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <>
              <TextField
                fullWidth
                className={style.formFieldInput}
                label={lastName}
                error={!!form.errors[contactType]?.lastName}
                variant="filled"
                {...field}
              />
              {field.value && <MuiInputAdornment onClick={() => handleClick(`${contactType}.lastName`)} />}
              {form.errors[contactType]?.lastName && <MuiInputAdornment icon='error' />}
            </>
          )}
        </Field>
      </div>
      <div className={classNames(style.formField, style.address1)}>
        <Field
          type="text"
          name={`${contactType}.address1`}
          id={`${contactType}address1`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <>
              <TextField
                fullWidth
                label={address1}
                error={!!form.errors[contactType]?.address1}
                variant="filled"
                className={style.formFieldInput}
                {...field}
              />
              {field.value && <MuiInputAdornment onClick={() => handleClick(`${contactType}.address1`)} />}
              {form.errors[contactType]?.address1 && <MuiInputAdornment icon='error' />}
            </>
          )}
        </Field>
      </div>
      <div className={classNames(style.formField, style.address2)}>
        <Field
          type="text"
          name={`${contactType}.address2`}
          id={`${contactType}address2`}
        >
          {({ field }: { field: { name: string, value: string } }) => (
            <>
              <TextField
                fullWidth
                className={style.formFieldInput}
                label={address2}
                variant="filled"
                {...field}
              />
              {field.value && <MuiInputAdornment onClick={() => handleClick(`${contactType}.address2`)} />}
            </>
          )}
        </Field>
      </div>
      <div className={classNames(style.formField, style.postalCode)}>
        <Field
          type="text"
          name={`${contactType}.postalCode`}
          id={`${contactType}postalCode`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <>
              <TextField
                fullWidth
                label={postalCode}
                error={!!form.errors[contactType]?.postalCode}
                variant="filled"
                className={style.formFieldInput}
                {...field}
              />
              {field.value && <MuiInputAdornment onClick={() => handleClick(`${contactType}.postalCode`)} />}
              {form.errors[contactType]?.postalCode && <MuiInputAdornment icon='error' />}
            </>
          )}
        </Field>
      </div>
      <div className={classNames(style.formField, style.city)}>
        <Field
          type="text"
          name={`${contactType}.city`}
          id={`${contactType}city`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <>
              <TextField
                fullWidth
                label={city}
                error={!!form.errors[contactType]?.city}
                variant="filled"
                className={style.formFieldInput}
                {...field}
              />
              {field.value && <MuiInputAdornment onClick={() => handleClick(`${contactType}.city`)} />}
              {form.errors[contactType]?.city && <MuiInputAdornment icon='error' />}
            </>
          )}
        </Field>
      </div>
      <div className={classNames(style.formField, style.country)}>
        <Field name={`${contactType}.country`}>
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <FormControl fullWidth variant="filled" className={style.formFieldInput}>
              <InputLabel
                htmlFor={`${contactType}country`}
                error={!!form.errors[contactType]?.country}
                shrink
              >
                {country}
              </InputLabel>
              <Select
                className=''
                displayEmpty
                label={country}
                error={!!form.errors[contactType]?.country}
                {...field}
                id={`${contactType}country`}
              >
                {invalidCountryLabel && !field.value &&
                  <MenuItem value='' disabled>
                    {invalidCountryLabel}
                  </MenuItem>
                }
                {countries.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Field>
      </div>
      <div className={classNames(style.formField, style.email)}>
        <Field
          type="email"
          name={`${contactType}.email`}
          id={`${contactType}email`}
        >
          {({ field, form }: { field: { name: string, value: string }, form: { errors: FormikErrors } }) => (
            <>
              <TextField
                fullWidth
                label={email}
                className={style.formFieldInput}
                error={!!form.errors[contactType]?.email}
                variant="filled"
                {...field}
              />
              {field.value && <MuiInputAdornment onClick={() => handleClick(`${contactType}.email`)} />}
              {form.errors[contactType]?.email && <MuiInputAdornment icon='error' />}
            </>
          )}
        </Field>
      </div>
    </div>
  );
};

export default CheckoutContactFields;
