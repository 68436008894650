import { Action, ActionType, TenantConfigState } from './types';
import { isEmpty } from 'lodash';

const initialState: TenantConfigState = {
  fetching: false,
  tenantConfig: {
    imprintUrl: 'https://www.imprint2.com',
    privacyPolicyUrl: 'https://ticketing.taas-dev.wort2.de/corona_regeln.pdf',
    gtcUrl: 'https://ticketing.taas-dev.wort2.de/agb.pdf',
    websiteUrl: 'https://ticketing.ticketshop.docker',
    primaryColor: '#1ab394',
    secondaryColor: '#cedfd9',
    logoUrl: '/icons/icon.png',
    faviconUrl: '/favicon/favicon-16x16.png',
    hotlineNumber: '06073 / 722740',
    hotlineReachability: `Mo.-Fr. 09:00-17:00 Uhr \u0026 Sa. 09:00 - 14:00 Uhr`,
    tenantName: '',
  }
};

export const reducer = (
  state = initialState,
  action: Action,
): TenantConfigState => {
  switch (action.type) {
    case ActionType.GET_TENANT_CONFIG: {
      const { tenantConfig } = action.payload;
      return {
        ...state,
        tenantConfig,
      };
    }
    case ActionType.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        fetching: false,
      };
    }
    case ActionType.FETCH: {
      return {
        ...state,
        fetching: true,
      };
    }
    case ActionType.SUCCESS: {
      const { tenantConfig } = action.payload;
      return {
        ...state,
        tenantConfig: {
          ...tenantConfig,
          ...isEmpty(tenantConfig.logoUrl) && { logoUrl: '/icons/icon.png' },
          ...isEmpty(tenantConfig.faviconUrl) && { faviconUrl: '/favicon/favicon-16x16.png' },
        },
        error: undefined,
        fetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
