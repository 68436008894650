import {Action, ActionType, VenueEventFlat, mapVenueEvent } from './index';
import {
  QueueItRedirectError,
  apiGETRequest,
  generateURL,
} from '../../util/apiRequest';
import config from '../../config.json';
import AsyncReduxThunkAction from '../../util/AsyncReduxThunkAction';
import { handleError } from '../../util/handleError';
import { Dispatch } from 'redux';

export const getVenueEvent = (
  venueEventId: string,
  salesChannel: string,
  queueittoken: string,
): AsyncReduxThunkAction => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch({ type: ActionType.FETCH });

    const requestURL = generateURL(config.API_ENDPOINTS.GET_EVENT, {
      params: { venueEventId, salesChannelKey: salesChannel },
      query: { queueittoken, redirectUrl: window.location.href },
    });

    const venueEventFlat = (await apiGETRequest(requestURL)) as VenueEventFlat;
    const venueEvent = mapVenueEvent(venueEventFlat);

    dispatch({ payload: { venueEvent }, type: ActionType.SUCCESS });
  } catch (error) {
    if (error instanceof QueueItRedirectError) {
      window.location.href = error.url;
      return;
    }
    if (error instanceof Error) {
      dispatch({ payload: { error }, type: ActionType.ERROR });
      handleError(dispatch, error);
    }
  }
};

export const setConsecutiveSeats = (consecutiveSeats: number): Action => ({
  payload: { consecutiveSeats },
  type: ActionType.SET_CONSECUTIVE_SEATS,
});
